import React, { useState, useEffect } from 'react';
import { Check, ChevronLeft, ChevronRight, ArrowUpDown, UnfoldHorizontal, Columns2, X, BoxSelect } from 'lucide-react';
import { ReactComponent as BodyweightIcon } from '../../../assets/icons/bodyweight.svg';
import { ReactComponent as DumbbellIcon } from '../../../assets/icons/dumbbell.svg';
import { ReactComponent as BarbellIcon } from '../../../assets/icons/barbell.svg';
import { ReactComponent as MachineIcon } from '../../../assets/icons/machine.svg';
import { ReactComponent as CableIcon } from '../../../assets/icons/cable.svg';
import { ReactComponent as KettlebellIcon } from '../../../assets/icons/kettlebell.svg';
import { ReactComponent as BandIcon } from '../../../assets/icons/bands.svg';

const StepFive = ({ formData, updateFields }) => {
  const splitOptions = [
    { label: 'Push / Pull / Legs', icon: <UnfoldHorizontal className="w-9 h-8 text-purple-600" /> },
    { label: 'Upper Lower', icon: <ArrowUpDown className="w-9 h-8 text-blue-600" /> },
    { label: '2 body parts per day', icon: <Columns2 className="w-9 h-8 text-green-600" /> },
    { label: 'Full Body', icon: <BodyweightIcon className="w-9 h-8 text-red-600" /> }
  ];

  const splits = [
    ...splitOptions,
    { label: 'Select for me', icon: <BoxSelect className="w-9 h-8 text-gray-600" /> }
  ];

  const getUpdatedSplit = (selectedSplit) => {
    if (selectedSplit === 'Select for me') {
      const availableSplits = splitOptions.map(split => split.label).join(', ');
      return `Please select the optimal split from these options: ${availableSplits}`;
    }
    return selectedSplit;
  };

  // Set initial split if not already set
  useEffect(() => {
    if (!formData.split) {
      updateFields({ split: getUpdatedSplit(splits[0].label) });
    }
  }, []);

  const [currentIndex, setCurrentIndex] = useState(
    formData.split ? splits.findIndex(split => split.label === formData.split) : 0
  );
  const [isAnimating, setIsAnimating] = useState(false);

  const handleNext = () => {
    if (isAnimating || currentIndex === splits.length - 1) return;
    setIsAnimating(true);
    setCurrentIndex(currentIndex + 1);
    updateFields({ split: getUpdatedSplit(splits[currentIndex + 1].label) });
    setTimeout(() => setIsAnimating(false), 300);
  };

  const handlePrev = () => {
    if (isAnimating || currentIndex === 0) return;
    setIsAnimating(true);
    setCurrentIndex(currentIndex - 1);
    updateFields({ split: getUpdatedSplit(splits[currentIndex - 1].label) });
    setTimeout(() => setIsAnimating(false), 300);
  };

  const handleCardClick = (index) => {
    if (isAnimating || index === currentIndex) return;
    setIsAnimating(true);
    setCurrentIndex(index);
    updateFields({ split: getUpdatedSplit(splits[index].label) });
    setTimeout(() => setIsAnimating(false), 300);
  };

  const equipmentList = [
    { name: "Body weight", icon: BodyweightIcon },
    { name: "Dumbbell", icon: DumbbellIcon },
    { name: "Barbell", icon: BarbellIcon },
    { name: "Machine", icon: MachineIcon },
    { name: "Cable", icon: CableIcon },
    { name: "Kettlebell", icon: KettlebellIcon },
    { name: "Band", icon: BandIcon },
  ];

  const handleEquipmentChange = (equipment) => {
    const currentEquipment = formData.equipment || [];
    
    if (equipment === 'All Equipment') {
      const allEquipmentNames = equipmentList.map(e => e.name);
      const hasAllEquipment = allEquipmentNames.every(name => currentEquipment.includes(name));
      
      updateFields({ 
        equipment: hasAllEquipment ? [] : allEquipmentNames 
      });
      return;
    }

    const newEquipment = currentEquipment.includes(equipment)
      ? currentEquipment.filter(e => e !== equipment)
      : [...currentEquipment, equipment];
    updateFields({ equipment: newEquipment });
  };

  const isAllSelected = equipmentList.every(({ name }) => 
    (formData.equipment || []).includes(name)
  );

  return (
    <div className="space-y-8 w-full">
      {/* Workout Split Section */}
      <div className="space-y-4">
        <label className="block text-lg font-medium text-gray-700">Choose your workout split</label>
        <div className="relative h-40">
          {/* Edge Gradients */}
          <div className="absolute left-0 top-0 h-full w-32 bg-gradient-to-r from-white via-white to-transparent z-10"></div>
          <div className="absolute right-0 top-0 h-full w-32 bg-gradient-to-l from-white via-white to-transparent z-10"></div>

          {/* Navigation Buttons */}
          <button 
            onClick={handlePrev}
            disabled={currentIndex === 0}
            className={`absolute left-4 top-1/2 -translate-y-1/2 z-20 p-2 rounded-full bg-white shadow-md transition-all
              ${currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50 cursor-pointer'}`}
          >
            <ChevronLeft className="w-6 h-6 text-gray-600" />
          </button>

          <button 
            onClick={handleNext}
            disabled={currentIndex === splits.length - 1}
            className={`absolute right-4 top-1/2 -translate-y-1/2 z-20 p-2 rounded-full bg-white shadow-md transition-all
              ${currentIndex === splits.length - 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50 cursor-pointer'}`}
          >
            <ChevronRight className="w-6 h-6 text-gray-600" />
          </button>

          {/* Carousel */}
          <div className="relative w-full h-full overflow-hidden px-16">
            <div className="w-full h-full flex items-center justify-center">
              {splits.map((split, index) => {
                const position = index - currentIndex;
                return (
                  <div
                    key={split.label}
                    onClick={() => handleCardClick(index)}
                    className={`
                      absolute w-40 transition-all duration-300 ease-in-out
                      ${Math.abs(position) <= 1 ? 'cursor-pointer' : ''}
                    `}
                    style={{
                      transform: `translateX(${position * 90}%) scale(${
                        position === 0 ? 1 : 0.8
                      })`,
                      opacity: Math.abs(position) > 1 ? 0 : 1 - Math.abs(position) * 0.5,
                      zIndex: 10 - Math.abs(position),
                      pointerEvents: Math.abs(position) <= 1 ? 'auto' : 'none'
                    }}
                  >
                    <div
                      className={`
                        p-4 rounded-xl border-2 transition-all
                        ${position === 0
                          ? 'bg-blue-100 border-blue-500 shadow-lg'
                          : 'bg-gray-50 border-gray-200 hover:bg-gray-100'
                        }
                      `}
                    >
                      <div className="flex flex-col items-center">
                        {split.icon}
                        <span className="text-center font-medium mt-2">{split.label}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

{/* Equipment Section */}
<div>
  <h2 className="text-lg font-medium text-gray-700 mb-2">
    Equipment Preference
  </h2>
  <p className="text-sm text-gray-500 mb-2">
    Select all equipment you have access to. Your workout plan will be customized based on your equipment preferences.
  </p>
  <p className={`text-sm mb-6 ${
    (formData.equipment || []).length === 0 ? 'text-gray-500' :
    (formData.equipment || []).length <= 4 ? 'text-green-600' : 'text-red-600'
  }`}>
    Recommended: Choose 1-4 selection of equipment for optimal workout result
    {(formData.equipment || []).length > 4 && ' (Not recommended)'}
  </p>
  <div className="grid grid-cols-3 gap-4">
    {equipmentList.map(({ name, icon: Icon }) => (
      <div
        key={name}
        onClick={() => handleEquipmentChange(name)}
        className={`
          cursor-pointer p-4 rounded-xl transition-all duration-300
          flex flex-col items-center justify-center
          ${(formData.equipment || []).includes(name)
            ? 'bg-blue-100 border-2 border-blue-500 shadow-lg' 
            : 'bg-gray-50 border-2 border-gray-200 hover:bg-gray-100'}
        `}
      >
        <div className="mb-2">
          <Icon className="w-12 h-12" />
        </div>
        <span className="text-center text-sm font-medium">{name}</span>
      </div>
    ))}
  </div>
</div>

      <div className="bg-blue-50 rounded-lg p-4">
        <p className="text-sm text-blue-600">
          💡 Tip: Having access to more equipment types will give you a more varied workout plan, but you can get a great workout with just bodyweight exercises too!
        </p>
      </div>
    </div>
  );
};

export default StepFive;