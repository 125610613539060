import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Authenticator, ThemeProvider, useTheme, View, TextField } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

const Login = () => {
  const { tokens } = useTheme();
  const navigate = useNavigate();
  const { email } = useAuth();
  const location = useLocation();
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(prevKey => prevKey + 1);
  }, [location]);

  useEffect(() => {
    if (email) {
      // Check if there's a redirect destination in location state
      const destination = location.state?.from?.pathname || '/dashboard';
      navigate(destination, { replace: true });
    }
  }, [email, navigate, location.state]);

  const components = {
    Header() {
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <h2 className="text-black font-extrabold" style={{ fontSize: 'var(--font-size-3xl)' }}>
            Login to your account
          </h2>
        </View>
      );
    },
    SignUp: {
      FormFields() {
        return (
          <>
            <Authenticator.SignUp.FormFields />
            <TextField
              label="Name"
              name="name"
              placeholder="Enter your full name"
              required
            />
          </>
        );
      },
    },
  };

  const theme = {
    name: 'custom-theme',
    tokens: {
      colors: {
        background: {
          primary: { value: '#FFFFFF' },
          secondary: { value: 'var(--color-background-secondary)' },
        },
        font: {
          interactive: { value: 'black' },
        },
        brand: {
          primary: {
            10: { value: 'var(--color-primary)' },
            80: { value: 'var(--color-primary)' },
            90: { value: 'var(--color-primary)' },
            100: { value: 'var(--color-primary)' },
          },
        },
      },
      components: {
        authenticator: {
          router: {
            borderWidth: { value: '0' },
          },
        },
        button: {
          primary: {
            backgroundColor: { value: 'var(--color-primary)' },
            _hover: {
              backgroundColor: { value: 'var(--button-hover-color)' },
            },
          },
        },
        fieldcontrol: {
          border: { value: 'var(--input-border)' },
        },
      },
    },
  };

  return (
    <div className="min-h-full min-w-full flex flex-col justify-center py-12 bg-light-secondary">
      <div className="mx-auto w-full max-w-full sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl px-4 flex justify-center">
        <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
          <ThemeProvider theme={theme}>
            <Authenticator
              key={key}
              initialState="signIn"
              components={components}
              socialProviders={['google']}
            >

            </Authenticator>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default Login;