// assistedWorkoutSteps/step3.js
import React from 'react';
import { Target, Dumbbell, Trophy } from 'lucide-react';

const StepThree = ({ formData, updateFields }) => {
  const experienceLevels = [
    { 
      label: 'Beginner',
      icon: <Target size={32} className="text-green-600" />,
      description: 'New to fitness'
    },
    { 
      label: 'Intermediate',
      icon: <Dumbbell size={32} className="text-blue-600" />,
      description: '1-3 years experience'
    },
    { 
      label: 'Advanced',
      icon: <Trophy size={32} className="text-yellow-600" />,
      description: '3+ years experience'
    }
  ];

  return (
    <div className="space-y-8 w-full">
      {/* Experience Level Section */}
      <div className="space-y-4">
        <label className="block text-lg font-medium text-gray-700">Fitness Experience</label>
        <div className="grid grid-cols-1 gap-4">
          {experienceLevels.map(({ label, icon, description }) => (
            <div
              key={label}
              onClick={() => updateFields({ experienceLevel: label })}
              className={`
                cursor-pointer p-4 rounded-xl transition-all duration-300
                flex items-center 
                ${formData.experienceLevel === label 
                  ? 'bg-blue-100 border-2 border-blue-500 shadow-lg' 
                  : 'bg-gray-50 border-2 border-gray-200 hover:bg-gray-100'}
              `}
            >
              <div className="mr-4">{icon}</div>
              <div>
                <span className="block font-medium">{label}</span>
                <span className="text-sm text-gray-500">{description}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Physical Limitations Section */}
      <div className="space-y-4">
        <label className="block text-lg font-medium text-gray-700">
          Physical Limitations or Injuries
        </label>
        <p className="text-sm text-gray-500 mb-2">
          Please describe any injuries or physical limitations we should consider when creating your workout plan.
          Leave blank if none.
        </p>
        <textarea
          value={formData.limitations || ''}
          onChange={(e) => updateFields({ limitations: e.target.value })}
          placeholder="Example: Recent knee surgery, Lower back pain, etc."
          className="w-full p-3 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 h-32 resize-none"
        />
      </div>
    </div>
  );
};

export default StepThree;