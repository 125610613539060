// ModalContext.jsx
import React, { createContext, useContext, useState } from 'react';
import Modal from '../components/Modal';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modalState, setModalState] = useState({
    isOpen: false,
    title: '',
    message: '',
    type: 'info',
    choices: null,
    onChoice: null,
    autoClose: true
  });

  const showModal = (title, message, type = 'info', config = {}) => {
    setModalState({ 
      isOpen: true, 
      title, 
      message, 
      type,
      choices: config.choices || null,
      onChoice: config.onChoice || null,
      autoClose: config.autoClose !== false
    });
  };

  const hideModal = () => {
    setModalState((prev) => ({ ...prev, isOpen: false }));
  };

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      <Modal
        isOpen={modalState.isOpen}
        onClose={hideModal}
        title={modalState.title}
        message={modalState.message}
        type={modalState.type}
        choices={modalState.choices}
        onChoice={modalState.onChoice}
        autoClose={modalState.autoClose}
      />
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

export default ModalProvider;