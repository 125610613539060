import React, { useState, useEffect } from 'react';
import { Edit2, Calendar, Clock, Target, Utensils, Weight, FileText } from 'lucide-react';

const EditableField = ({ value, onSave, type = 'text', className = '' }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value || '');

  useEffect(() => {
    setEditValue(value || '');
  }, [value]);

  const handleSave = () => {
    onSave(editValue);
    setIsEditing(false);
  };

  return isEditing ? (
    <div className="flex items-center gap-2">
      <input
        type={type}
        value={editValue}
        onChange={(e) => setEditValue(e.target.value)}
        className={`rounded px-2 py-1 w-full ${className}`}
        style={{
          backgroundColor: 'var(--color-workout-exerbg)',
          color: 'var(--color-workout-text)'
        }}
        onBlur={handleSave}
        onKeyPress={(e) => e.key === 'Enter' && handleSave()}
        autoFocus
      />
    </div>
  ) : (
    <div className="relative group cursor-pointer" onClick={() => setIsEditing(true)}>
      <span className={`block ${className}`} style={{ color: 'var(--color-workout-text)' }}>{value}</span> 
      <Edit2 
        size={16} 
        className="absolute right-2 top-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity"
        style={{ color: 'var(--color-workout-text)' }}
      />
    </div>
  );
};

const StatsDisplay = ({ icon: Icon, value, label, onEdit }) => (
  <div className="flex flex-col items-center p-4 rounded-lg"
    style={{ backgroundColor: 'var(--color-workout-exerbg)' }}
  >
    <div className="flex items-center justify-center w-12 h-12 rounded-full mb-2"
      style={{ 
        backgroundColor: 'var(--color-workout-button-selected)',
        color: 'var(--color-workout-text)'
      }}
    >
      <Icon className="w-6 h-6" />
    </div>
    <div className="text-center">
      {onEdit ? (
        <EditableField 
          value={value} 
          onSave={onEdit} 
          className="text-lg font-semibold hover:cursor-pointer"
        />
      ) : (
        <span className="text-lg font-semibold" style={{ color: 'var(--color-workout-text)' }}>
          {value}
        </span>
      )}
      <p className="text-sm" style={{ color: 'var(--color-workout-text)' }}>
        {label}
      </p>
    </div>
  </div>
);

const WorkoutHeader = ({ 
  title,
  strategy,
  timePerSession,
  daysPerWeek,
  programDuration = "4-6 weeks",
  onTitleChange,
  onStrategyChange,
  onTimeChange,
  foodValue,
  weightsValue,
  onFoodChange,
  onWeightsChange
}) => {
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingStrategy, setIsEditingStrategy] = useState(false);
  const [isEditingFood, setIsEditingFood] = useState(false);
  const [isEditingWeights, setIsEditingWeights] = useState(false);

  return (
    <div className="space-y-6 mb-5">
      <div className="flex justify-center mb-6 w-full">
        <a href="https://pdftrainer.com" target="_blank" rel="noopener noreferrer">
          <img 
            src="/images/logo-text.png" 
            alt="Logo" 
            className="h-9 opacity-100"
            style={{ filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 1))' }}
          />
        </a>
      </div>
  
      <div className="p-4 rounded-lg pt-3 mt-0"
        style={{ backgroundColor: 'var(--color-workout-daybg)' }}>
        <div className="text-center flex m-auto justify-center">
          {isEditingTitle ? (
            <input
              autoFocus
              value={title}
              onChange={(e) => onTitleChange(e.target.value)}
              onBlur={() => setIsEditingTitle(false)}
              className="w-full text-center uppercase font-bold text-2xl bg-transparent outline-none"
              style={{ color: 'var(--color-workout-text)' }}
            />
          ) : (
            <div 
              className="uppercase font-bold text-2xl cursor-pointer hover:opacity-80 group relative"
              onClick={() => setIsEditingTitle(true)}
              style={{ color: 'var(--color-workout-text)' }}>
              {title}
              <Edit2 
                size={16} 
                className="absolute -right-6 top-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity"
                style={{ color: 'var(--color-workout-text)' }}
              />
            </div>
          )}
        </div>
      </div>
  
      <div className="grid grid-cols-3 gap-6">
        <StatsDisplay icon={Calendar} value={`${daysPerWeek}x`} label="Days per Week" />
        <StatsDisplay icon={Clock} value={`${timePerSession}`} label="minutes per Session" onEdit={onTimeChange} />
        <StatsDisplay icon={Target} value={programDuration} label="Duration" />
      </div>
  
      <div className="p-8 rounded-lg place-items-center pt-5 mt-0"
        style={{ backgroundColor: 'var(--color-workout-exerbg)' }}>
        <div className="flex flex-col items-center mb-2">
          <FileText className="w-6 h-6 mb-2" style={{ color: 'var(--color-workout-alttext)' }} />
          <h3 className="text-xl font-semibold text-center"
            style={{ color: 'var(--color-workout-alttext)' }}>
            Plan Strategy
          </h3>
        </div>
        {isEditingStrategy ? (
          <textarea
            autoFocus
            value={strategy}
            onChange={(e) => onStrategyChange(e.target.value)}
            onBlur={() => setIsEditingStrategy(false)}
            className="w-full text-center bg-transparent resize-none outline-none min-h-[120px] px-8"
            style={{ 
              color: 'var(--color-workout-text)',
              width: '450px',
              height: '250px',
            }}
          />
        ) : (
          <div 
            className="text-center cursor-pointer hover:opacity-80 group relative"
            onClick={() => setIsEditingStrategy(true)}
            style={{ color: 'var(--color-workout-text)' }}>
            {strategy}
            <Edit2 
              size={16}
              className="absolute -right-6 top-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity"
              style={{ color: 'var(--color-workout-text)' }}
            />
          </div>
        )}
      </div>
  
      <div className="p-8 rounded-lg place-items-center pt-5 mt-0"
        style={{ backgroundColor: 'var(--color-workout-exerbg)' }}>
        <div className="flex flex-col items-center mb-2">
          <Weight className="w-6 h-6 mb-2" style={{ color: 'var(--color-workout-alttext)' }} />
          <h3 className="text-xl font-semibold text-center"
            style={{ color: 'var(--color-workout-alttext)' }}>
            Weight Training Guidelines
          </h3>
        </div>
        {isEditingWeights ? (
          <textarea
            autoFocus
            value={weightsValue}
            onChange={(e) => onWeightsChange(e.target.value)}
            onBlur={() => setIsEditingWeights(false)}
            className="w-full text-center bg-transparent resize-none outline-none min-h-[100px] px-8"
            style={{ 
              color: 'var(--color-workout-text)',
              width: '450px',
              height: '150px',
            }}
          />
        ) : (
          <div 
            className="text-center cursor-pointer hover:opacity-80 group relative"
            onClick={() => setIsEditingWeights(true)}
            style={{ color: 'var(--color-workout-text)' }}>
            {weightsValue || 'Click to add weight training guidelines'}
            <Edit2 
              size={16}
              className="absolute -right-6 top-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity"
              style={{ color: 'var(--color-workout-text)' }}
            />
          </div>
        )}
      </div>
  
      <div className="p-8 rounded-lg place-items-center pt-5 mt-0"
        style={{ backgroundColor: 'var(--color-workout-exerbg)' }}>
        <div className="flex flex-col items-center mb-2">
          <Utensils className="w-6 h-6 mb-2" style={{ color: 'var(--color-workout-alttext)' }} />
          <h3 className="text-xl font-semibold text-center"
            style={{ color: 'var(--color-workout-alttext)' }}>
            Nutrition Guidelines
          </h3>
        </div>
        {isEditingFood ? (
          <textarea
            autoFocus
            value={foodValue}
            onChange={(e) => onFoodChange(e.target.value)}
            onBlur={() => setIsEditingFood(false)}
            className="w-full text-center bg-transparent resize-none outline-none min-h-[100px] px-8"
            style={{ 
              color: 'var(--color-workout-text)',
              width: '450px',
              height: '150px',
            }}
          />
        ) : (
          <div 
            className="text-center cursor-pointer hover:opacity-80 group relative"
            onClick={() => setIsEditingFood(true)}
            style={{ color: 'var(--color-workout-text)' }}>
            {foodValue || 'Click to add nutrition guidelines'}
            <Edit2 
              size={16}
              className="absolute -right-6 top-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity"
              style={{ color: 'var(--color-workout-text)' }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkoutHeader;