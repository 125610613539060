import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Trash2, Plus, Pen, ArrowUp, ArrowDown, ChevronUp, ChevronDown } from 'lucide-react';
import { useWorkout } from './WorkoutContext';
import { useUserProfile } from '../../contexts/UserProfileContext';
import { useModal } from '../../contexts/ModalContext';
import { saveWorkout, emailWorkout } from '../../services/handlers/serviceHandler';
import { exportToPDF } from '../../utils/pdfExport';
import WorkoutPlanActions from './WorkoutPlanActions';
import WorkoutHeader from './WorkoutHeader';

const WorkoutPlan = ({ isMobile, isExpanded }) => {
  const { 
    setWorkout,
    workout,
    workoutFinal,
    removeExercise,
    addDay,
    removeDay,
    setCurrentDay,
    currentDay,
    workoutStrategy,
    setWorkoutStrategy,
    setSelectedOptions,
    newExerciseId,
    setNewExerciseId,
    selectedExerciseId,     // Add these from context
    setSelectedExerciseId,
    setHasUnsavedChanges
  } = useWorkout();

  const [isSaving, setIsSaving] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { showModal } = useModal();
  const { userProfile } = useUserProfile();
  const [editingField, setEditingField] = useState(null);
  const workoutPlanRef = useRef(null);
  const [workoutTitle, setWorkoutTitle] = useState("My Workout Plan");
  const [planStrategy, setPlanStrategy] = useState("A focused approach targeting strength and muscle growth");
  const [editingDayStrategy, setEditingDayStrategy] = useState(null);
  const [foodValue, setFoodValue] = useState('');
  const [weightsValue, setWeightsValue] = useState('');


  const [timePerSession, setTimePerSession] = useState("60");

  useEffect(() => {
    if (workoutFinal?.workoutPlan?.name) {
      setWorkoutTitle(workoutFinal.workoutPlan.name);
    } else if (workoutStrategy?.workoutPlan?.name) {
      setWorkoutTitle(workoutStrategy.workoutPlan.name);
    }

    if (workoutFinal?.workoutPlan?.overallStrategy) {
      setPlanStrategy(workoutFinal.workoutPlan.overallStrategy);
    } else if (workoutStrategy?.workoutPlan?.overallStrategy) {
      setPlanStrategy(workoutStrategy.workoutPlan.overallStrategy);
    }

    // Add food and weights value management
    if (workoutStrategy?.workoutPlan?.food) {
      setFoodValue(workoutStrategy.workoutPlan.food);
    }
    if (workoutStrategy?.workoutPlan?.weights) {
      setWeightsValue(workoutStrategy.workoutPlan.weights);
    }

    const newTimePerSession = 
    workoutFinal?.timePerSession || 
    workoutFinal?.workoutPlan?.timePerSession || 
    workoutStrategy?.timePerSession ||
    workoutStrategy?.workoutPlan?.timePerSession ||
    60; // fallback value
    
  setTimePerSession(String(newTimePerSession)); // Convert to string as the state expects string


  }, [workoutStrategy, workoutFinal]);

  

  useEffect(() => {
    if (newExerciseId) {
      // Select the new exercise
      setSelectedExerciseId(newExerciseId);
      
      // Scroll to it
      setTimeout(() => {
        const exerciseElement = document.querySelector(`[data-exercise-id="${newExerciseId}"]`);
        if (exerciseElement) {
          exerciseElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 100);

      // Clear the newExerciseId
      setNewExerciseId(null);
    }
  }, [newExerciseId]);

  const getUniqueExerciseIdentifier = (exerciseId, dayIndex, exerciseIndex) => {
    return `${exerciseId}-${dayIndex}-${exerciseIndex}`;
  };
  
  const handleParameterChange = (exerciseId, field, value, dayIndex, exerciseIndex) => {
    setWorkout(prev => {
      return prev.map((day, dIndex) =>
        day.map((exercise, eIndex) => {
          // Only update if we match the exercise ID and indices
          if (exercise._frontendId === exerciseId && dIndex === dayIndex && eIndex === exerciseIndex) {
            return {
              ...exercise,
              parameters: {
                ...exercise.parameters,
                [field]: value
              }
            };
          }
          return exercise;
        })
      );
    });
  };

  const renderParameter = (exercise, label, field, dayIndex, exerciseIndex, unit = '') => {
    const value = exercise.parameters?.[field] || ''; // Don't force conversion
    const isEditing = editingField === getUniqueExerciseIdentifier(exercise._frontendId, dayIndex, exerciseIndex) + `-${field}`;
    const isRest = field === 'rest';
    const fontSize = getFontSize(value);
  
    return (
      <div className="flex flex-col items-center">
        <p className="text-xl mb-2" style={{ color: 'var(--color-workout-alttext)' }}>{label}</p>
        <button
          onClick={() => handleIncrement(exercise._frontendId, field, dayIndex, exerciseIndex)}
          className="hover:opacity-70 mb-2"
          style={{ color: 'var(--color-workout-text)' }}
        >
          <ChevronUp size={24} />
        </button>
        {isEditing ? (
          <input
            type="text"
            value={value}
            maxLength={8}
            onChange={(e) => handleParameterChange(exercise._frontendId, field, e.target.value, dayIndex, exerciseIndex)}
            onBlur={() => setEditingField(null)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') setEditingField(null);
            }}
            className={`w-24 font-bold text-center bg-transparent border-b-2 ${fontSize}`}
            style={{ 
              color: 'var(--color-workout-text)',
              borderColor: 'var(--color-workout-alttext)'
            }}
            autoFocus
          />
        ) : (
          <div onClick={() => setEditingField(getUniqueExerciseIdentifier(exercise._frontendId, dayIndex, exerciseIndex) + `-${field}`)} className="text-center">
            <p className={`font-bold cursor-pointer ${fontSize}`} 
               style={{ color: 'var(--color-workout-text)' }}>
              {value}
            </p>
            {isRest && (
              <p className="text-xs mt-1 opacity-70" style={{ color: 'var(--color-workout-text)' }}>
                seconds
              </p>
            )}
          </div>
        )}
        <button
          onClick={() => handleDecrement(exercise._frontendId, field, dayIndex, exerciseIndex)}
          className="hover:opacity-70 mt-2"
          style={{ color: 'var(--color-workout-text)' }}
        >
          <ChevronDown size={24} />
        </button>
      </div>
    );
  };
  
  // Update handleIncrement function
  const handleIncrement = (exerciseId, field, dayIndex, exerciseIndex) => {
    const exercise = workout[dayIndex][exerciseIndex];
    if (!exercise) return;
    
    const currentValue = exercise.parameters?.[field] || '0';
    const numericValue = parseInt(currentValue) || 0;
    const increment = field === 'rest' ? 5 : 1;
    handleParameterChange(exercise._frontendId, field, (numericValue + increment).toString(), dayIndex, exerciseIndex);
  };
  
  // Update handleDecrement function
  const handleDecrement = (exerciseId, field, dayIndex, exerciseIndex) => {
    const exercise = workout[dayIndex][exerciseIndex];
    if (!exercise) return;
    
    const currentValue = exercise.parameters?.[field] || '0';
    const numericValue = parseInt(currentValue) || 0;
    const decrement = field === 'rest' ? 5 : 1;
    if (numericValue > decrement) {
      handleParameterChange(exerciseId, field, (numericValue - decrement).toString(), dayIndex, exerciseIndex);
    }
  };


      // Function to get font size based on text length
      const getFontSize = (text) => {
        const length = text.toString().length;
        if (length <= 3) return 'text-4xl';
        if (length <= 4) return 'text-4xl';
        if (length <= 6) return 'text-3xl';
        return 'text-xl';
      };

const moveExercise = useCallback((dayIndex, exerciseIndex, direction) => {
  const newWorkout = [...workout];
  const day = [...newWorkout[dayIndex]];
  
  // Calculate new index
  const newIndex = direction === 'up' ? exerciseIndex - 1 : exerciseIndex + 1;
  
  // Swap exercises
  [day[exerciseIndex], day[newIndex]] = [day[newIndex], day[exerciseIndex]];
  newWorkout[dayIndex] = day;
  
  // Update the workout
  setWorkout(newWorkout);

  // Update selected exercise ID to follow the moved exercise
  const movedExercise = day[newIndex];
  const newSelectedId = `${movedExercise._frontendId}-${dayIndex}-${newIndex}`;
  setSelectedExerciseId(newSelectedId);

  // Scroll to the moved exercise
  requestAnimationFrame(() => {
    const exerciseElement = document.querySelector(`[data-exercise-id="${newSelectedId}"]`);
    if (exerciseElement) {
      exerciseElement.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  });
}, [workout, setWorkout, setSelectedExerciseId]);

useEffect(() => {
  if (selectedExerciseId) {
    const handleKeyDown = (e) => {
      if (!['ArrowUp', 'ArrowDown'].includes(e.key)) return;
      e.preventDefault();

      // Parse the selected exercise ID to get indices
      const [frontendId, dayIndex, exerciseIndex] = selectedExerciseId.split('-').map((str, i) => i === 0 ? str : Number(str));
      
      if (dayIndex === undefined || exerciseIndex === undefined) return;

      if (e.key === 'ArrowUp' && exerciseIndex > 0) {
        moveExercise(dayIndex, exerciseIndex, 'up');
      } else if (e.key === 'ArrowDown' && exerciseIndex < workout[dayIndex].length - 1) {
        moveExercise(dayIndex, exerciseIndex, 'down');
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }
}, [selectedExerciseId, workout, moveExercise]);

  const getFrameLayout = (frameCount) => {
    // Default image size for 1-2 images
    if (frameCount <= 2) {
      return {
        containerClass: "flex justify-center gap-2",
        imageClass: "w-[55%] h-auto rounded-md"
      };
    }
    // 3-4 images: 2x2 grid
    else if (frameCount <= 4) {
      return {
        containerClass: "grid grid-cols-2 gap-2 w-full mx-auto place-items-center",
        imageClass: "h-auto rounded-md"
      };
    }
    // 5-6 images: 2 rows of 3
    else if (frameCount <= 6) {
      return {
        containerClass: "grid grid-cols-3 gap-2 w-full mx-auto place-items-center",
        imageClass: "h-auto rounded-md"
      };
    }
    // 7-8 images: 3 rows
    else {
      return {
        containerClass: "grid grid-cols-3 gap-2 w-fit mx-auto place-items-center",
        imageClass: "w-full h-auto rounded-md"
      };
    }
  };

  const renderExercise = (exercise, index, dayIndex) => {
    const isSelected = selectedExerciseId === `${exercise._frontendId}-${dayIndex}-${index}`;
    const frameCount = exercise.frameUrls?.length || 0;
    const { containerClass, imageClass } = getFrameLayout(frameCount);
  
    const handleValueClick = (e, exercise) => {
      e.stopPropagation(); // Prevent click from bubbling up to handleMainClick
      
      // Create unique identifier for this exercise
      const exerciseKey = `${exercise._frontendId}-${dayIndex}-${index}`;
      
      // If not currently selected, select it
      if (selectedExerciseId !== exerciseKey) {
        setCurrentDay(dayIndex);
        setSelectedExerciseId(exerciseKey);
        setSelectedOptions({
          bodyPart: { name: exercise.muscleGroup || exercise.bodyPart },
          equipment: { name: exercise.equipment },
          exercise: {
            id: exercise.id,
            uniqueId: exercise._frontendId,
            name: exercise.name || exercise.exerciseName,
            targetedMuscle: exercise.targetedMuscle,
            gifUrl: exercise.gifUrl,
            frameUrls: exercise.frameUrls,
            position: { dayIndex, index }
          },
          parameters: exercise.parameters
        });
      }
      // No else clause - we don't want to deselect when clicking parameters/notes
    };
    
    const handleMainClick = () => {
      // Create a unique identifier combining exercise id and position
      const exerciseKey = `${exercise._frontendId}-${dayIndex}-${index}`;
      const isThisExerciseSelected = selectedExerciseId === exerciseKey;
      
      if (!isThisExerciseSelected) {
        // When selecting an exercise
        setCurrentDay(dayIndex); // Always set the day to match selected exercise
        setSelectedExerciseId(exerciseKey);
        setSelectedOptions({
          bodyPart: { 
            name: exercise.muscleGroup || exercise.bodyPart 
          },
          equipment: { 
            name: exercise.equipment 
          },
          exercise: {
            id: exercise.id,
            uniqueId: exercise._frontendId,
            name: exercise.name || exercise.exerciseName,
            targetedMuscle: exercise.targetedMuscle,
            gifUrl: exercise.gifUrl,
            frameUrls: exercise.frameUrls,
            position: { dayIndex, index } // Store position info
          },
          parameters: exercise.parameters
        });
      } else {
        // When deselecting
        setSelectedExerciseId(null);
        setSelectedOptions({
          bodyPart: null,
          equipment: null,
          exercise: null,
          parameters: null
        });
      }
    };

    // Modify the isSelected check in renderExercise

    return (
      <div 
        key={exercise._frontendId}
        data-exercise-id={`${exercise._frontendId}-${dayIndex}-${index}`}
        className={`rounded-lg p-4 mb-4 relative ${
          isSelected ? 'ring-2 ring-[var(--color-workout-alttext)]' : ''
        }`}
        style={{
          backgroundColor: 'var(--color-workout-exerbg)',
          color: 'var(--color-workout-text)'
        }}
        onClick={handleMainClick}
      >
        <div className="flex justify-between items-start mb-2">
          <h3 className="text-xl" style={{ color: 'var(--color-workout-alttext)' }}>
            Exercise {index + 1}
          </h3>
          {isSelected && (
            <div className="flex space-x-2">
              <button 
                onClick={(e) => {
                  e.stopPropagation();
                  removeExercise(exercise._frontendId, dayIndex);
                }}
                className="hover:opacity-70"
                style={{ color: '#FF0000' }}
              >
                <Trash2 size={24} />
              </button>
            </div>
          )}
        </div>
  
        <div className="text-center">
          <p className="text-2xl font-medium pt-2">
            {exercise.name || exercise.exerciseName}
          </p>
          <p className="text-xl opacity-80">
            {exercise.muscleGroup || exercise.bodyPart}
          </p>
          <p className="text-base opacity-60">
            {exercise.targetedMuscle}
          </p>
        </div>
  
        {frameCount > 0 && (
          <div className="my-6 px-4">
            <div className={containerClass}>
              {exercise.frameUrls.map((frameUrl, index) => (
                frameCount <= 2 ? (
                  <img 
                    key={index}
                    src={frameUrl}
                    alt={`${exercise.name || exercise.exerciseName} frame ${index + 1}`}
                    className={imageClass}
                  />
                ) : (
                  <div key={index} className="relative">
                    <img 
                      src={frameUrl}
                      alt={`${exercise.name || exercise.exerciseName} frame ${index + 1}`}
                      className={imageClass}
                    />
                    <span className="absolute top-0.5 left-1.5 text-sm text-black font-medium backdrop-contrast-200">
                      {index + 1}
                    </span>
                  </div>
                )
              ))}
            </div>
          </div>
        )}
  
        {/* Parameters section */}
<div 
  className="grid grid-cols-3 my-5 px-6 pt-2 place-items-stretch" 
  onClick={(e) => {
    e.stopPropagation();
    handleValueClick(e, exercise, index, dayIndex);
  }}
>
{isSelected ? (
  <>
    {renderParameter(exercise, 'Sets', 'sets', dayIndex, index)}
    {renderParameter(exercise, 'Reps', 'reps', dayIndex, index)}
    {renderParameter(exercise, 'Rest', 'rest', dayIndex, index)}
  </>
) : (
    <>
      <div 
        className="flex flex-col items-center justify-start h-full"
        onClick={(e) => {
          e.stopPropagation();
          handleValueClick(e, exercise, index, dayIndex);
        }}
      >
        <p className="text-xl" style={{ color: 'var(--color-workout-alttext)' }}>Sets</p>
        <div className="flex flex-col items-center justify-center">
          <p className="text-4xl font-bold" style={{ color: 'var(--color-workout-text)' }}>
            {exercise.parameters?.sets || ''}
          </p>
        </div>
      </div>
      <div 
        className="flex flex-col items-center justify-start h-full"
        onClick={(e) => {
          e.stopPropagation();
          handleValueClick(e, exercise, index, dayIndex);
        }}
      >
        <p className="text-xl" style={{ color: 'var(--color-workout-alttext)' }}>Reps</p>
        <div className="flex flex-col items-center justify-center">
          <p className={`font-bold ${getFontSize(exercise.parameters?.reps || '')}`} 
             style={{ color: 'var(--color-workout-text)' }}>
            {exercise.parameters?.reps || ''}
          </p>
        </div>
      </div>
      <div 
        className="flex flex-col items-center justify-start h-full"
        onClick={(e) => {
          e.stopPropagation();
          handleValueClick(e, exercise, index, dayIndex);
        }}
      >
        <p className="text-xl" style={{ color: 'var(--color-workout-alttext)' }}>Rest</p>
        <div className="flex flex-col items-center justify-center">
          <p className="text-4xl font-bold" style={{ color: 'var(--color-workout-text)' }}>
            {exercise.parameters?.rest || ''}
          </p>
          <p className="text-xs opacity-70" style={{ color: 'var(--color-workout-text)' }}>
            seconds
          </p>
        </div>
      </div>
    </>
  )}
</div>

{/* Notes section */}
<div 
  className="text-center px-12 py-2" 
  onClick={(e) => {
    e.stopPropagation();
    handleValueClick(e, exercise, index, dayIndex);
  }}
>
  <p style={{ color: 'var(--color-workout-alttext)' }}>Notes</p>
  {isSelected ? (
    <textarea
  value={exercise.parameters?.notes || ''}
  onChange={(e) => handleParameterChange(exercise._frontendId, 'notes', e.target.value, dayIndex, index)}
      onClick={(e) => e.stopPropagation()}
      onFocus={(e) => e.stopPropagation()}
      className="w-full p-3 rounded-lg text-center mt-2 min-h-[100px] resize-none ring-2 ring-[var(--color-workout-alttext)]"
      style={{
        backgroundColor: 'var(--color-workout-exerbg)',
        color: 'var(--color-workout-text)',
      }}
      placeholder="Add exercise notes here"
    />
  ) : (
    <p 
      className="mt-2 min-h-[60px]" 
      style={{ color: 'var(--color-workout-text)' }}
      onClick={(e) => {
        e.stopPropagation();
        handleValueClick(e, exercise, index, dayIndex);
      }}
    >
      {exercise.parameters?.notes || 'No notes'}
    </p>
  )}
</div>
  
        {isSelected && (
          <div className="absolute bottom-2 left-2 right-2 flex justify-between">
            <button
              className={`${index === 0 ? 'opacity-30 cursor-not-allowed' : 'hover:opacity-70'}`}
              onClick={(e) => {
                e.stopPropagation();
                if (index > 0) moveExercise(dayIndex, index, 'up');
              }}
              disabled={index === 0}
            >
              <ArrowUp size={24} />
            </button>
            <button
              className={`${index === workout[dayIndex].length - 1 ? 'opacity-30 cursor-not-allowed' : 'hover:opacity-70'}`}
              onClick={(e) => {
                e.stopPropagation();
                if (index < workout[dayIndex].length - 1) moveExercise(dayIndex, index, 'down');
              }}
              disabled={index === workout[dayIndex].length - 1}
            >
              <ArrowDown size={24} />
            </button>
          </div>
        )}
      </div>
    );
  };

  const handleSaveWorkout = async () => {
    if (!userProfile?.email) {
      showModal('Error', 'You must be logged in to save workouts.', 'error');
      return;
    }
   
    setIsSaving(true);
    try {
      const workoutData = {
        content: workout,
        workoutPlan: {
          ...workoutStrategy?.workoutPlan,
          notes: {
            food: foodValue,
            weights: weightsValue
          }
        },
        notes: {
          food: foodValue,
          weights: weightsValue
        }
      };
   
      const options = {
        defaultName: workoutTitle,
        timePerSession: timePerSession,
        defaultStrategy: planStrategy, 
        programDuration: workoutStrategy?.workoutPlan?.programDuration
      };
  
      const enrichedWorkoutStrategy = {
        ...workoutStrategy,
        notes: {
          food: foodValue,
          weights: weightsValue
        }
      };
   
      await saveWorkout(
        userProfile.email,
        workoutData,
        enrichedWorkoutStrategy,
        options
      );
      setHasUnsavedChanges(false);

      showModal('Success', 'Your workout plan has been saved successfully!', 'success');
    } catch (error) {
      showModal('Error', 'Failed to save workout plan. Please try again.', 'error');
      console.error('Error saving workout:', error);
    } finally {
      setIsSaving(false);
    }
  };
  
  const handleExportPDF = useCallback(async () => {
    if (workoutPlanRef.current) {
      setIsProcessing(true);
      
      // Deselect exercise before export
      setSelectedExerciseId(null);
      
      try {
        // If in mobile mode, scroll to top before export
        if (isMobile) {
          const container = workoutPlanRef.current.closest('.overflow-y-auto');
          if (container) {
            container.scrollTop = 0;
          }
        }
        
        // Small delay to ensure state updates are reflected in the DOM
        await new Promise(resolve => setTimeout(resolve, 100));
        await exportToPDF(workoutPlanRef.current, workoutTitle);
      } finally {
        setIsProcessing(false);
      }
    }
  }, [workoutTitle, setSelectedExerciseId, isMobile]);
  
  const handleEmailWorkout = async () => {
    if (!userProfile?.email) {
      showModal('Error', 'Please log in to email workouts', 'error');
      return;
    }
  
    setIsProcessing(true);
    try {
      // Deselect exercise before export
      setSelectedExerciseId(null);
      
      // Generate PDF blob
      const pdfBlob = await exportToPDF(workoutPlanRef.current, workoutTitle, true);      
      // Send to backend
      await emailWorkout(userProfile.email, pdfBlob, workoutTitle);
      
      showModal('Success', 'Workout plan has been emailed to you!', 'success');
    } catch (error) {
      console.error('Error emailing workout:', error);
      showModal('Error', 'Failed to email workout plan', 'error');
    } finally {
      setIsProcessing(false);
    }
  };
  
  
  
  const getMusclesWorked = (day) => {
    const muscles = new Set(day.map(exercise => 
      exercise.muscleGroup || exercise.bodyPart
    ).filter(Boolean));
    return Array.from(muscles).join(', ');
  };


  return (
    <div 
      className={`sm:w-3/5 w-full relative p-4 overflow-y-auto max-w-[520px] workout-background ${
        isMobile && !isExpanded ? 'pt-16' : ''
      }`}
      ref={workoutPlanRef}
    >
          <div className="absolute right-1/3 up-2">
            <WorkoutPlanActions
              onSave={handleSaveWorkout}
              onExportPDF={handleExportPDF}
              onEmailWorkout={handleEmailWorkout}
              isSaving={isSaving}
              isProcessing={isProcessing}
            />
          </div>
    
          <WorkoutHeader 
      title={workoutTitle}
      strategy={planStrategy}
      timePerSession={timePerSession}
      daysPerWeek={workout.length}
      programDuration={workoutStrategy?.workoutPlan?.programDuration || "4-6 weeks"}
      onTitleChange={setWorkoutTitle}
      onStrategyChange={setPlanStrategy}
      onTimeChange={setTimePerSession}
      foodValue={foodValue}
      weightsValue={weightsValue}
      onFoodChange={setFoodValue}
      onWeightsChange={setWeightsValue}
    />
{workout.map((day, dayIndex) => (
  <div key={`day-${dayIndex}`} className="mb-2 p-2 rounded-lg relative pt-3 mt-0"
    style={{ backgroundColor: 'var(--color-workout-daybg)' }}
  >
    <div className="px-16 pt-0 mt-0">
      <h2 className="text-4xl font-bold text-center p-0 mt-3"
        style={{ color: 'var(--color-workout-text)' }}
      >
        DAY {dayIndex + 1}
      </h2>
      
      {editingDayStrategy === dayIndex ? (
        <textarea
          autoFocus
          value={workoutStrategy?.workoutPlan?.days?.[`day${dayIndex + 1}`]?.strategy || ''}
          onChange={(e) => {
            const updatedWorkoutStrategy = {
              ...workoutStrategy,
              workoutPlan: {
                ...workoutStrategy?.workoutPlan,
                days: {
                  ...workoutStrategy?.workoutPlan?.days,
                  [`day${dayIndex + 1}`]: {
                    ...workoutStrategy?.workoutPlan?.days?.[`day${dayIndex + 1}`],
                    strategy: e.target.value,
                    muscleGroup: getMusclesWorked(workout[dayIndex])
                  }
                }
              }
            };
            setWorkoutStrategy(updatedWorkoutStrategy);
          }}
          onBlur={() => setEditingDayStrategy(null)}
          className="w-full text-2xl text-center font-medium px-4 mb-3 mt-2 bg-transparent resize-none"
          style={{ 
            color: 'var(--color-workout-alttext)',
            outline: 'none'
          }}
        />
      ) : (
        <p 
          className="text-2xl text-center font-medium px-4 mb-3 mt-2 cursor-pointer hover:opacity-80"
          style={{ color: 'var(--color-workout-alttext)' }}
          onClick={() => setEditingDayStrategy(dayIndex)}
        >
          {workoutStrategy?.workoutPlan?.days?.[`day${dayIndex + 1}`]?.strategy || 'Click to add strategy'}
        </p>
      )}
      
      <p className="text-center mb-1"
        style={{ color: 'var(--color-workout-text)' }}
      >
        {getMusclesWorked(day)}
      </p>
      <p className="text-center"
        style={{ color: 'var(--color-workout-text)' }}
      >
        {day.length} exercises | {day.reduce((total, ex) => total + (parseInt(ex.parameters?.sets) || 0), 0)} sets
      </p>
    </div>

    <button
      onClick={() => setCurrentDay(dayIndex)}
      className="absolute left-4 top-4 px-3 py-1 rounded"
      style={{
        backgroundColor: currentDay === dayIndex ? 
          'var(--color-workout-button-selected)' : 
          'var(--color-workout-button-notselected)',
        color: 'var(--color-workout-text)'
      }}
    >
      Select
    </button>

    {workout.length > 1 && (
      <button
        onClick={() => removeDay(dayIndex)}
        className="absolute right-4 top-4 hover:opacity-70"
        style={{ color: '#FF0000' }}
      >
        <Trash2 size={32} />
      </button>
    )}

    <hr className="my-4" style={{ borderColor: 'var(--color-workout-text)', opacity: '0.2' }} />

    {day.map((exercise, index) => (
      <div key={`exercise-${dayIndex}-${index}`}>
        {renderExercise(exercise, index, dayIndex)}
      </div>
    ))}
  </div>
))}
    
          <button
            onClick={addDay}
            className="w-full h-16 rounded-lg flex items-center justify-center mb-4 hover:opacity-90"
            style={{
              backgroundColor: 'var(--color-workout-button-notselected)',
              color: 'var(--color-workout-text)'
            }}
          >
            <Plus size={24} className="mr-2" />
            Add New Day
          </button>
    
          <div className="flex justify-center align-middle text-base mt-1 font-bold"
            style={{ color: 'var(--color-workout-text)' }}
          >
            <img 
              src="/images/logo-text-banner-opacity100.png" 
              alt="Logo Banner" 
              className="h-6 object-contain opacity-100 backdrop-contrast-200"
            />
          </div>
        </div>
      );
};

export default WorkoutPlan;