import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useTheme } from '../contexts/ThemeContext';
import { useUserProfile } from '../contexts/UserProfileContext';
import { useModal } from '../contexts/ModalContext';
import userHandler from '../services/handlers/userHandler';
import paymentHandler from '../services/handlers/paymentHandler';
import { ChevronDown, ChevronUp, Loader } from 'lucide-react';

// First, let's create the TierCard component
const TierCard = ({ tier, isProcessing, handleSelectProduct, isCurrentPlan = false }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden flex flex-col border border-gray-200 h-full">
      {/* Mobile View */}
      <div className="sm:hidden">
        <div 
          onClick={() => setIsExpanded(!isExpanded)}
          className="p-4 flex items-center cursor-pointer transition-colors duration-200 hover:bg-gray-50"
        >
          {tier.imageUrl && (
            <img
              src={tier.imageUrl}
              alt={tier.name}
              className="h-16 w-16 object-contain rounded-lg mr-4"
            />
          )}
          <div className="flex-grow">
            <h2 className="text-black font-semibold text-lg">{tier.name}</h2>
            <p className="text-black font-bold text-xl">
              ${tier.price}{tier.credits ? '' : '/mo'}
            </p>
          </div>
          {isExpanded ? (
            <ChevronUp className="h-6 w-6 text-gray-500" />
          ) : (
            <ChevronDown className="h-6 w-6 text-gray-500" />
          )}
        </div>
  
        <div 
          className={`px-4 overflow-hidden transition-[max-height,opacity] duration-300 ease-in-out ${
            isExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'
          }`}
        >
          <div className="pb-4">
            {tier.description && (
              <p className="text-gray-600 text-sm my-3">{tier.description}</p>
            )}
            <ul className="space-y-3 mb-4">
              {tier.features?.map((feature, index) => (
                <li key={index} className="flex items-start">
                  <svg
                    className="h-5 w-5 text-green-500 flex-shrink-0 mt-0.5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <p className="ml-2 text-black text-sm">{feature}</p>
                </li>
              ))}
            </ul>
            <button
              onClick={(e) => {
                e.stopPropagation();
                if (isCurrentPlan) return;
                handleSelectProduct(tier.priceId, !!tier.tier);
              }}
              disabled={isProcessing || isCurrentPlan}
              className="w-full py-3 px-4 rounded-md transition duration-150 ease-in-out btn-primary bg-primary hover:bg-primary-dark text-white disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed"
            >
              {isProcessing ? (
                <div className="flex items-center justify-center">
                  <Loader className="animate-spin mr-2" size={16} />
                  Processing...
                </div>
              ) : isCurrentPlan ? 'Current Plan' : tier.tier ? 'Select Plan' : 'Purchase Credits'}
            </button>
          </div>
        </div>
      </div>
  
      {/* Desktop View */}
      <div className="hidden sm:flex sm:flex-col h-full">

        
        <div className="p-8 pt-10 flex-grow">
          <h2 className="text-black font-semibold text-2xl">{tier.name}</h2>
          <p className="mt-1 text-black font-extrabold text-3xl">
            ${tier.price}{tier.credits ? '' : '/mo'}
          </p>
          {tier.description && (
            <p className="mt-2 text-gray-600">{tier.description}</p>
          )}
          <ul className="mt-6 space-y-4">
            {tier.features?.map((feature, index) => (
              <li key={index} className="flex items-start">
                <svg
                  className="h-6 w-6 text-green-500 flex-shrink-0"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                <p className="ml-3 text-black text-base">{feature}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className="px-8 pb-8 mt-auto">
          <button
            onClick={() => handleSelectProduct(tier.priceId, !!tier.tier)}
            disabled={isProcessing || isCurrentPlan}
            className="w-full py-3 px-4 rounded-md transition duration-150 ease-in-out btn-primary bg-primary hover:bg-primary-dark text-white disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed"
          >
            {isProcessing ? (
              <div className="flex items-center justify-center">
                <Loader className="animate-spin mr-2" size={16} />
                Processing...
              </div>
            ) : isCurrentPlan ? 'Current Plan' : tier.tier ? 'Select Plan' : 'Purchase Credits'}
          </button>
        </div>
      </div>
    </div>
  );
};

const ServiceTierSelection = () => {
  const { email } = useAuth();
  const { currentTheme } = useTheme();
  const { userProfile } = useUserProfile();
  const { showModal } = useModal();
  const [products, setProducts] = useState({ subscriptions: [], oneOff: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  // Keep fetchTiers exactly as is
  const fetchTiers = useCallback(async () => {
    try {
      const fetchedTiers = await userHandler.getTiers();
      
      const validTiers = fetchedTiers.filter(product => 
        product && 
        product.tier && 
        product.price !== undefined && 
        product.name && 
        product.features
      );
      const sortedTiers = validTiers.sort((a, b) => a.price - b.price);
      
      const oneOffProducts = fetchedTiers.filter(product => 
        product && 
        product.credits &&
        product.visibleToTier === userProfile?.tier
      );
      
      setProducts({
        subscriptions: sortedTiers,
        oneOff: oneOffProducts
      });
    } catch (error) {
      console.error('Error fetching products:', error);
      showModal('Error', 'Failed to fetch product information. Please try again later.', 'error');
    } finally {
      setIsLoading(false);
    }
  }, [showModal, userProfile?.tier]);

  useEffect(() => {
    fetchTiers();
  }, [fetchTiers]);

  // Keep handleSelectProduct exactly as is
  const handleSelectProduct = async (priceId, isSubscription = true) => {
    console.log('priceId:', priceId,'isSubscription:',isSubscription)
    if (!email) {
      console.error('User email is missing');
      navigate('/login');
      return;
    }
  
    if (!userProfile) {
      console.error('User profile not loaded');
      showModal('Error', 'Unable to load user profile. Please try again.', 'error');
      return;
    }
  
    setIsProcessing(true);
    try {
      const checkoutResult = await paymentHandler.createCheckoutSession(
        email, 
        priceId, 
        isSubscription
      );
      if (checkoutResult?.url) {
        window.location.href = checkoutResult.url;
      } else {
        throw new Error('Failed to create checkout session');
      }
    } catch (error) {
      console.error('Error processing selection:', error);
      showModal('Error', `An error occurred while processing your request: ${error.message}`, 'error');
    } finally {
      setIsProcessing(false);
    }
  };

  if (isLoading || !userProfile) {
    return (
      <div className={`flex justify-center items-center h-screen bg-${currentTheme}-background`}>
        <Loader className="animate-spin h-10 w-10 text-black" />
      </div>
    );
  }

  if (!userProfile.tier || userProfile.tier === 'undefined') {
    return (
      <div className="min-h-full min-w-full overflow-auto">
        <div className="min-h-full bg-light-secondary w-full flex-grow py-12 px-4 sm:px-10 lg:px-8 relative">
          <div className="max-w-7xl mx-auto">
            <h1 className="text-4xl font-bold text-center text-gray-900 mb-12">
              Select Your Service
            </h1>
            
            <div className="w-full max-w-5xl mx-auto">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                {products.subscriptions.map((tier) => (
                  <TierCard
                    key={tier.id}
                    tier={tier}
                    isProcessing={isProcessing}
                    handleSelectProduct={handleSelectProduct}
                  />
                ))}
              </div>
            </div>
          </div>
          <p className="mt-12 text-sm text-gray-500 text-center">
            * Prices are shown in USD. Applicable taxes will be calculated at checkout.
          </p>
        </div>
      </div>
    );
  }

  const currentTier = products.subscriptions.find(tier => 
    tier.tier.toLowerCase() === userProfile.tier.toLowerCase()
  );
  
  const eligibleCreditPack = products.oneOff.find(product => 
    product.visibleToTier === userProfile.tier
  );

  return (
    <div className="min-h-full min-w-full">
      <div className="min-h-full bg-light-secondary w-full flex-grow py-12 px-4 sm:px-10 lg:px-8 relative">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl font-bold text-center text-gray-900 mb-12">
            Your Service
          </h1>
          
          <div className="w-full max-w-6xl mx-auto">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              {eligibleCreditPack && (
                <TierCard
                  tier={eligibleCreditPack}
                  isProcessing={isProcessing}
                  handleSelectProduct={handleSelectProduct}
                />
              )}
              {currentTier && (
                <TierCard
                  tier={currentTier}
                  isProcessing={isProcessing}
                  handleSelectProduct={handleSelectProduct}
                  isCurrentPlan={true}
                />
              )}
            </div>
          </div>
        </div>
        <p className="mt-12 text-sm text-gray-500 text-center">
          * Prices are shown in USD. Applicable taxes will be calculated at checkout.
        </p>
      </div>
    </div>
  );
};

export default ServiceTierSelection;