import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { getProxiedImage} from '../services/handlers/serviceHandler';

// 1. Get background style
const getWorkoutBackgroundStyle = () => {
  const workoutBackground = document.querySelector('.workout-background');
  if (!workoutBackground) return null;
  
  const computedStyle = window.getComputedStyle(workoutBackground);
  return {
    backgroundImage: computedStyle.backgroundImage,
    backgroundSize: computedStyle.backgroundSize,
    backgroundRepeat: computedStyle.backgroundRepeat,
    backgroundPosition: computedStyle.backgroundPosition
  };
};

// 2. Process EditableText
const processEditableText = (element) => {
  element.querySelectorAll('[data-pdf-content]').forEach(container => {
    const content = container.getAttribute('data-pdf-content');
    if (!content) return;

    // Find the original textarea/input if it exists
    const originalInput = container.querySelector('textarea, input');
    const computedStyle = window.getComputedStyle(originalInput || container);

    // Create new div for content
    const div = document.createElement('div');
    div.innerText = content;

    // Get specified text color
    const specifiedTextColor = container.getAttribute('data-text-color');
    
    // Get color from CSS variable or specified color
    let textColor = computedStyle.color;
    if (specifiedTextColor) {
      if (specifiedTextColor.includes('var(')) {
        const variableName = specifiedTextColor.match(/var\((.*?)\)/)[1];
        textColor = getComputedStyle(document.documentElement)
          .getPropertyValue(variableName)
          .trim();
      } else {
        textColor = specifiedTextColor;
      }
    } else if (container.hasAttribute('style') && container.style.color?.includes('var(')) {
      const variableName = container.style.color.match(/var\((.*?)\)/)[1];
      textColor = getComputedStyle(document.documentElement)
        .getPropertyValue(variableName)
        .trim();
    }

    // Apply specific styles based on the EditableText type
    if (container.closest('.p-4.rounded-lg')) {
      // Plan title styles
      Object.assign(div.style, {
        fontSize: '24px',
        fontWeight: '700',
        textTransform: 'uppercase',
        color: textColor,
        textAlign: 'center',
        width: '100%',
        padding: '0.5rem 1rem'
      });
    } else if (container.closest('.p-8.rounded-lg')) {
      // Plan strategy styles
      Object.assign(div.style, {
        fontSize: '16px',
        lineHeight: '1.5',
        color: textColor,
        textAlign: 'center',
        width: '100%',
        padding: '0.5rem'
      });
    } else if (container.closest('.px-16')) {
      // Day strategy styles
      Object.assign(div.style, {
        fontSize: '24px',
        fontWeight: '500',
        color: textColor,
        textAlign: 'center',
        width: '100%',
        marginBottom: '12px',
        lineHeight: '1.2'  // Reduced line height
      });
    }

    // Apply base styles
    Object.assign(div.style, {
      fontFamily: computedStyle.fontFamily,
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      overflowWrap: 'break-word',
      display: 'block',
      minHeight: 'fit-content',
      height: 'auto'
    });

    // Clear and append
    container.innerHTML = '';
    container.appendChild(div);
  });
};

// 3. Clone elements
const cloneElementForPDF = (originalElement) => {
  const wrapper = document.createElement('div');
  const backgroundStyle = getWorkoutBackgroundStyle();
  Object.assign(wrapper.style, {
    width: '520px',
    maxWidth: '520px',
    position: 'relative',
    padding: '1rem',
    ...backgroundStyle,
    display: 'flex',
    flexDirection: 'column'
  });

  const clone = originalElement.cloneNode(true);
  clone.className = originalElement.className;
  const computedStyle = window.getComputedStyle(originalElement);

  // Apply element-specific background (for days)
  if (clone.classList.contains('rounded-lg') && clone.classList.contains('relative')) {
    clone.style.backgroundColor = 'var(--color-workout-daybg)';
  }
  
  // Prepare exercise names for YouTube links
  clone.querySelectorAll('.text-2xl.font-medium.pt-2').forEach(exerciseNameElement => {
    const exerciseName = exerciseNameElement.textContent.trim();
    const youtubeSearchQuery = exerciseName.replace(/\s+/g, '+');
    const youtubeUrl = `https://www.youtube.com/results?search_query=How+to+${youtubeSearchQuery}`;
    
    // Add data attributes for PDF link creation
    exerciseNameElement.setAttribute('data-youtube-link', youtubeUrl);
    exerciseNameElement.style.cursor = 'pointer';
  });

  Object.assign(clone.style, {
    color: computedStyle.color,
    width: '100%',
    margin: computedStyle.margin,
    padding: computedStyle.padding
  });

  processEditableText(clone);

  // Remove interactive elements
  clone.querySelectorAll('button:not(.keep-in-pdf)').forEach(btn => btn.remove());
  clone.querySelectorAll('.ring-2').forEach(el => el.classList.remove('ring-2'));
  
  wrapper.appendChild(clone);
  return wrapper;
};

// 4. Process images
const processImages = async (element) => {
  const images = element.querySelectorAll('img');
  await Promise.all(Array.from(images).map(async (img) => {
    if (img.src.includes('/frames/')) {
      try {
        const key = img.src.split('/frames/')[1];
        const proxiedUrl = await getProxiedImage(`frames/${key}`);
        img.src = proxiedUrl;
        await new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        });
      } catch (error) {
      }
    }
  }));
};

// 5. Capture element
const captureElement = async (element) => {
  const clone = cloneElementForPDF(element);
  clone.style.position = 'absolute';
  clone.style.left = '-9999px';
  clone.style.top = '0';
  document.body.appendChild(clone);
  
  await processImages(clone);

  // Store link positions for later use
  const linkPositions = [];
  clone.querySelectorAll('[data-youtube-link]').forEach(linkElement => {
    const rect = linkElement.getBoundingClientRect();
    const cloneRect = clone.getBoundingClientRect();
    linkPositions.push({
      url: linkElement.getAttribute('data-youtube-link'),
      text: linkElement.textContent.trim(),
      x: rect.left - cloneRect.left,
      y: rect.top - cloneRect.top,
      width: rect.width,
      height: rect.height
    });
  });

  const canvas = await html2canvas(clone, {
    scale: 2,
    useCORS: true,
    logging: false,
    width: 520,
    backgroundColor: null,
    ignoreElements: (el) => el.tagName === 'SCRIPT' || el.classList.contains('pdf-ignore')
  });

  document.body.removeChild(clone);
  
  return {
    dataUrl: canvas.toDataURL('image/jpeg', 0.7),
    width: canvas.width / 2,
    height: canvas.height / 2,
    linkPositions
  };
};

// 6. Main export function
// pdfExport.js
export const exportToPDF = async (workoutPlanElement, workoutTitle = 'Workout Plan', returnBlob = false) => {
  try {
    const header = workoutPlanElement.querySelector('.space-y-6.mb-5');
    const days = workoutPlanElement.querySelectorAll('.mb-2.rounded-lg.relative');
    const footer = workoutPlanElement.querySelector('.flex.justify-center.align-middle.text-base.mt-1.font-bold');

    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'pt',
      format: [520, 842]
    });

    let currentY = 0;
    
    const headerCapture = await captureElement(header);
    pdf.setPage(1);
    pdf.internal.pageSize.setHeight(headerCapture.height);
    
    pdf.addImage(
      headerCapture.dataUrl,
      'JPEG',
      0,
      0,
      520,
      headerCapture.height,
      undefined,
      'FAST'
    );

    // Add links for header if any
    headerCapture.linkPositions.forEach(link => {
      pdf.link(link.x, link.y, link.width, link.height, { url: link.url });
    });

    currentY = headerCapture.height;

    for (let i = 0; i < days.length; i++) {
      const dayCapture = await captureElement(days[i]);
      
      if (i === days.length - 1 && footer) {
        const footerCapture = await captureElement(footer);
        pdf.addPage([520, dayCapture.height + footerCapture.height]);
        currentY = 0;
        
        pdf.addImage(
          dayCapture.dataUrl,
          'JPEG',
          0,
          currentY,
          520,
          dayCapture.height,
          undefined,
          'FAST'
        );

        // Add links for the day
        dayCapture.linkPositions.forEach(link => {
          pdf.link(link.x, currentY + link.y, link.width, link.height, { url: link.url, newWindow: true });
        });
        
        currentY += dayCapture.height;
        
        pdf.addImage(
          footerCapture.dataUrl,
          'JPEG',
          0,
          currentY,
          520,
          footerCapture.height,
          undefined,
          'FAST'
        );

        // Add links for footer if any
        footerCapture.linkPositions.forEach(link => {
          pdf.link(link.x, currentY + link.y, link.width, link.height, { url: link.url });
        });
      } else {
        pdf.addPage([520, dayCapture.height]);
        currentY = 0;
        
        pdf.addImage(
          dayCapture.dataUrl,
          'JPEG',
          0,
          currentY,
          520,
          dayCapture.height,
          undefined,
          'FAST'
        );

        // Add links for the day
        dayCapture.linkPositions.forEach(link => {
          pdf.link(link.x, currentY + link.y, link.width, link.height, { url: link.url, newWindow: true });
        });
      }
    }

    if (returnBlob) {
      return pdf.output('blob');
    } else {
      const formattedDate = new Date().toISOString().split('T')[0];
      const fileName = `PDFTrainer - ${workoutTitle.replace(/[^\w\s-]/g, '')} - ${formattedDate}.pdf`;
      pdf.save(fileName);
    }

  } catch (error) {
    console.error('Error generating PDF:', error);
    throw error;
  }
};