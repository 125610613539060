import React, { useState } from 'react';
import { useUserProfile } from '../../../contexts/UserProfileContext';
import { 
  User2, Calendar, Clock, Target, Dumbbell,
  Brain, ArrowLeft, Sparkles, Split
} from 'lucide-react';
import { generateWorkoutPlan } from '../../../services/handlers/serviceHandler';
import { useWorkout } from '../WorkoutContext';

const SummaryItem = ({ icon, label, value }) => (
  <div className="flex items-center p-4 bg-gray-50 rounded-lg">
    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-blue-100">
      {icon}
    </div>
    <div className="ml-4">
      <p className="text-sm text-gray-500">{label}</p>
      <p className="font-medium text-gray-900">{value}</p>
    </div>
  </div>
);

const StepSix = ({ formData, onBack, onGenerate, updateFields }) => {
  const {
    setWorkoutStrategy,
    showFeedbackHints,
    previousStrategy,
    setShowFeedbackHints,
  } = useWorkout();
  const [error, setError] = useState(null);
  const { userProfile } = useUserProfile();

  const presetPreferences = [
    "Add cardio at the end of each day",
    "More focus on compound exercises",
    "Avoid cardio",
    "No leg day",
    "Focus on compound exercises only"
  ];

  const handlePresetClick = (preset) => {
    const currentNotes = formData.additionalNotes || "";
    const newNotes = currentNotes
      ? `${currentNotes}, ${preset}`
      : preset;
    updateFields({ additionalNotes: newNotes });
  };

  const handleGenerateWorkout = async () => {
    setError(null);
    setShowFeedbackHints(false);
    setWorkoutStrategy(null);
    onGenerate();
    
    try {
      const workoutPlan = await generateWorkoutPlan({
        ...formData,
        previousWorkoutStrategy: previousStrategy,
        email: userProfile.email
      });

      updateFields({originalAdditionalNotes: ''})

      const enrichedWorkoutStrategy = {
        ...workoutPlan,
        workoutPlan: {
          ...workoutPlan.workoutPlan,
          notes: {
            food: workoutPlan.notes?.food || '',
            weights: workoutPlan.notes?.weights || '',
            repsAndSets: workoutPlan.notes?.repsAndSets || ''
          }
        },
        notes: {
          food: workoutPlan.workoutPlan.notes?.food || '',
          weights: workoutPlan.workoutPlan.notes?.weights || '',
          repsAndSets: workoutPlan.workoutPlan.notes?.repsAndSets || ''
        }
      };
  
      setWorkoutStrategy(enrichedWorkoutStrategy);
    } catch (err) {
      setError(err.message || 'Failed to generate workout plan.');
      console.error('Workout generation error:', err);
      onBack();
    }
  };

  const summaryData = [
    {
      icon: <User2 className="w-5 h-5 text-blue-600" />,
      label: "Profile",
      value: `${formData.name} · ${formData.gender} · ${formData.ageGroup} years`
    },
    {
      icon: <Target className="w-5 h-5 text-green-600" />,
      label: "Goal",
      value: formData.objective
    },
    {
      icon: <Split className="w-5 h-5 text-purple-600" />,
      label: "Workout Split",
      value: formData.split
    },
    {
      icon: <Dumbbell className="w-5 h-5 text-purple-600" />,
      label: "Focus Areas",
      value: formData.focusAreas.join(", ")
    },
    {
      icon: <Brain className="w-5 h-5 text-yellow-600" />,
      label: "Experience Level",
      value: formData.experienceLevel
    },
    {
      icon: <Calendar className="w-5 h-5 text-red-600" />,
      label: "Weekly Schedule",
      value: `${formData.daysPerWeek} days per week`
    },
    {
      icon: <Clock className="w-5 h-5 text-indigo-600" />,
      label: "Session Duration",
      value: `${formData.timePerSession} minutes`
    }
  ];

  return (
    <div className="space-y-6 w-full">
      <div className="text-center mb-8">
        <h2 className="text-2xl font-bold text-gray-900">Review Your Plan</h2>
        <p className="text-gray-500 mt-2">Please review your preferences before generating your workout plan</p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {summaryData.map((item, index) => (
          <SummaryItem key={index} {...item} />
        ))}
      </div>

      <div className="bg-gray-50 rounded-lg p-4">
        <p className="text-sm text-gray-500 mb-2">Available Equipment</p>
        <div className="flex flex-wrap gap-2">
          {formData.equipment.map((item) => (
            <span 
              key={item}
              className="px-3 py-1 bg-blue-100 text-blue-700 rounded-full text-sm font-medium"
            >
              {item}
            </span>
          ))}
        </div>
      </div>

      {formData.limitations && (
        <div className="bg-yellow-50 rounded-lg p-4">
          <p className="text-sm text-yellow-700 font-medium mb-1">Physical Limitations</p>
          <p className="text-sm text-yellow-600">{formData.limitations}</p>
        </div>
      )}

      <div className="bg-white rounded-lg relative">
        <label className="block">
          <span className="text-gray-700 font-medium mb-2 block">Additional Workout Preferences</span>
          
          {/* Preset Preferences Section */}
          <div className="mb-4">
            <p className="text-sm text-gray-500 mb-2">Quick select preferences:</p>
            <div className="flex flex-wrap gap-2">
              {presetPreferences.map((preset, index) => (
                <button
                  key={index}
                  onClick={() => handlePresetClick(preset)}
                  className="px-3 py-2 bg-blue-50 text-blue-600 rounded-lg text-sm font-medium
                    hover:bg-blue-100 transition-colors duration-200"
                >
                  {preset}
                </button>
              ))}
            </div>
          </div>

          <p className="text-sm text-gray-500 mb-2">
            {showFeedbackHints ? (
              <span className="text-red-600">
                Please provide feedback on what you'd like to change in the workout strategy
              </span>
            ) : (
              "Select a quick preference above or type your custom requirements below"
            )}
          </p>
          
          <textarea
            value={formData.additionalNotes}
            onChange={(e) => updateFields({ additionalNotes: e.target.value })}
            placeholder={showFeedbackHints ? 
              "Example: I'd prefer more focus on upper body, less cardio sessions, or different split of muscle groups..." :
              "Your selected preferences will appear here. You can also type your custom requirements..."
            }
            className={`w-full h-32 p-3 border rounded-lg resize-none transition-all duration-200
              ${showFeedbackHints ? 
                'border-red-500 focus:border-red-500 focus:ring-red-500' : 
                'border-gray-300 focus:border-blue-500 focus:ring-blue-500'
              }`
            }
          />
        </label>
      </div>

      {error && (
        <div className="p-4 bg-red-50 text-red-600 rounded-lg text-sm">
          {error}
        </div>
      )}

      <div className="flex flex-col gap-4 mt-8">
        <button
          onClick={handleGenerateWorkout}
          className="w-full py-4 bg-blue-600 text-white rounded-xl font-semibold 
            transition-all duration-200 flex items-center justify-center gap-2
            hover:bg-blue-700 hover:shadow-lg transform hover:-translate-y-0.5"
        >
          <Sparkles className="w-5 h-5" />
          Generate Workout Plan
        </button>

        <button
          onClick={onBack}
          className="w-full py-3 rounded-xl font-medium transition-all duration-200 
            flex items-center justify-center gap-2
            text-gray-600 hover:text-gray-800 hover:bg-gray-50"
        >
          <ArrowLeft className="w-4 h-4" />
          Back to Edit
        </button>
      </div>
    </div>
  );
};

export default StepSix;