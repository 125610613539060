import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { X } from 'lucide-react';

const DelayedModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  
  useEffect(() => {
    // Only show on landing page
    if (location.pathname !== '/') return;
    
    // Check if user has previously dismissed the modal
    const hasSeenModal = localStorage.getItem('hasSeenWelcomeModal');
    if (hasSeenModal) return;
    
    // Set timer for 5 seconds
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, 5000);
    
    // Cleanup timer
    return () => clearTimeout(timer);
  }, [location]);
  
  const handleDismiss = () => {
    setIsOpen(false);
    localStorage.setItem('hasSeenWelcomeModal', 'true');
  };

  if (!isOpen) return null;
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center" onClick={handleDismiss}>
      <div 
        className="relative max-w-2xl mx-4"
        onClick={e => e.stopPropagation()}
      >
        {/* Close button */}
        <button
          onClick={handleDismiss}
          className="absolute -top-2 -right-2 p-1 rounded-full bg-white hover:bg-gray-100 transition-colors z-10"
          aria-label="Close promotional modal"
        >
          <X className="h-6 w-6 text-red-500" />
        </button>

        {/* Promo Image */}
        <img 
          src="/images/promo.jpg" 
          alt="Promotional offer" 
          className="w-full h-full object-cover rounded-lg"
        />
      </div>
    </div>
  );
};

export default DelayedModal;