import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Check } from 'lucide-react';
import AnimatedLogoRows from './AnimatedLogoRows';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const PricingPlan = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 },
    },
  };

  const handleGetStarted = () => {
    if (user) {
      navigate('/workspace');
    } else {
      navigate('/login');
    }
  };

  const PricingCard = ({ title, price, features, isPopular }) => (
    <motion.div
      variants={cardVariants}
      className={`bg-gray-900 rounded-2xl p-6 md:p-8 flex flex-col relative ${
        isPopular ? 'border-2 border-logoRed' : 'border border-gray-700'
      }`}
    >
      {isPopular && (
        <div className="absolute -top-3 left-1/2 transform -translate-x-1/2 bg-logoRed text-white text-sm font-semibold py-1 px-4 rounded-full whitespace-nowrap">
          Most Popular
        </div>
      )}
      <h3 className="text-2xl font-bold text-white mb-2 mt-2">{title}</h3>
      <div className="mb-4">
        <span className="text-4xl font-bold text-white">${price}</span>
        <span className="text-gray-400 ml-2">/3 months</span>
      </div>
      <ul className="space-y-4 mb-8 flex-grow">
        {features.map((feature, index) => (
          <li key={index} className="flex items-start">
            <Check className="text-logoRed mr-2 h-5 w-5 mt-1 flex-shrink-0" />
            <span className="text-gray-300">{feature}</span>
          </li>
        ))}
      </ul>
      <button 
        onClick={handleGetStarted}
        className={`w-full py-3 px-6 rounded-full font-semibold transition-colors duration-300 ${
          isPopular 
            ? 'bg-logoRed hover:bg-red-700 text-white' 
            : 'bg-gray-700 hover:bg-gray-600 text-white'
        }`}
      >
        {user ? 'Go to Workspace' : 'Get Started'}
      </button>
    </motion.div>
  );

  return (
    <section id="pricing" className="py-16 px-8 bg-black text-white">
      <motion.div
        ref={ref}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={containerVariants}
        className="max-w-6xl mx-auto"
      >
        <motion.h2 variants={cardVariants} className="text-3xl md:text-5xl mb-6 text-center font-fira">
          Simple, Transparent Pricing
        </motion.h2>
        <motion.p variants={cardVariants} className="text-xl md:text-2xl text-gray-400 mb-12 text-center font-fira">
          Choose the plan that's right for you
        </motion.p>
        
        <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          <PricingCard
            title="Fit Builder"
            price="9.99"
            features={[
              "Access to workout builder",
              "1000+ exercises library",
              "PDF download & sharing",
              "Save Workouts Plans",
              "Chat support",
              "Color themes"
            ]}
          />
          
          <PricingCard
            title="Pro"
            price="21.99"
            isPopular={true}
            features={[
              "Everything in Basic",
              "5 AI-powered workout plans",
              "Custom exercise notes",
              "Priority support",
              "Preferential credits rate"
            ]}
          />
        </div>
      </motion.div>
      
      <AnimatedLogoRows />
    </section>
  );
};

export default PricingPlan;