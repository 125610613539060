import React, { useState, useEffect } from 'react';
import { ArrowLeft, ChevronUp, ChevronDown } from 'lucide-react';

const ParameterSelectionContent = ({ onSelect, onBack, onAddExercise, currentDay, isEditMode, initialParams }) => {
  const [params, setParams] = useState({ 
    sets: '3', 
    reps: '10', 
    rest: '60',
    notes: '' 
  });
  const [editingField, setEditingField] = useState(null);

  useEffect(() => {
    if (initialParams) {
      setParams(initialParams);
    }
  }, [initialParams]);

  const handleIncrement = (field) => {
    setParams(prev => ({
      ...prev,
      [field]: isNaN(prev[field]) ? prev[field] : String(Number(prev[field]) + 1)
    }));
  };

  const handleDecrement = (field) => {
    setParams(prev => ({
      ...prev,
      [field]: isNaN(prev[field]) ? prev[field] : String(Math.max(0, Number(prev[field]) - 1))
    }));
  };

  const handleDirectEdit = (field) => {
    setEditingField(field);
  };

  const handleKeyDown = (e, field) => {
    if (e.key === 'Enter') {
      setEditingField(null);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setParams(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleBlur = () => {
    setEditingField(null);
  };

  const renderParameter = (label, field, unit = '') => {
    return (
      <div className="flex flex-col items-center">
        <p className="text-xl mb-2" style={{ color: 'var(--color-workout-alttext)' }}>{label}</p>
        <button
          onClick={() => handleIncrement(field)}
          className="hover:opacity-70 mb-2"
          style={{ color: 'var(--color-workout-text)' }}
        >
          <ChevronUp size={24} />
        </button>
        {editingField === field ? (
          <input
            type="text"
            name={field}
            value={params[field]}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={(e) => handleKeyDown(e, field)}
            className="w-24 text-4xl font-bold text-center bg-transparent border-b-2"
            style={{ 
              color: 'var(--color-workout-text)',
              borderColor: 'var(--color-workout-alttext)',
              outline: 'none'
            }}
            autoFocus
          />
        ) : (
          <div
            onClick={() => handleDirectEdit(field)}
            className="cursor-pointer hover:opacity-80"
          >
            <p className="text-4xl font-bold" style={{ color: 'var(--color-workout-text)' }}>
              {params[field]}{unit}
            </p>
          </div>
        )}
        <button
          onClick={() => handleDecrement(field)}
          className="hover:opacity-70 mt-2"
          style={{ color: 'var(--color-workout-text)' }}
        >
          <ChevronDown size={24} />
        </button>
      </div>
    );
  };

  return (
    <div className="h-full flex flex-col p-6" style={{ background: 'var(--color-workout-mainbg-gradient)' }}>
      <div className="flex items-center mb-8">
        <button 
          onClick={onBack} 
          className="mr-4 hover:opacity-70"
          style={{ color: 'var(--color-workout-text)' }}
        >
          <ArrowLeft size={24} />
        </button>
        <h2 
          className="text-2xl font-bold"
          style={{ color: 'var(--color-workout-text)' }}
        >
          Set Exercise Parameters
        </h2>
      </div>

      <div className="flex-grow">
        <div 
          className="p-6 rounded-lg mb-6"
          style={{ backgroundColor: 'var(--color-workout-daybg)' }}
        >
          <div className="grid grid-cols-3 gap-8 place-items-center mb-8">
            {renderParameter('Sets', 'sets')}
            {renderParameter('Reps', 'reps')}
            {renderParameter('Rest', 'rest', 's')}
          </div>

          <div className="text-center px-4">
            <p 
              className="text-xl mb-3"
              style={{ color: 'var(--color-workout-alttext)' }}
            >
              Notes
            </p>
            <textarea
              name="notes"
              value={params.notes}
              onChange={handleChange}
              rows="3"
              className="w-full p-3 rounded-lg text-center"
              style={{
                backgroundColor: 'var(--color-workout-exerbg)',
                color: 'var(--color-workout-text)',
                border: '1px solid var(--color-workout-alttext)'
              }}
              placeholder="Add exercise notes here"
            />
          </div>
        </div>

        <div className="space-y-4">
          <button 
            onClick={() => onAddExercise(params)} 
            className="w-full p-3 rounded-lg transition duration-200 hover:opacity-90"
            style={{
              backgroundColor: 'var(--color-workout-button-selected)',
              color: 'var(--color-workout-text)'
            }}
          >
            {isEditMode ? `Update Exercise for Day ${currentDay + 1}` : `Add Exercise to Day ${currentDay + 1}`}
          </button>
          <button 
            onClick={() => onSelect(params)} 
            className="w-full p-3 rounded-lg transition duration-200 hover:opacity-90"
            style={{
              backgroundColor: 'var(--color-workout-button-notselected)',
              color: 'var(--color-workout-text)'
            }}
          >
            Set Parameters Only
          </button>
        </div>
      </div>
    </div>
  );
};

export default ParameterSelectionContent;