import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { WorkoutProvider, useWorkout } from './WorkoutContext';
import { useModal } from '../../contexts/ModalContext';
import BuilderMenu from './BuilderMenu';
import WorkoutPlan from './WorkoutPlan';
import WorkoutSummary from './WorkoutSummary';
import { Maximize2, Minimize2 } from 'lucide-react';

const WorkoutBuilderContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { loadWorkout, hasUnsavedChanges } = useWorkout();
  const { showModal } = useModal();
  const loadedRef = useRef(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState(location.state?.initialTab || 'builder');

  useEffect(() => {
    if (location.state?.loadedWorkout && !loadedRef.current) {
      loadWorkout(location.state.loadedWorkout);
      loadedRef.current = true;
    }
  }, [location.state, loadWorkout]);

  // Handle navigation attempts
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
        return e.returnValue;
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    
    const currentPath = location.pathname;
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      
      if (hasUnsavedChanges && location.pathname !== currentPath) {
        showModal(
          'Unsaved Changes',
          'You have unsaved changes in your workout plan. Are you sure you want to leave? Your changes will be lost.',
          'warning',
          [
            {
              label: 'Stay',
              onClick: () => navigate(currentPath),
              primary: true
            },
            {
              label: 'Leave',
              onClick: () => {},
              variant: 'destructive'
            }
          ]
        );
      }
    };
  }, [location, hasUnsavedChanges, navigate, showModal]);

  const handleNavigation = (nextPath) => {
    if (hasUnsavedChanges) {
      showModal(
        'Unsaved Changes',
        'You have unsaved changes in your workout plan. Are you sure you want to leave? Your changes will be lost.',
        'warning',
        [
          {
            label: 'Stay',
            onClick: () => {},
            primary: true
          },
          {
            label: 'Leave',
            onClick: () => navigate(nextPath),
            variant: 'destructive'
          }
        ]
      );
    } else {
      navigate(nextPath);
    }
  };

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const renderLeftPanel = () => (
    <div className="w-full h-full overflow-hidden flex flex-col">
      {/* Tabs */}
      <div className="flex border-b border-gray-200 w-4/5 sm:w-full">
        <button
          className={`flex-1 py-2 lg:py-4 text-base lg:text-lg font-medium ${
            activeTab === 'builder' 
              ? 'border-b-2 border-purple-600 text-purple-600' 
              : 'text-gray-500 hover:text-gray-700'
          }`}
          onClick={() => setActiveTab('builder')}
        >
          Builder
        </button>
        <button
          className={`flex-1 py-2 lg:py-4 text-base lg:text-lg font-medium ${
            activeTab === 'summary' 
              ? 'border-b-2 border-purple-600 text-purple-600' 
              : 'text-gray-500 hover:text-gray-700'
          }`}
          onClick={() => setActiveTab('summary')}
        >
          Summary
        </button>
      </div>

      {/* Content */}
      <div className="flex-1 overflow-hidden">
        {activeTab === 'builder' ? (
          <BuilderMenu onNavigate={handleNavigation} />
        ) : (
          <WorkoutSummary onTabChange={setActiveTab} onNavigate={handleNavigation} />
        )}
      </div>
    </div>
  );

  return (
    <>
      {/* Desktop Layout */}
      <div className="hidden lg:flex sm:w-4/6 h-full bg-gray-100 w-full">
        {renderLeftPanel()}
        <WorkoutPlan />
      </div>

      {/* Mobile Layout */}
      <div className="lg:hidden flex h-full bg-gray-100 relative w-full">
        {/* Left Panel with Tabs */}
        <div className={`flex-grow relative ${isExpanded ? 'w-[80%]' : 'w-[100%]'}`}>
          {renderLeftPanel()}
        </div>

        {/* Split Button */}
        <button
          onClick={toggleExpand}
          className={`fixed top-1/2 -translate-y-1/2 transform 
            bg-purple-600 hover:bg-purple-700 text-white rounded-full p-2 shadow-lg z-40
            border-2 border-white transition-all duration-300 ease-in-out
            ${isExpanded ? 'right-[20%]' : 'right-[90%]'}`}
        >
          {isExpanded ? <Minimize2 size={20} /> : <Maximize2 size={20} />}
        </button>

        {/* Mobile Workout Plan */}
        <div 
          className={`fixed right-0 top-0 h-full transition-all duration-300 ease-in-out 
            ${isExpanded ? 'w-[20%]' : 'w-[100%]'}
            bg-white shadow-lg z-20`}
        >
          <div className="relative h-full w-full">
            <div 
              className={`absolute top-0 right-0
                ${isExpanded 
                  ? 'scale-[0.2] mt-16 origin-top-right w-[500%] h-[500%]' 
                  : 'scale-100 w-full h-full'}`}
              style={{
                height: isExpanded ? '500%' : '100%',
                overflowY: isExpanded ? 'auto' : 'auto',
                paddingTop: isExpanded ? '0' : '60px'
              }}
            >
              <div className="overflow-y-auto h-full">
                <WorkoutPlan isMobile={true} isExpanded={isExpanded} />
              </div>
            </div>
          </div>
        </div>

        {/* Overlay */}
        {!isExpanded && (
          <div 
            className="fixed inset-0 bg-black bg-opacity-50 z-10"
            onClick={toggleExpand}
          />
        )}
      </div>
    </>
  );
};

const WorkoutBuilder = () => {
  return (
    <WorkoutProvider>
      <WorkoutBuilderContent />
    </WorkoutProvider>
  );
};

export default WorkoutBuilder;