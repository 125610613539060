import React, { useState, useEffect } from 'react';
import { Clock, Calendar, Plus, Minus } from 'lucide-react';

const TimeSlider = ({ value, onChange }) => {
  const min = 30;
  const max = 120;
  const step = 15;
  const percentage = ((value - min) / (max - min)) * 100;
  
  const formatTime = (minutes) => `${minutes} minutes`;

  return (
    <div className="w-full space-y-6">
      <div className="flex justify-center">
        <span className="text-2xl font-bold text-blue-600">
          {formatTime(value)}
        </span>
      </div>
      
      <div className="relative w-full h-6 flex items-center">
        {/* Track */}
        <div className="absolute w-full h-2 bg-gray-200 rounded-full">
          {/* Progress */}
          <div 
            className="absolute h-full bg-blue-500 rounded-full transition-all duration-200 ease-out"
            style={{ width: `${percentage}%` }}
          />
        </div>

        {/* Hidden Range Input */}
        <input
          type="range"
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={(e) => onChange(parseInt(e.target.value))}
          className="absolute w-full appearance-none bg-transparent cursor-pointer"
          style={{
            '--range-thumb-size': '24px',
            '--range-track-height': '0px',
          }}
        />

        {/* Thumb */}
        <div 
          className="absolute w-6 h-6 bg-white border-4 border-blue-500 rounded-full shadow-lg pointer-events-none transition-all duration-200 ease-out"
          style={{ 
            left: `calc(${percentage}% - 12px)`
          }}
        />
      </div>
    </div>
  );
};

const StepFour = ({ formData, updateFields }) => {
  const daysPerWeek = [2, 3, 4, 5, 6];
  
  // Set initial days per week to 2 if not already set
  useEffect(() => {
    if (!formData.daysPerWeek) {
      updateFields({ daysPerWeek: 2 });
    }
  }, []);

  const [currentIndex, setCurrentIndex] = useState(
    formData.daysPerWeek ? daysPerWeek.findIndex(days => days === formData.daysPerWeek) : 0
  );
  const [isAnimating, setIsAnimating] = useState(false);

  const handleNext = () => {
    if (isAnimating || currentIndex === daysPerWeek.length - 1) return;
    setIsAnimating(true);
    setCurrentIndex(currentIndex + 1);
    updateFields({ daysPerWeek: daysPerWeek[currentIndex + 1] });
    setTimeout(() => setIsAnimating(false), 300);
  };

  const handlePrev = () => {
    if (isAnimating || currentIndex === 0) return;
    setIsAnimating(true);
    setCurrentIndex(currentIndex - 1);
    updateFields({ daysPerWeek: daysPerWeek[currentIndex - 1] });
    setTimeout(() => setIsAnimating(false), 300);
  };

  const handleCardClick = (index) => {
    if (isAnimating || index === currentIndex) return;
    setIsAnimating(true);
    setCurrentIndex(index);
    updateFields({ daysPerWeek: daysPerWeek[index] });
    setTimeout(() => setIsAnimating(false), 300);
  };

  return (
    <div className="space-y-8 w-full">
      {/* Days per Week Section */}
      <div className="space-y-4">
        <div className="flex items-center gap-2">
          <Calendar className="text-blue-600" size={24} />
          <label className="block text-lg font-medium text-gray-700">
            Days per Week
          </label>
        </div>
        <p className="text-sm text-gray-500">
          How many days per week would you like to work out?
        </p>

        {/* Days Carousel */}
        <div className="relative h-32">
          {/* Edge Gradients */}
          <div className="absolute left-0 top-0 h-full w-32 bg-gradient-to-r from-white via-white to-transparent z-10"></div>
          <div className="absolute right-0 top-0 h-full w-32 bg-gradient-to-l from-white via-white to-transparent z-10"></div>

          {/* Navigation Buttons */}
          <button 
            onClick={handlePrev}
            disabled={currentIndex === 0}
            className={`absolute left-4 top-1/2 -translate-y-1/2 z-20 p-2 rounded-full bg-white shadow-md transition-all
              ${currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50 cursor-pointer'}`}
          >
            <Minus className="w-6 h-6 text-gray-600" />
          </button>

          <button 
            onClick={handleNext}
            disabled={currentIndex === daysPerWeek.length - 1}
            className={`absolute right-4 top-1/2 -translate-y-1/2 z-20 p-2 rounded-full bg-white shadow-md transition-all
              ${currentIndex === daysPerWeek.length - 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50 cursor-pointer'}`}
          >
            <Plus className="w-6 h-6 text-gray-600" />
          </button>

          {/* Carousel */}
          <div className="relative w-full h-full overflow-hidden px-16">
            <div className="w-full h-full flex items-center justify-center">
              {daysPerWeek.map((days, index) => {
                const position = index - currentIndex;
                return (
                  <div
                    key={days}
                    onClick={() => handleCardClick(index)}
                    className={`
                      absolute w-32 transition-all duration-300 ease-in-out
                      ${Math.abs(position) <= 1 ? 'cursor-pointer' : ''}
                    `}
                    style={{
                      transform: `translateX(${position * 90}%) scale(${
                        position === 0 ? 1 : 0.8
                      })`,
                      opacity: Math.abs(position) > 1 ? 0 : 1 - Math.abs(position) * 0.5,
                      zIndex: 10 - Math.abs(position),
                      pointerEvents: Math.abs(position) <= 1 ? 'auto' : 'none'
                    }}
                  >
                    <div
                      className={`
                        p-4 rounded-xl border-2 transition-all
                        ${position === 0
                          ? 'bg-blue-100 border-blue-500 shadow-lg'
                          : 'bg-gray-50 border-gray-200 hover:bg-gray-100'
                        }
                      `}
                    >
                      <div className="flex flex-col items-center">
                        <span className="text-3xl font-bold text-gray-700">{days}</span>
                        <span className="text-sm text-gray-500">days</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* Time per Session Section */}
      <div className="space-y-4">
        <div className="flex items-center gap-2">
          <Clock className="text-blue-600" size={24} />
          <label className="block text-lg font-medium text-gray-700">
            Time per Session
          </label>
        </div>
        <p className="text-sm text-gray-500">
          How much time can you dedicate to each workout session?
        </p>
        <div className="px-4 py-6">
          <TimeSlider 
            value={formData.timePerSession || 60}
            onChange={(value) => updateFields({ timePerSession: value })}
          />
        </div>
      </div>
    </div>
  );
};

export default StepFour;