const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_bAQ2VWDUK",
    "aws_user_pools_web_client_id": "1ktl88fh2jcn0m8infb6eilmh",
    "oauth": {
        "domain": "auth.pdftrainer.com",
        "scope": ["email", "openid", "profile"],
        "redirectSignIn": "http://localhost:3000/,https://d120ovw29hasnn.cloudfront.net/,https://pdftrainer.com/,https://www.pdftrainer.com/,https://auth.pdftrainer.com/oauth2/idpresponse/",
        "redirectSignOut": "http://localhost:3000/,https://d120ovw29hasnn.cloudfront.net/,https://pdftrainer.com/,https://www.pdftrainer.com/,https://auth.pdftrainer.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": ["EMAIL"],
    "aws_cognito_social_providers": ["GOOGLE"],
    "aws_cognito_signup_attributes": ["EMAIL"],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": ["SMS"],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": ["EMAIL"]
};

export default awsmobile;