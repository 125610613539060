import React, { useEffect, useRef } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useUserProfile } from '../contexts/UserProfileContext';

const SubscribedRoutes = () => {
  const { email } = useAuth();
  const { userProfile, isLoading, isInitialized } = useUserProfile();
  const location = useLocation();
  const path = location.pathname;
  const lastValidProfileRef = useRef(userProfile);

  // Update lastValidProfile when we have a valid profile
  useEffect(() => {
    if (userProfile) {
      lastValidProfileRef.current = userProfile;
    }
  }, [userProfile]);

  // Determine if we're in a workout generation flow
  const isWorkoutGenerationPath = path.includes('/workspace/assisted-workout');
  const isWorkoutGeneration = isWorkoutGenerationPath && lastValidProfileRef.current;



  // During workout generation, use a more permissive check
  if (isWorkoutGeneration) {
    if (!email) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return <Outlet />;
  }

  // Normal route protection logic
  if (isLoading || !isInitialized) {
    return null;
  }

  if (!email) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!userProfile) {
    return <Navigate to="/select-tier" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default SubscribedRoutes;