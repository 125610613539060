import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useUserProfile } from '../contexts/UserProfileContext';
import { useModal } from '../contexts/ModalContext';
import { Loader, Star, Calendar } from 'lucide-react';
import userHandler from '../services/handlers/userHandler';
import paymentHandler from '../services/handlers/paymentHandler';

const DeleteAccountModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg">
        <h2 className="text-xl font-bold mb-4">Confirm Account Deletion</h2>
        <p className="mb-4">Are you sure you want to delete your account? This action cannot be undone.</p>
        <div className="flex justify-end">
          <button onClick={onClose} className="btn-secondary mr-2">Cancel</button>
          <button onClick={onConfirm} className="btn-red">Delete Account</button>
        </div>
      </div>
    </div>
  );
};

const CancelSubscriptionModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg">
        <h2 className="text-xl font-bold mb-4">Confirm Subscription Cancellation</h2>
        <p className="mb-4">Are you sure you want to cancel your subscription? Your subscription will remain active until the end of your current billing period.</p>
        <div className="flex justify-end">
          <button onClick={onClose} className="btn-secondary mr-2">Keep Subscription</button>
          <button onClick={onConfirm} className="btn-red">Cancel Subscription</button>
        </div>
      </div>
    </div>
  );
};

const TierDisplay = ({ tier }) => {
  const capitalizedTier = tier ? tier.charAt(0).toUpperCase() + tier.slice(1) : 'Unknown';

  const renderTierIcon = () => {
    if (tier === 'pro') {
      return <Star size={24} fill="#FBBF24" stroke="#FBBF24" className="inline-block ml-2" />;
    } else if (tier === 'enterprise') {
      return <img src="/images/diamond.svg" alt="Enterprise" className="inline-block w-6 h-6 ml-2" />;
    }
    return null;
  };

  return (
    <span className="flex items-center">
      {capitalizedTier} plan
      {renderTierIcon()}
    </span>
  );
};

const Account = () => {
  const { signOut } = useAuth();
  const { userProfile, setUserProfile, isLoading } = useUserProfile();
  const { showModal } = useModal();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isUpdatingEmailPreferences, setIsUpdatingEmailPreferences] = useState(false);
  const [isCancellingDowngrade, setIsCancellingDowngrade] = useState(false);
  const [isCancellingSubscription, setIsCancellingSubscription] = useState(false);
  const navigate = useNavigate();

  const handleManageSubscription = useCallback(() => {
    navigate('/select-tier');
  }, [navigate]);

  const handleExportData = useCallback(async () => {
    if (!userProfile) return;
    setIsExporting(true);
    try {
      const result = { ...userProfile };
      delete result.password;
      
      const dataStr = JSON.stringify(result, null, 2);
      const dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
      
      const exportFileDefaultName = 'user_data.json';

      let linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
      
      showModal('Success', 'Your data has been exported successfully.', 'success');
    } catch (error) {
      console.error('Error exporting data:', error);
      showModal('Error', 'An error occurred while exporting your data. Please try again.', 'error');
    } finally {
      setIsExporting(false);
    }
  }, [userProfile, showModal]);

  const handleDeleteAccount = useCallback(() => {
    setIsDeleteModalOpen(true);
  }, []);

  const confirmDeleteAccount = useCallback(async () => {
    try {
      await userHandler.updateUser(userProfile.email, { deleted: true });
      await signOut();
      navigate('/');
      showModal('Account Deleted', 'Your account has been deleted successfully.', 'info');
    } catch (error) {
      console.error('Error deleting account:', error);
      showModal('Error', 'An error occurred while deleting your account. Please try again.', 'error');
    }
  }, [userProfile, signOut, navigate, showModal]);

  const handleEmailPreferenceChange = useCallback(async (preference) => {
    if (!userProfile?.email) return;
    setIsUpdatingEmailPreferences(true);
    
    try {
      const currentPreferences = userProfile.emailPreferences || {
        accountUpdates: false,
        promoEmail: false
      };
  
      const updatedPreferences = {
        ...currentPreferences,
        [preference]: !currentPreferences[preference]
      };
  
      const result = await userHandler.updateUser(
        userProfile.email, 
        { emailPreferences: updatedPreferences },
        true // suppress notification
      );
  
      if (result.success) {
        setUserProfile(result.user);
        showModal('Success', 'Email preferences updated', 'success');
      } else {
        throw new Error(result.error);
      }
    } catch (error) {
      console.error('Email preference update failed:', error);
      showModal('Error', 'Failed to update preferences', 'error');
    } finally {
      setIsUpdatingEmailPreferences(false);
    }
  }, [userProfile, setUserProfile, showModal]);

  const handleCancelDowngrade = useCallback(async () => {
    if (!userProfile?.email) return;
    setIsCancellingDowngrade(true);
    try {
      const result = await paymentHandler.cancelDowngrade(userProfile.email);
      if (result.success) {
        setUserProfile(prev => ({
          ...prev,
          scheduledTierDowngrade: null,
          tierChange: null
        }));
        showModal('Success', 'Your subscription cancellation has been reversed.', 'success');
      } else {
        throw new Error(result.error || 'Failed to cancel downgrade');
      }
    } catch (error) {
      console.error('Error cancelling downgrade:', error);
      showModal('Error', 'An error occurred while cancelling your scheduled downgrade. Please try again.', 'error');
    } finally {
      setIsCancellingDowngrade(false);
    }
  }, [userProfile, setUserProfile, showModal]);

  const handleCancelSubscription = useCallback(async () => {
    if (!userProfile?.email) return;
    setIsCancellingSubscription(true);
    try {
      const result = await paymentHandler.downgradeSubscription(userProfile.email, null);
      
      if (result) {
        setUserProfile(prev => ({
          ...prev,
          scheduledTierDowngrade: {
            tier: 'free',
            date: new Date(result.current_period_end * 1000).toISOString()
          },
          tierChange: 'downgrade'
        }));
        showModal('Success', 'Your subscription has been cancelled and will end at the end of your current billing period.', 'success');
      }
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      showModal('Error', 'Failed to cancel subscription. Please try again.', 'error');
    } finally {
      setIsCancellingSubscription(false);
      setIsCancelModalOpen(false);
    }
  }, [userProfile, setUserProfile, showModal]);

  if (isLoading || !userProfile) {
    return (
      <div className="min-h-full min-w-full flex items-center justify-center bg-light-secondary">
        <Loader className="animate-spin h-12 w-12 text-primary" />
      </div>
    );
  }

  const hasActiveSubscription = !!userProfile.nextBillingPeriodDate;
  const showCancelSubscription = (userProfile.tier === 'pro' || userProfile.tier === 'enterprise') 
                              && hasActiveSubscription 
                              && !userProfile.tierChange;
  
  return (
    <div className="min-h-full min-w-full flex flex-col justify-center py-12 bg-light-secondary">
      <div className="mx-auto w-full max-w-3xl px-4 sm:px-6 lg:px-8">
        <h1 className="text-dark font-bold mb-4" style={{ fontSize: 'var(--font-size-3xl)' }}>Account</h1>
        <div className="bg-light shadow-md rounded px-6 py-8">
          {/* Subscription Section */}
          <div className="mb-6">
            <h2 className="text-dark font-semibold mb-2" style={{ fontSize: 'var(--font-size-2xl)' }}>Current Plan</h2>
            <p className="text-dark" style={{ fontSize: 'var(--font-size-base)' }}>
              <TierDisplay tier={userProfile.tier} />
            </p>
            {userProfile.scheduledTierDowngrade?.date ? (
              <div className="mt-4 p-4">
                <p className="text-dark mb-2" style={{ fontSize: 'var(--font-size-base)' }}>
                  <Calendar className="inline-block mr-2" size={18} />
                  Your subscription will end on {new Date(userProfile.scheduledTierDowngrade.date).toLocaleDateString()}
                </p>
                <button
                  onClick={handleCancelDowngrade}
                  disabled={isCancellingDowngrade}
                  className="btn-secondary py-2 px-4 rounded"
                >
                  {isCancellingDowngrade ? 'Cancelling...' : 'Keep Subscription'}
                </button>
              </div>
            ) : userProfile.nextBillingPeriodDate && (
              <p className="text-dark mt-2" style={{ fontSize: 'var(--font-size-sm)' }}>
                Next billing date: {new Date(userProfile.nextBillingPeriodDate).toLocaleDateString()}
              </p>
            )}
          </div>
          
          {/* Subscription Management Section */}
          <div className="mb-6">
            <h2 className="text-dark font-semibold mb-2" style={{ fontSize: 'var(--font-size-2xl)' }}>Manage Your Subscription</h2>
            <div className="flex gap-2">
              <button
                onClick={handleManageSubscription}
                className="btn-primary py-2 px-4 rounded flex items-center"
                style={{ fontSize: 'var(--font-size-base)' }}
              >
                Plan | Buy Credits
              </button>
              {showCancelSubscription && hasActiveSubscription &&  (
                <button
                  onClick={() => setIsCancelModalOpen(true)}
                  disabled={isCancellingSubscription}
                  className="btn-secondary py-2 px-4 rounded flex items-center text-red-600"
                  style={{ fontSize: 'var(--font-size-base)' }}
                >
                  {isCancellingSubscription ? 'Cancelling...' : 'Cancel Subscription'}
                </button>
              )}
            </div>
          </div>

          {/* Email Preferences Section */}
          <div className="mb-6">
            <h2 className="text-dark font-semibold mb-2" style={{ fontSize: 'var(--font-size-2xl)' }}>Email Preferences</h2>
            <div>
              <button
                onClick={() => handleEmailPreferenceChange('promoEmail')}
                className={`py-2 px-4 rounded flex items-center ${userProfile.emailPreferences?.promoEmail ? 'btn-primary' : 'btn-secondary'}`}
                disabled={isUpdatingEmailPreferences}
                style={{ fontSize: 'var(--font-size-base)' }}
              >
                {userProfile.emailPreferences?.promoEmail ? 'Disable' : 'Enable'} Promotional Emails
              </button>
              <button
                onClick={() => handleEmailPreferenceChange('accountUpdates')}
                className={`py-2 px-4 rounded flex items-center mt-2 ${userProfile.emailPreferences?.accountUpdates ? 'btn-primary' : 'btn-secondary'}`}
                disabled={isUpdatingEmailPreferences}
                style={{ fontSize: 'var(--font-size-base)' }}
              >
                {userProfile.emailPreferences?.accountUpdates ? 'Disable' : 'Enable'} Account Update Emails
              </button>
            </div>
            {isUpdatingEmailPreferences && (
              <p className="text-dark mt-2" style={{ fontSize: 'var(--font-size-sm)' }}>
                <Loader className="animate-spin inline-block mr-2" size={16} />
                Updating preferences...
              </p>
            )}
          </div>

          {/* Data Management Section */}
          <div className="mb-6">
            <h2 className="text-dark font-semibold mb-2" style={{ fontSize: 'var(--font-size-2xl)' }}>Data Management</h2>
            <button
              onClick={handleExportData}
              className="btn-primary py-2 px-4 rounded flex items-center"
              disabled={isExporting}
              style={{ fontSize: 'var(--font-size-base)' }}
            >
              {isExporting ? (
                <>
                  <Loader className="animate-spin mr-2" size={16} />
                  Exporting Data...
                </>
              ) : 'Export Data'}
            </button>
          </div>

          {/* Account Deletion Section */}
          <div className="mb-6">
            <h2 className="text-dark font-semibold mb-2" style={{ fontSize: 'var(--font-size-2xl)' }}>Delete your account</h2>
            <button
              onClick={handleDeleteAccount}
              className="text-red-500 underline bg-transparent border-none cursor-pointer p-0"
              style={{ fontSize: 'var(--font-size-base)' }}
            >
              Delete Account
            </button>
          </div>
        </div>
      </div>
      <DeleteAccountModal 
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={confirmDeleteAccount}
      />
      <CancelSubscriptionModal
        isOpen={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
        onConfirm={handleCancelSubscription}
      />
    </div>
  );
};

export default Account;