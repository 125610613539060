import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import WorkoutList from '../components/WorkoutList';
import { ModalProvider } from '../contexts/ModalContext';

const Dashboard = () => {
  const { user } = useAuth();

  return (
    <ModalProvider>
      <div className="min-w-full min-h-full bg-white bg-cover">
        <header className="bg-white shadow">
          <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold text-gray-900">My Workouts</h1>
          </div>
        </header>
        <main>
          <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
            <div className="px-4 py-6 sm:px-0">
              <div className="bg-white shadow rounded-lg p-6">
                <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                  Welcome, {user ? user.email : 'Guest'}!
                </h2>
                <h3 className="text-xl font-semibold text-gray-900 mb-4">
                  Your Workout Plans
                </h3>
                <WorkoutList />
              </div>
            </div>
          </div>
        </main>
      </div>
    </ModalProvider>
  );
};

export default Dashboard;