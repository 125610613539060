import React, { useState, useEffect } from 'react';
import { Search, Plus } from 'lucide-react';
import debounce from 'lodash/debounce';
import { searchExercises } from '../../services/handlers/serviceHandler';

const SearchExercise = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [allResults, setAllResults] = useState([]);
  const [displayCount, setDisplayCount] = useState(15);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(true);


  const handleSearch = async (term) => {
    if (!term.trim()) {
      setSearchResults([]);
      setAllResults([]);
      setShowDropdown(false);
      return;
    }
    
    setIsLoading(true);
    try {
      const { exercises } = await searchExercises(term);
      setAllResults(exercises);
      setSearchResults(exercises.slice(0, displayCount));
      setShowDropdown(!!exercises.length);
    } catch (error) {
      console.error('Search error:', error);
      setSearchResults([]);
      setAllResults([]);
    } finally {
      setIsLoading(false);
    }
  };

  const loadMore = () => {
    const newCount = displayCount + 15;
    setDisplayCount(newCount);
    setSearchResults(allResults.slice(0, newCount));
  };

  const debouncedSearch = debounce(handleSearch, 300);

  useEffect(() => {
    if (searchTerm) {
      debouncedSearch(searchTerm);
    } else {
      setShowDropdown(false);
      setSearchResults([]);
      setAllResults([]);
      setDisplayCount(15);
    }
    return () => debouncedSearch.cancel();
  }, [searchTerm]);

  const handleSelect = (exercise) => {
    onSearch({
      bodyPart: { name: exercise.bodyPart },
      equipment: { name: exercise.equipment },
      exercise: {
        id: exercise.id,
        name: exercise.name,
        targetedMuscle: exercise.targetedMuscle,
        gifUrl: exercise.gifUrl,
        frameUrls: exercise.frameUrls
      }
    });
    setSearchTerm('');
    setShowDropdown(false);
    setDisplayCount(15);
  };

  const toggleDropdown = () => {
    if (!searchTerm.trim()) return;
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="relative w-full mb-4">
      <div className="relative">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClick={() => setDropdownOpen(true)}
          placeholder="Search by exercise name, muscle group, equipment..."
          className="w-full p-2 pr-10 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200"
        />
        {isLoading ? (
          <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
            <div className="animate-spin h-5 w-5 border-2 border-blue-500 border-t-transparent rounded-full"></div>
          </div>
        ) : (
          <button 
            onClick={toggleDropdown}
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
          >
            <Search size={20} />
          </button>
        )}
      </div>
      
      {showDropdown && dropdownOpen && searchResults.length > 0 && (
        <div className="absolute z-50 w-full mt-1 bg-white rounded-lg shadow-lg border border-gray-200 max-h-60 overflow-y-auto">
          {searchResults.map((exercise, index) => (
            <button
              key={index}
              onClick={() => handleSelect(exercise)}
              className="w-full text-left px-4 py-2 hover:bg-gray-100 flex items-center border-b last:border-b-0"
            >
              <div className="flex-grow">
                <span className="font-medium block">{exercise.name}</span>
                <span className="text-sm text-gray-600 block">
                  {exercise.bodyPart} | {exercise.equipment} | {exercise.targetedMuscle}
                </span>
              </div>
              {exercise.gifUrl && (
                <div className="flex-shrink-0 ml-4">
                  <img 
                    src={exercise.gifUrl} 
                    alt={exercise.name} 
                    className="h-12 w-12 object-cover rounded"
                  />
                </div>
              )}
            </button>
          ))}
          {allResults.length > displayCount && (
            <button
              onClick={(e) => {
                e.preventDefault();
                loadMore();
              }}
              className="w-full text-center py-2 text-blue-500 hover:bg-gray-50 flex items-center justify-center gap-2"
            >
              <Plus size={16} />
              Load More
            </button>
          )}
        </div>
      )}
    </div>
  );
};
export default SearchExercise;