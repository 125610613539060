import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Question = ({ question, answer, index }) => {
  const [isOpen, setIsOpen] = useState(false);
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, delay: index * 0.1 }
    }
  };

  return (
    <motion.div 
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={variants}
      className="border-b border-gray-200 py-4"
    >
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-base md:text-lg font-medium text-gray-100 hover:underline">{question}</h3>
        {isOpen ? <ChevronUp className="h-5 w-5 text-gray-100" /> : <ChevronDown className="h-5 w-5 text-gray-500" />}
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <p className="mt-2 text-base text-gray-200">{answer}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const FAQ = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, when: "beforeChildren", staggerChildren: 0.1 }
    }
  };

  const titleVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
  };

  const faqItems = [
    {
      question: "What is PDFTrainer?",
      answer: "PDFTrainer is a workout builder and AI-powered personal trainer that generates personalized PDF workout plans."
    },
    {
      question: "Can I download and keep my workout plans?",
      answer: "Yes, once generated, the workout plan is yours to download and keep forever."
    },
    {
      question: "How does the AI workout trainer work?",
      answer: "The AI trainer gathers your preferences and goals, and generates a tailored workout plan based on fitness expert knowledge."
    },
    {
      question: "Can PDFTrainer accommodate various workout environments and equipment availability?",
      answer: "Absolutely. PDFTrainer is designed to create comprehensive workout plans that cater to a wide range of settings and equipment availability. Our system can generate plans ranging from no-equipment home workouts to routines utilizing free weights, calisthenics, gym machines, kettlebells, and more. Whatever your workout environment or equipment access, PDFTrainer will tailor a plan to suit your specific circumstances and fitness goals."
    },
    {
      question: "How flexible are the workout plans generated by PDFTrainer, and can they be customized?",
      answer: "PDFTrainer offers a high degree of flexibility in its workout plans. While we provide comprehensive suggestions based on your input. Once generated, you have full control to modify the plan according to your preferences."
    }
    // {
    //   question: "How does PDFTrainer ensure continued progress and prevent workout plateaus?",
    //   answer: "PDFTrainer employs advanced algorithms to continuously challenge your body and promote ongoing progress. Our system takes into account your previous workout plans and performance data to intelligently adjust future plans, effectively preventing plateau effects. By leveraging expert fitness documentation and principles of progressive overload, PDFTrainer creates both short-term and long-term strategies to keep your workouts challenging and effective. This approach ensures that your fitness regimen evolves with you, consistently pushing your boundaries and facilitating continuous improvement."
    // }
  ];

  return (
    <section id="faq" className="pt-8 pb-16 px-8 bg-black relative text-white">
      <motion.div 
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={containerVariants}
        className="max-w-3xl mx-auto"
      >
        <motion.h2 
          variants={titleVariants}
          className="text-2xl md:text-3xl font-fira mb-8 text-center"
        >
          Frequently Asked Questions
        </motion.h2>
        <div className="space-y-2">
          {faqItems.map((item, index) => (
            <Question
              key={index}
              question={item.question}
              answer={item.answer}
              index={index}
            />
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default FAQ;