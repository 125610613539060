import React, { useState, useEffect } from 'react';
import { useUserProfile } from '../contexts/UserProfileContext';
import { useAuth } from '../contexts/AuthContext';
import { useModal } from '../contexts/ModalContext';
import { Loader } from 'lucide-react';
import userHandler from '../services/handlers/userHandler';

const Profile = () => {
  const { userProfile, setUserProfile } = useUserProfile();
  const { email } = useAuth();
  const { showModal } = useModal();
  const [name, setName] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (userProfile && userProfile.name) {
      setName(userProfile.name);
    }
  }, [userProfile]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name === userProfile?.name) return;

    setIsSaving(true);

    try {
      const result = await userHandler.updateUser(email, { name });
      if (result.success) {
        setUserProfile({ ...userProfile, name });
        setName(name);  // Update local state with the new name
        if (result.message) {
          showModal('Profile Updated', result.message, result.type);
        }
      } else {
        showModal('Update Failed', result.error, result.type);
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      showModal('Error', 'An unexpected error occurred. Please try again.', 'error');
    } finally {
      setIsSaving(false);
    }
  };

  const isModified = name !== userProfile?.name;

  return (
    <div className="min-h-full min-w-full flex flex-col justify-center py-12 bg-light-secondary">
      <div className="mx-auto w-full max-w-2xl px-4 sm:px-6 lg:px-8">
        <h1 className="text-dark font-bold mb-6" style={{ fontSize: 'var(--font-size-3xl)' }}>Profile</h1>
        <form onSubmit={handleSubmit} className="bg-light shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <label className="block text-dark font-bold mb-2" htmlFor="name" style={{ fontSize: 'var(--font-size-sm)' }}>
              Name
            </label>
            <input
              className="input-primary w-full"
              id="name"
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{ fontSize: 'var(--font-size-base)' }}
            />
          </div>
          <div className="mb-6">
            <label className="block text-dark font-bold mb-2" htmlFor="email" style={{ fontSize: 'var(--font-size-sm)' }}>
              Email
            </label>
            <input
              className="input-primary w-full"
              id="email"
              type="email"
              value={email || ''}
              readOnly
              style={{ fontSize: 'var(--font-size-base)' }}
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className={`py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center ${
                isModified && !isSaving ? 'btn-primary' : 'btn-disabled bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
              type="submit"
              disabled={!isModified || isSaving}
              style={{ fontSize: 'var(--font-size-base)' }}
            >
              {isSaving ? (
                <>
                  <Loader className="animate-spin mr-2" size={16} />
                  Saving...
                </>
              ) : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;