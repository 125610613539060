import React, { createContext, useState, useContext, useEffect } from 'react';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

const themes = [
  { name: 'light', emoji: '☀️' },
  { name: 'dark', emoji: '🌚' },
  { name: 'peach', emoji: '🍑' },
  { name: 'strawberry', emoji: '🍓' },
  { name: '8ball', emoji: '🎱' },
  { name: 'sky', emoji: '☁️' },
  { name: 'sunset', emoji: '🌅' },
  { name: 'camo', emoji: '🪖' },
  { name: 'camowhite', emoji: '❄️' },
  { name: 'camopink', emoji: '💗' },
  { name: 'usa', emoji: '🇺🇸' },
  
];

export const ThemeProvider = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState('light');

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme && themes.some(theme => theme.name === savedTheme)) {
      setCurrentTheme(savedTheme);
    }
  }, []);

  const changeTheme = (themeName) => {
    setCurrentTheme(themeName);
    localStorage.setItem('theme', themeName);
  };

  useEffect(() => {
    document.body.className = `theme-${currentTheme}`;
  }, [currentTheme]);

  return (
    <ThemeContext.Provider value={{ currentTheme, changeTheme, themes }}>
      {children}
    </ThemeContext.Provider>
  );
};