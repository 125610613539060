import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUserProfile } from '../contexts/UserProfileContext';
import paymentHandler from '../services/handlers/paymentHandler';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { updateUserProfile } = useUserProfile();
  const [status, setStatus] = useState('processing');
  const [error, setError] = useState(null);


  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const verifySession = async () => {
      const searchParams = new URLSearchParams(location.search);
      const sessionId = searchParams.get('session_id');
      const emailFromUrl = searchParams.get('email');

      if (!sessionId || !emailFromUrl) {
        setError('Invalid session or user information');
        setStatus('error');
        return;
      }

      try {
        const { tierId } = await paymentHandler.verifySession(sessionId, emailFromUrl);
        await updateUserProfile({ tier: tierId }, emailFromUrl);
        setStatus('success');

        // Set a timeout to navigate after 2 seconds
        setTimeout(() => {
          navigate('/account');
        }, 2000);

      } catch (error) {
        console.error('Error verifying payment:', error);
        setError('There was an error verifying your payment. Please contact support.');
        setStatus('error');
      }
    };

    verifySession();
  }, []);

  if (error) {
    return (
      <div className="min-h-full min-w-full flex items-center justify-center bg-light-secondary text-dark py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 ">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-dark">
              Payment Verification Error
            </h2>
            <p className="mt-2 text-center text-sm text-dark">{error}</p>
            <button
              onClick={() => navigate('/select-tier')}
              className="btn-primary mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Return to Tier Selection
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-full min-w-full flex items-center justify-center bg-light-secondary text-dark py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-dark">
            {status === 'processing' ? 'Processing your payment' : 'Payment Successful'}
          </h2>
          <p className="mt-2 text-center text-sm text-dark">
            {status === 'processing' 
              ? 'Please wait while we confirm your payment and update your account.' 
              : 'Your account has been updated. Redirecting to dashboard...'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;