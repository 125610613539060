import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getWorkouts, deleteWorkout, updateWorkout } from '../services/handlers/serviceHandler';
import { useAuth } from '../contexts/AuthContext';
import { useModal } from '../contexts/ModalContext';
import { Pencil, X, Check, Trash2, Loader, Upload } from 'lucide-react';

const WorkoutList = () => {
  const [workouts, setWorkouts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [editName, setEditName] = useState('');
  const [actionInProgress, setActionInProgress] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const { showModal } = useModal();

  useEffect(() => {
    fetchWorkouts();
  }, []);

  const fetchWorkouts = async () => {
    try {
      setLoading(true);
      const fetchedWorkouts = await getWorkouts(user.email);
      const sortedWorkouts = fetchedWorkouts.sort((a, b) => 
        new Date(b.saveTime) - new Date(a.saveTime)
      );
      setWorkouts(sortedWorkouts);
    } catch (err) {
      setError('Failed to fetch workouts');
      console.error(err);
      showModal('Error', 'Failed to fetch workouts. Please try again.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleEditStart = (workout) => {
    if (actionInProgress) return;
    setEditingId(workout.workoutId);
    setEditName(workout.name);
  };

  const handleEditCancel = () => {
    setEditingId(null);
    setEditName('');
  };

  const handleEditSave = async (workout) => {
    if (editName === workout.name) {
      handleEditCancel();
      return;
    }

    setActionInProgress(true);
    try {
      const updatedWorkout = {
        ...workout,
        name: editName
      };

      await updateWorkout(user.email, workout.workoutId, updatedWorkout);
      
      setWorkouts(prevWorkouts => {
        const newWorkouts = prevWorkouts.map(w => 
          w.workoutId === workout.workoutId 
            ? { ...w, name: editName, saveTime: new Date().toISOString() }
            : w
        );
        return newWorkouts.sort((a, b) => 
          new Date(b.saveTime) - new Date(a.saveTime)
        );
      });
      
      setEditingId(null);
      showModal('Success', 'Workout name updated successfully.', 'success');
    } catch (err) {
      console.error('err:', err);
      showModal('Error', 'Failed to update workout name. Please try again.', 'error');
    } finally {
      setActionInProgress(false);
    }
  };

  const handleDelete = async (workoutId) => {
    setActionInProgress(true);
    try {
      await deleteWorkout(user.email, workoutId);
      setWorkouts(prevWorkouts => 
        prevWorkouts.filter(workout => workout.workoutId !== workoutId)
      );
      showModal('Success', 'Workout deleted successfully.', 'success');
    } catch (err) {
      console.error(err);
      showModal('Error', 'Failed to delete workout. Please try again.', 'error');
    } finally {
      setActionInProgress(false);
    }
  };

  const handleLoad = (workout) => {
    if (actionInProgress) return;
    setActionInProgress(true);
    navigate('/workspace/workout-builder', { state: { loadedWorkout: workout } });
    showModal('Success', 'Workout loaded successfully. Redirecting to builder...', 'success');
  };

  if (loading) return <div className="text-center"><Loader className="animate-spin" size={24} /> Loading workouts...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="space-y-4">
      {workouts.map(workout => (
        <div key={workout.workoutId} className="bg-white shadow rounded-lg p-4">
          {editingId === workout.workoutId ? (
            <div className="flex items-center">
              <input
                type="text"
                value={editName}
                onChange={(e) => setEditName(e.target.value)}
                className="flex-grow mr-2 px-2 py-1 border rounded"
                disabled={actionInProgress}
              />
              <button 
                onClick={() => handleEditSave(workout)}
                disabled={actionInProgress}
                className="p-1 bg-green-500 text-white rounded hover:bg-green-600 mr-1 disabled:opacity-50"
              >
                {actionInProgress ? <Loader className="animate-spin" size={16} /> : <Check size={16} />}
              </button>
              <button 
                onClick={handleEditCancel}
                disabled={actionInProgress}
                className="p-1 bg-red-500 text-white rounded hover:bg-red-600 disabled:opacity-50"
              >
                <X size={16} />
              </button>
            </div>
          ) : (
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-semibold">{workout.name}</h3>
              <button 
                onClick={() => handleEditStart(workout)}
                disabled={actionInProgress}
                className="p-1 text-blue-500 hover:text-blue-600 disabled:opacity-50"
              >
                <Pencil size={16} />
              </button>
            </div>
          )}
          <p className="text-sm text-gray-600">Created: {new Date(workout.saveTime).toLocaleDateString()}</p>
          <p className="text-sm text-gray-600">Exercises: {workout.summary.totalExercises}</p>
          <div className="mt-2 space-x-2">
            <button 
              onClick={() => handleDelete(workout.workoutId)}
              disabled={actionInProgress}
              className="p-2 bg-red-500 text-white rounded hover:bg-red-600 disabled:opacity-50"
            >
              {actionInProgress && editingId === workout.workoutId ? <Loader className="animate-spin" size={16} /> : <Trash2 size={16} />}
            </button>
            <button 
              onClick={() => handleLoad(workout)}
              disabled={actionInProgress}
              className="p-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50"
            >
              <Upload size={16} />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default WorkoutList;