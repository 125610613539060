import React from 'react';
import { Sparkles, RotateCcw, CircleDollarSign } from 'lucide-react';
import { motion } from 'framer-motion';
import { useWorkout } from '../WorkoutContext';
import { useUserProfile } from '../../../contexts/UserProfileContext';
import { useModal } from '../../../contexts/ModalContext';
import { generateFinalWorkout } from '../../../services/handlers/serviceHandler';


const LoadingState = () => (
  <div className="flex flex-col items-center justify-center min-h-[420px]">
    <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
    <p className="mt-4 text-gray-600">Generating your perfect workout plan...</p>
  </div>
);


const DayItem = ({ day, index }) => (
  <div className="flex items-start gap-4 py-3 border-b last:border-b-0">
    <div className="flex-shrink-0 flex flex-col items-center">
      <span className="text-xs text-gray-500 mb-1">DAY</span>
      <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center">
        <span className="text-blue-600 font-bold text-xl">{index + 1}</span>
      </div>
    </div>
    <div className="flex-1 pt-6">
      <h3 className="font-medium text-gray-900">{day.focus}</h3>
    </div>
  </div>
);


const StepSeven = ({ onError, formData, onGenerateFinal, onBack, updateFields }) => {
  const { 
    workoutStrategy, 
    setShowFeedbackHints,
    setPreviousStrategy,
    setWorkoutFinal,
    setHasSaved,
  } = useWorkout();

  const { userProfile, refreshUserProfile } = useUserProfile();
  const { showModal } = useModal();

  if (!workoutStrategy) {
    return <LoadingState />;
  
  }


  console.log('Days:', workoutStrategy.workoutPlan?.days);


  const {
    workoutPlan: {
      days = {},
      workoutPlan: {
        daysPerWeek = 3,
        overallStrategy = '',
        programDuration = '',
        timePerSession = ''
      } = {}
    } = {}
  } = workoutStrategy || {};

  const formattedDays = Object.entries(days)
    .filter(([key]) => key.startsWith('day'))
    .sort(([a], [b]) => {
      const numA = parseInt(a.replace('day', ''));
      const numB = parseInt(b.replace('day', ''));
      return numA - numB;
    })
    .map(([key, day]) => ({
      name: `Day ${key.replace('day', '')}`,
      focus: day.strategy || 'Rest Day',
      muscleGroup: day.muscleGroup
    }));

    const handleReviewStrategy = () => {
      if (workoutStrategy) {
        setPreviousStrategy(workoutStrategy);
        // Update both fields in a single call to ensure atomic update
        updateFields({
          originalAdditionalNotes: formData.additionalNotes,
          additionalNotes: ''
        });
        setShowFeedbackHints(true);
      }
      onBack();
    };

  const handleGenerateFinalWorkout = async () => {
    if (!userProfile?.credits || userProfile.credits < 1) {
      showModal('Error', 'You need at least 1 credit to generate a workout', 'error');
      return;
    }
  
    setWorkoutFinal(null);
    setHasSaved(false);
    onGenerateFinal();
    
    try {
      const workoutFinalPlan = await generateFinalWorkout(workoutStrategy, {
        ...formData,
        email: userProfile.email
      });
      
      await refreshUserProfile();
      
      setWorkoutFinal({
        ...workoutFinalPlan,
        workoutPlan: {
          ...workoutFinalPlan.workoutPlan,
          notes: workoutStrategy.notes || {}
        }
      });
    } catch (err) {
      console.error('Workout generation error:', err);
      if (err.message === 'Insufficient credits') {
        showModal('Error', 'You need credits to generate workouts', 'error');
      } else {
        showModal('Error', 'Failed to generate workout', 'error');
      }
      onBack();
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="space-y-8 w-full max-w-2xl mx-auto p-3"
    >
      {/* Header */}
      <div className="text-center space-y-2">
        <h2 className="text-2xl font-bold text-gray-900">This is the strategy we put together</h2>
        <p className="text-gray-500">Please confirm strategy below or provide feedback to improve it</p>
      </div>

      {/* Stats Display */}
      <div className="grid grid-cols-2 gap-8 my-8">
        <div className="text-center">
          <div className="text-6xl font-bold text-blue-600">{daysPerWeek}</div>
          <div className="text-sm text-gray-600 mt-2">days per week</div>
        </div>
        <div className="text-center">
          <div className="text-6xl font-bold text-blue-600">{timePerSession}</div>
          <div className="text-sm text-gray-600 mt-2">minutes per session</div>
        </div>
      </div>

      {/* Duration */}
      <div className="text-center">
        <span className="text-gray-500">Plan Duration:</span>
        <span className="ml-2 font-semibold">{programDuration}</span>
      </div>

      {/* Strategy */}
      <div className="space-y-2">
        <h3 className="text-xl font-semibold text-gray-900 p-3">Strategy</h3>
        <p className="text-gray-600">{overallStrategy}</p>
      </div>

      {/* Weekly Schedule - Updated */}
      <div className="space-y-4">
        <div className="flex items-center">
          <h3 className="text-xl font-semibold text-gray-900">Weekly Schedule</h3>

        </div>

        <div className="bg-gray-50 rounded-lg px-4">

          {formattedDays.map((day, index) => (
            <DayItem key={index} day={day} index={index} />
          ))}
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex flex-col gap-4">
        <button
          onClick={handleGenerateFinalWorkout}
          className="w-full py-4 bg-blue-600 text-white rounded-xl font-semibold 
            transition-all duration-200 flex items-center justify-center gap-2
            hover:bg-blue-700 hover:shadow-lg transform hover:-translate-y-0.5"
        >
          <Sparkles className="w-5 h-5" />
          Generate Final Workout
          <div className="flex items-center gap-1 ml-2 bg-blue-500 px-2 py-1 rounded-lg">
            <CircleDollarSign className="w-4 h-4" />
            <span className="text-sm">1</span>
          </div>
        </button>

        <button
          onClick={handleReviewStrategy}
          className="w-full py-3 border-2 border-gray-200 rounded-xl font-medium
            transition-all duration-200 flex items-center justify-center gap-2
            text-gray-600 hover:bg-gray-50"
        >
          <RotateCcw className="w-5 h-5" />
          Review Strategy
        </button>
      </div>
    </motion.div>
  );
};

export default StepSeven;