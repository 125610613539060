// src/components/Workspace/Builder/StepEight.js

import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { CheckCircle, Dumbbell, Loader2 } from 'lucide-react';
import { useWorkout } from '../WorkoutContext';
import { useModal } from '../../../contexts/ModalContext';
import { useUserProfile } from '../../../contexts/UserProfileContext';
import { saveWorkout } from '../../../services/handlers/serviceHandler';

const LoadingState = () => (
  <div className="flex flex-col items-center justify-center h-full min-h-[420px]">
    <Loader2 className="w-16 h-16 text-blue-500 animate-spin" />
    <p className="mt-4 text-gray-600">Finalizing your workout plan...</p>
  </div>
);

const StepEight = () => {
  const navigate = useNavigate();
  const { workoutFinal, workoutStrategy, hasSaved, setHasSaved } = useWorkout();
  const { showModal } = useModal();
  const { userProfile } = useUserProfile();
  const saveAttempted = useRef(false);

  useEffect(() => {
    const handleSaveWorkout = async () => {
      // Prevent multiple save attempts
      if (saveAttempted.current || !workoutFinal || !workoutStrategy || !userProfile?.email || hasSaved) {
        return;
      }

      saveAttempted.current = true;
    
      try {
        const mergedWorkoutData = {
          ...workoutFinal,
          notes: {
            food: workoutStrategy?.notes?.food || '',
            weights: workoutStrategy?.notes?.weights || '',
            repsAndSets: workoutStrategy?.notes?.repsAndSets || ''
          },
          workoutPlan: {
            ...workoutFinal.workoutPlan,
            notes: {
              food: workoutStrategy?.notes?.food || '',
              weights: workoutStrategy?.notes?.weights || '',
              repsAndSets: workoutStrategy?.notes?.repsAndSets || ''
            }
          }
        };
    
    
        await saveWorkout(
          userProfile.email,
          mergedWorkoutData,
          workoutStrategy
        );
    
        setHasSaved(true);
        showModal('Success', 'Your workout plan has been automatically saved!', 'success');
      } catch (error) {
        console.error('Save error:', error);
        saveAttempted.current = false; // Reset on error to allow retry
        showModal('Warning', 'Unable to auto-save your workout plan.', 'warning');
      }
    };

    handleSaveWorkout();
  }, [workoutFinal, workoutStrategy, userProfile?.email, hasSaved, setHasSaved, showModal]);

  // Reset saveAttempted when component unmounts
  useEffect(() => {
    return () => {
      saveAttempted.current = false;
    };
  }, []);

  const handleNavigateToWorkout = () => {
    navigate('/workspace/workout-builder', {
      state: { 
        loadedWorkout: workoutFinal,
        initialTab: 'summary' // Add this to state
      }
    });
  }

  if (!workoutFinal) {
    return <LoadingState />;
  }
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col items-center justify-center h-full text-center space-y-8"
    >
      <div className="flex items-center justify-center w-20 h-20 rounded-full bg-green-100">
        <CheckCircle className="w-12 h-12 text-green-500" />
      </div>

      <div className="space-y-2">
        <h2 className="text-2xl font-bold text-gray-900">Your Workout Plan is Ready!</h2>
        <p className="text-gray-600">
          We've created your personalized workout plan and saved it to your dashboard.
        </p>
      </div>

      <button
        onClick={handleNavigateToWorkout}
        className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-600 text-white rounded-xl
          font-semibold transition-all duration-200 hover:bg-blue-700 hover:shadow-lg transform 
          hover:-translate-y-0.5"
      >
        <Dumbbell className="w-5 h-5" />
        View Your Workout Plan
      </button>

      <div className="text-sm text-gray-500">
        <p>You can always access this plan from your dashboard later.</p>
      </div>
    </motion.div>
  );
};

export default StepEight;