import React, { useEffect } from 'react';  
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { UserProfileProvider } from './contexts/UserProfileContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { ModalProvider } from './contexts/ModalContext';
import MainLayout from './components/MainLayout';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import Account from './pages/Account';
import ServiceTierSelection from './pages/ServiceTierSelection';
import Workspace from './pages/Workspace';
import AssistedWorkout from './pages/workspace/AssistedWorkout';
import WorkoutBuilder from './pages/workspace/WorkoutBuilder';
import ConfirmSignup from './pages/ConfirmSignup';
import PaymentSuccess from './pages/PaymentSuccess';
import Unsubscribe from './pages/Unsubscribe';
import PrivateRoutes from './utils/PrivateRoutes';
import SubscribedRoutes from './utils/SubscribedRoutes';
import ChatBox from './components/ChatBox';
import { WorkoutProvider } from './pages/workspace/WorkoutContext';
import Landing from './pages/Landing'
import NotFoundPage from './pages/NotFoundPage'
import config from './aws-exports';
import './styles/customProperties.css';
import { Amplify } from 'aws-amplify';
import  DelayedModal  from './components/DelayedModal';

Amplify.configure(config);





const MainLayoutWithChat = ({ children }) => (
  <MainLayout>
    {children}
    <DelayedModal />
  </MainLayout>
);

function App() {

  useEffect(() => {
    if (window.location.hostname.startsWith('www.')) {
      const newUrl = window.location.href.replace('www.', '');
      window.location.replace(newUrl);
    }
  }, []);

  return (
    <Router>
      <AuthProvider>
        <UserProfileProvider>
          <ThemeProvider>
            <ModalProvider>
              <WorkoutProvider>
                <MainLayoutWithChat>
                  <Routes>
                    <Route path="/unsubscribe" element={<Unsubscribe />} />
                    <Route path="/" element={<Landing />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/confirm-signup" element={<ConfirmSignup />} />
                    <Route element={<PrivateRoutes />}>
                      <Route path="/select-tier" element={<ServiceTierSelection />} />
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/account" element={<Account />} />
                      <Route path="/payment-success" element={<PaymentSuccess />} />
                      
                      {/* Workspace routes protected by subscription */}
                      <Route element={<SubscribedRoutes />}>
                        <Route path="/workspace" element={<Workspace />} />
                        <Route path="/workspace/assisted-workout" element={<AssistedWorkout />} />
                        <Route path="/workspace/workout-builder" element={<WorkoutBuilder />} />
                      </Route>
                    </Route>
                    <Route path="*" element={<NotFoundPage />} />
                  </Routes>
                </MainLayoutWithChat>
              </WorkoutProvider>
            </ModalProvider>
          </ThemeProvider>
        </UserProfileProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;