import React from 'react';
import { Save, FileDown, Mail, Loader2 } from 'lucide-react';

const LoadingOverlay = () => (
  <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
    <div className="bg-blue-900 p-8 rounded-lg flex flex-col items-center">
      <Loader2 className="h-12 w-12 animate-spin text-white mb-4" />
      <p className="text-white font-medium">Processing...</p>
    </div>
  </div>
);

const ActionButton = ({ icon: Icon, label, onClick, loading = false, className = '' }) => (
  <div className="relative group">
    <button
      onClick={onClick}
      disabled={loading}
      className={`w-8 h-8 lg:w-10 lg:h-10 rounded-full flex items-center justify-center transition-all duration-200 hover:scale-110 disabled:opacity-50 disabled:hover:scale-100 ${className}`}
    >
      {loading ? (
        <Loader2 size={16} className="animate-spin lg:w-5 lg:h-5" />
      ) : (
        <Icon size={16} className="lg:w-5 lg:h-5" />
      )}
    </button>
    <div className="absolute hidden lg:group-hover:flex -top-8 left-1/2 -translate-x-1/2 bg-gray-900 text-white text-xs py-1 px-2 rounded whitespace-nowrap">
      {loading ? 'Processing...' : label}
    </div>
  </div>
);

const WorkoutPlanActions = ({ onSave, onExportPDF, onEmailWorkout, isSaving = false, isProcessing = false }) => {
  return (
    <>
      {isProcessing && <LoadingOverlay />}
      <div className="relative flex items-center gap-2 lg:gap-3 z-10 opacity-60 hover:opacity-100">
        {/* Desktop position */}
        <div className="hidden lg:flex fixed right-8 top-24 items-center gap-3">
          <ActionButton
            icon={Save}
            label="Save Workout"
            onClick={onSave}
            loading={isSaving}
            className="bg-blue-600 text-white hover:bg-blue-700"
          />
          <ActionButton
            icon={FileDown}
            label="Export PDF"
            onClick={onExportPDF}
            loading={isProcessing}
            className="bg-purple-600 text-white hover:bg-purple-700"
          />
          <ActionButton
            icon={Mail}
            label="Email Workout"
            onClick={onEmailWorkout}
            loading={isProcessing}
            className="bg-green-600 text-white hover:bg-green-700"
          />
        </div>

        {/* Mobile position */}
        <div className="lg:hidden fixed top-20 right-6 flex items-center gap-2 bg-gray-900 bg-opacity-75 p-2 rounded-full">
          <ActionButton
            icon={Save}
            label="Save"
            onClick={onSave}
            loading={isSaving}
            className="bg-blue-600 text-white hover:bg-blue-700"
          />
          <ActionButton
            icon={FileDown}
            label="Export"
            onClick={onExportPDF}
            loading={isProcessing}
            className="bg-purple-600 text-white hover:bg-purple-700"
          />
          <ActionButton
            icon={Mail}
            label="Email"
            onClick={onEmailWorkout}
            loading={isProcessing}
            className="bg-green-600 text-white hover:bg-green-700"
          />
        </div>
      </div>
    </>
  );
};

export default WorkoutPlanActions;