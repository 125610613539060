
/**
 * Formats workout data into a consistent structure for saving
 * @param {Object} workoutData - Raw workout data
 * @param {Object} workoutStrategy - Workout strategy data
 * @param {Object} options - Additional options
 * @returns {Object} Formatted workout data
 */
const formatWorkoutForSave = (workoutData, workoutStrategy, options = {}) => {
  const finalWorkoutPlan = workoutData?.workoutPlan?.workoutPlan || workoutData?.workoutPlan || {};
  const workoutName = finalWorkoutPlan?.name || 
                     workoutStrategy?.workoutPlan?.name || 
                     options.defaultName || 
                     "My Workout Plan";
                     
  const overallStrategy = finalWorkoutPlan?.overallStrategy || 
                         workoutStrategy?.workoutPlan?.overallStrategy ||
                         options.defaultStrategy ||
                         "A focused approach targeting strength and muscle growth through progressive overload.";

  const notes = {
    food: workoutData?.notes?.food || 
          workoutStrategy?.notes?.food || 
          finalWorkoutPlan?.notes?.food || 
          '',
    weights: workoutData?.notes?.weights || 
             workoutStrategy?.notes?.weights || 
             finalWorkoutPlan?.notes?.weights || 
             '',
    repsAndSets: workoutData?.notes?.repsAndSets || 
             workoutStrategy?.notes?.repsAndSets || 
             finalWorkoutPlan?.notes?.repsAndSets || 
             ''

  };
  
    // Calculate workout summary
    const summary = {
      totalDays: workoutData.content?.length || 0,
      totalExercises: workoutData.content?.reduce((total, day) => total + day.length, 0) || 0,
      totalSets: workoutData.content?.reduce((total, day) => 
        total + day.reduce((dayTotal, exercise) => 
          dayTotal + (parseInt(exercise.parameters?.sets) || 0), 0
        ), 0
      ) || 0,
      muscles: [...new Set(workoutData.content?.flatMap(day => 
        day.map(exercise => exercise.muscleGroup || exercise.bodyPart)
      ).filter(Boolean))]
    };

    const formattedContent = workoutData.content?.map(day => day.map(exercise => ({
      id: exercise.exerciseIdFromApi || exercise.id,
      parameters: exercise.parameters || {}
    }))) || [];

    return {
      name: workoutName,
      content: formattedContent,
      summary,
      planStrategy: overallStrategy,
      notes,
      workoutPlan: {
        name: workoutName,
        overallStrategy: overallStrategy,
        programDuration: finalWorkoutPlan?.programDuration || options.programDuration || "4-6 weeks",
        daysPerWeek: workoutData.content?.length || 0,
        timePerSession: finalWorkoutPlan?.timePerSession || options.timePerSession || 60,
        days: finalWorkoutPlan?.days || workoutData?.workoutPlan?.days || {},
        notes
      },
      timePerSession: finalWorkoutPlan?.timePerSession || options.timePerSession || 60,
      trainingTips: workoutData.trainingTips || {
        form: "Focus on proper form and controlled movements for each exercise.",
        setsReps: "Progressive overload by increasing weight or reps when possible.",
        safety: "Always warm up properly and maintain proper form throughout."
      }
    };
};

/**
 * Check if a workout with the given name exists
 * @param {Array} workouts - List of existing workouts
 * @param {string} workoutName - Name to check
 * @returns {Object|null} Existing workout or null
 */
const findExistingWorkout = (workouts, workoutName) => {
  return workouts.find(workout => workout.name === workoutName);
};

/**
 * Generate next available version name
 * @param {string} baseName - Original workout name
 * @param {Array} workouts - List of existing workouts
 * @returns {string} New unique name
 */
const getNextVersionName = (baseName, workouts) => {
  let counter = 2;
  let newName = `${baseName} (${counter})`;
  
  while (workouts.some(workout => workout.name === newName)) {
    counter++;
    newName = `${baseName} (${counter})`;
  }
  
  return newName;
};

/**
 * Prepares and validates workout data for saving
 * @param {string} email - User email
 * @param {Object} workoutData - Raw workout data
 * @param {Object} workoutStrategy - Workout strategy data
 * @param {Object} options - Additional options
 * @returns {Object} Prepared workout data ready for API
 */
export const prepareWorkoutForSave = (email, workoutData, workoutStrategy = null, options = {}) => {
  // Validate inputs
  if (!email) {
    throw new Error('User email is required');
  }

  if (!workoutData?.content || !Array.isArray(workoutData.content)) {
    throw new Error('Invalid workout data structure');
  }

  // Format the workout data
  const formattedWorkout = formatWorkoutForSave(workoutData, workoutStrategy, options);

  // Return the prepared data for the API
  return {
    email,
    workout: formattedWorkout
  };
};

/**
 * Handle the complete workout save process
 * @param {Object} params - Save parameters
 * @returns {Promise} Save operation result
 */
export const handleWorkoutSave = async ({
  email,
  workoutData,
  workoutStrategy,
  options = {},
  existingWorkouts = [],
  callbacks = {},
  handlers = {}
}) => {
  const {
    showModal = () => {},
    setWorkoutTitle = () => {},
    setHasSaved = () => {},
    onSaveStart = () => {},
    onSaveComplete = () => {}
  } = callbacks;

  const {
    saveWorkout,
    updateWorkout
  } = handlers;

  try {
    if (!email) {
      showModal('Error', 'You must be logged in to save workouts.', 'error');
      return null;
    }

    onSaveStart();

    // Prepare the workout data
    const preparedData = prepareWorkoutForSave(email, workoutData, workoutStrategy, options);
    const workoutName = preparedData.workout.name;

    // Check for duplicates
    const existingWorkout = findExistingWorkout(existingWorkouts, workoutName);
    let response;

    if (existingWorkout) {
      // Handle duplicate case
      const userChoice = await new Promise((resolve) => {
        showModal(
          'Workout Already Exists',
          `A workout named "${workoutName}" already exists. Would you like to overwrite it or create a new version?`,
          'confirm',
          {
            choices: ['Overwrite', 'New Version', 'Cancel'],
            onChoice: resolve
          }
        );
      });

      switch (userChoice) {
        case 'Overwrite':
          response = await updateWorkout(
            email, 
            existingWorkout.workoutId, 
            { ...preparedData.workout, workoutId: existingWorkout.workoutId }
          );
          showModal('Success', 'Your workout plan has been updated successfully!', 'success');
          break;

        case 'New Version':
          const newName = getNextVersionName(workoutName, existingWorkouts);
          preparedData.workout.name = newName;
          response = await saveWorkout(email, preparedData.workout);
          setWorkoutTitle(newName);
          showModal('Success', `Your workout plan has been saved as "${newName}"!`, 'success');
          break;

        case 'Cancel':
          return null;
      }
    } else {
      // Save new workout
      response = await saveWorkout(email, preparedData.workout);
      showModal('Success', 'Your workout plan has been saved successfully!', 'success');
    }

    setHasSaved(true);
    onSaveComplete(response);
    return response;

  } catch (error) {
    console.error('Error in handleWorkoutSave:', error);
    showModal('Error', 'Failed to save workout plan. Please try again.', 'error');
    throw error;
  }
};