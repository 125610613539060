import React from 'react';
import HeroSection from '../components/landing-page/HeroSection';
import AboutSection from '../components/landing-page/AboutSection';
import HowItWorksComponent from '../components/landing-page/HowItWorks';
import PricingPlans from '../components/landing-page/PricingPlans';
import FAQ from '../components/landing-page/FAQ';
import Footer from '../components/landing-page/Footer';

const Landing = () => {
  return (
    <>

      <HeroSection />
      <AboutSection />
      <HowItWorksComponent />
      <PricingPlans />
      <FAQ />
      <Footer />
    </>
  );
};

export default Landing;
