import React from 'react';
import Navbar from './Navbar';
import CookieConsentBanner from './CookieConsentBanner';
import { useLocation } from 'react-router-dom';

const MainLayout = ({ children }) => {
  const location = useLocation();
  const isLandingPage = location.pathname === '/';
  const isAssistedWorkout = location.pathname === '/workspace/assisted-workout';

  
  return (
    <div className={`flex flex-col ${isLandingPage ? 'min-h-screen' : 'h-screen sm:overflow-hidden'}`}>
      <Navbar className="flex-shrink-0"/>
      <CookieConsentBanner />
      <div className={`${isLandingPage ? 'w-full' : `flex-1 ${isAssistedWorkout ? 'overflow-y-hidden' : 'overflow-y-auto'} flex justify-center`}`}>
        {children}
      </div>
    </div>
  );
};

export default MainLayout;