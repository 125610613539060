import React from 'react';
import { Link } from 'react-router-dom';
import { useUserProfile } from '../contexts/UserProfileContext';

const WorkspaceButton = ({ to, imageSrc, children, disabled, noCredits }) => (
  <div className="relative w-full sm:w-96">
    {disabled || noCredits ? (
      <Link to={noCredits ? "/select-tier" : "/select-tier"} className="block">
        <div className="flex flex-col items-center w-full mb-4 sm:mb-8 p-3 sm:p-8 rounded-lg shadow-lg bg-gray-200">
          <div className="w-32 h-44 sm:w-72 sm:h-72 flex items-center justify-center mb-2 sm:mb-6 opacity-50 blur-[2px]">
            <img 
              src={imageSrc} 
              alt="Workspace Icon" 
              className="w-full h-full object-contain"
            />
          </div>
          <span className="text-center font-semibold text-base sm:text-xl text-gray-500">
            {children}
          </span>
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="bg-black bg-opacity-70 text-white px-6 py-3 rounded-lg text-sm sm:text-base font-medium">
              {noCredits ? "Purchase credits to continue" : "Upgrade to Fit Hero to access"}
            </div>
          </div>
        </div>
      </Link>
    ) : (
      <Link
        to={to}
        className="flex flex-col items-center w-full mb-4 sm:mb-8 p-3 sm:p-8 bg-gray-100 rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-xl"
      >
        <div className="w-32 h-44 sm:w-72 sm:h-72 flex items-center justify-center mb-2 sm:mb-6">
          <img 
            src={imageSrc} 
            alt="Workspace Icon" 
            className="w-full h-full object-contain"
          />
        </div>
        <span className="text-center font-semibold text-base sm:text-xl text-black">
          {children}
        </span>
      </Link>
    )}
  </div>
);

const Workspace = () => {
  const { userProfile } = useUserProfile();
  const tier = userProfile?.tier;
  const credits = userProfile?.credits || 0;

  // Assisted workout now only depends on credits
  const canAccessAssistedWorkout = credits > 0;
  // Workout builder still requires pro or enterprise tier
  const canAccessWorkoutBuilder = tier === 'pro' || tier === 'enterprise';
  // Check if user has no credits for assisted workout
  const hasNoCredits = !canAccessAssistedWorkout;

  return (
    <div className="min-w-full min-h-full bg-light-secondary relative">
      <div className="max-w-7xl mx-auto py-4 sm:py-12 px-3 sm:px-6 lg:px-8 pb-16 sm:pb-20">
        <h1 className="text-xl sm:text-4xl font-bold mb-4 sm:mb-12 text-center">
          Welcome to PDFTrainer
        </h1>
        <div className="flex flex-col sm:flex-row items-center justify-center gap-3 sm:gap-8 px-2 sm:px-4">
          <WorkspaceButton 
            to="/workspace/assisted-workout" 
            imageSrc="/images/ai-workout.png"
            disabled={false}
            noCredits={hasNoCredits}
          >
            Let PDFTrainer create my workout plan
          </WorkspaceButton>
          <WorkspaceButton 
            to="/workspace/workout-builder" 
            imageSrc="/images/workout-builder.png"
            disabled={!canAccessWorkoutBuilder}
          >
            I want to build my own workout plan
          </WorkspaceButton>
        </div>
      </div>
    </div>
  );
};

export default Workspace;