// AssistedWorkout.js
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { ArrowRight, ArrowLeft, ArrowDown } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import StepOne from './assistedWorkoutSteps/step1';
import StepTwo from './assistedWorkoutSteps/step2';
import StepThree from './assistedWorkoutSteps/step3';
import StepFour from './assistedWorkoutSteps/step4';
import StepFive from './assistedWorkoutSteps/step5';
import StepSix from './assistedWorkoutSteps/step6';
import StepSeven from './assistedWorkoutSteps/step7';
import StepEight from './assistedWorkoutSteps/step8';
import { useWorkout } from './WorkoutContext';

const STEPS = [
  { id: 1, label: 'BASICS' },
  { id: 2, label: 'GOALS' }, 
  { id: 3, label: 'EXPERIENCE' },
  { id: 4, label: 'SCHEDULE' },
  { id: 5, label: 'EQUIPMENT' },
  { id: 6, label: 'REVIEW' },
  { id: 7, label: 'GENERATING' },
  { id: 8, label: 'COMPLETE' },
];

const ProgressBar = ({ currentStep, steps }) => {
  const calculateProgress = (current, total) => {
    if (current === 0) return 0;
    return `${(current / (total - 1)) * 100}%`;
  };

  return (
    <div className="flex-1 mx-4">
      <div className="relative h-2 bg-gray-200 rounded-full overflow-hidden">
        <div 
          className="absolute top-0 h-full bg-blue-500 transition-all duration-500 rounded-full"
          style={{ 
            left: 0,
            width: calculateProgress(currentStep, steps.length),
            transform: 'translateX(0)',
          }}
        />
        
        <div className="absolute w-full flex justify-between" style={{ top: '-6px' }}>
          {steps.map((_, index) => {
            const isCompleted = index < currentStep;
            const isCurrent = index === currentStep;

            return (
              <div 
                key={index}
                className="relative"
              >
                <div 
                  className={`
                    rounded-full transition-all duration-300
                    ${isCurrent ? 'w-4 h-5 sm:w-5 sm:h-5 -ml-2 sm:-ml-2.5 border-blue-500' : 'w-3 h-3 sm:w-4 sm:h-4 -ml-1.5 sm:-ml-2 border-none'}
                  `}
                >
                  <div
                    className={`
                      w-full h-full rounded-full transition-all duration-300
                      ${isCompleted ? 'bg-blue-500' : isCurrent ? 'bg-blue-500' : 'bg-transparent'}
                    `}
                  />
                </div>
                {isCurrent && (
                  <div className="absolute top-5 left-1/2 transform -translate-x-1/2 text-xs font-semibold text-blue-500">
                    Step {index + 1}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const AssistedWorkout = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [slideDirection, setSlideDirection] = useState('left');
  const { setShowFeedbackHints } = useWorkout();
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);
  const containerRef = useRef(null);
  const [formData, setFormData] = useState({
    name: '',
    gender: '',
    ageGroup: '',
    objective: '',
    focusAreas: [],
    experienceLevel: '',
    limitations: '',
    daysPerWeek: null,
    timePerSession: 60,
    equipment: [],
    split: '',
    additionalNotes: ''
  });

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;
      
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      const hasScrollableContent = scrollHeight > clientHeight;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - 20; // 20px threshold
      setShowScrollIndicator(hasScrollableContent && !isAtBottom && scrollTop < scrollHeight / 2);
    };
   
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      handleScroll();
      return () => container.removeEventListener('scroll', handleScroll);
    }
   }, []);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [currentStep]);

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };
  

  const handleBack = useCallback(() => {
    if (currentStep === 6) {
      setSlideDirection('right');
      setCurrentStep(5);
    } else if (currentStep === 7) {
      setSlideDirection('right');
      setCurrentStep(5);
    } else {
      setSlideDirection('right');
      setCurrentStep(prev => prev - 1);
    }
  }, [currentStep, setShowFeedbackHints]);
  


const handleGenerateStart = () => {
  setSlideDirection('left');
  setCurrentStep(6);
  setShowFeedbackHints(false);
};

  const handleGenerateFinalStart = () => {
    
    setSlideDirection('left');
    setCurrentStep(7);
  };

  const handleGenerateError = () => {
    setSlideDirection('right');
    setCurrentStep(5);
  };

  const updateFields = useCallback((fields) => {
    setFormData(prev => ({ ...prev, ...fields }));
  }, []);


  const isStepValid = () => {
    if (currentStep === 0) {
      const { name, gender, ageGroup } = formData;
      return name?.trim() && gender && ageGroup;
    }
    if (currentStep === 1) {
      const { objective, focusAreas } = formData;
      return objective && focusAreas.length > 0;
    }
    if (currentStep === 2) {
      const { experienceLevel } = formData;
      return experienceLevel;
    }
    if (currentStep === 3) {
      const { daysPerWeek } = formData;  // Only check daysPerWeek
      return daysPerWeek !== null;  // Changed to explicitly check for null
    }
    if (currentStep === 4) {
      const { equipment } = formData;
      return equipment && equipment.length > 0;
    }
    return false;
  };

  const slideVariants = {
    enter: (direction) => ({
      x: direction === 'left' ? 500 : -500,
      opacity: 0,
      position: 'absolute',
      width: '100%',
      top: 0
    }),
    center: {
      x: 0,
      opacity: 1,
      position: 'relative',
      width: '100%'
    },
    exit: (direction) => ({
      x: direction === 'left' ? -500 : 500,
      opacity: 0,
      position: 'absolute',
      width: '100%',
      top: 0
    })
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return <StepOne formData={formData} updateFields={updateFields} />;
      case 1:
        return <StepTwo formData={formData} updateFields={updateFields} />;
      case 2:
        return <StepThree formData={formData} updateFields={updateFields} />;
      case 3:
        return <StepFour formData={formData} updateFields={updateFields} />;
      case 4:
        return <StepFive formData={formData} updateFields={updateFields} />;
        case 5:
          return (
            <StepSix
              formData={formData}
              updateFields={updateFields}
              onBack={handleBack}
              onGenerate={handleGenerateStart}
            />
          );
        case 6:
          return (
            <StepSeven
              formData={formData}
              updateFields={updateFields}
              onBack={handleBack}
              onError={handleGenerateError}
              onGenerateFinal={handleGenerateFinalStart}
            />
          );
        
        case 7:
          return <StepEight />;
        default:
          return null;
      }
    };



  return (
    <div ref={containerRef} className="min-h-screen mb-20 sm:mb-0 bg-white p-6 overflow-auto sm:pb-6">
    <div className=" sm:min-w-[600px] max-w-xl mx-auto bg-white rounded-2xl shadow-xl p-8 flex flex-col overflow-y-hidden">
      <div className="flex flex-col items-center text-center justify-center mb-4">
          <h1 className="text-2xl font-bold text-gray-900">AI-Assisted Workout Plan</h1>
        </div>

        <div className="sm:min-h-[620px] min-h-[420px] sm:h-auto w-full relative overflow-y-hidden">
          <AnimatePresence mode="wait" initial={false} custom={slideDirection}>
            <motion.div
              key={currentStep}
              custom={slideDirection}
              variants={slideVariants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 }
              }}
              className="absolute inset-0 h-full"
            >
              <div className="h-full overflow-y-hidden">
                <div className="h-auto">
                  {renderStep()}
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>

        {currentStep < 8 && (
  <div className="flex items-center mt-8 px-4 py-2 bg-gray-50 rounded-full">
    <button
      className={`
        w-10 h-10 rounded-full flex items-center justify-center transition-all duration-300
        ${currentStep === 0 
          ? 'opacity-0 cursor-default' 
          : 'bg-white shadow-md hover:shadow-lg hover:scale-105'}
      `}
      onClick={() => {
        if (currentStep > 0) {
          setSlideDirection('right');
          setCurrentStep(prev => prev - 1);
        }
      }}
    >
      <ArrowLeft className={currentStep === 0 ? 'text-gray-300' : 'text-gray-600'} />
    </button>

    <ProgressBar currentStep={currentStep} steps={STEPS} />

    <button
      className={`
        w-10 h-10 rounded-full flex items-center justify-center transition-all duration-300
        ${isStepValid()
          ? 'bg-blue-600 shadow-md hover:shadow-lg hover:scale-105 hover:bg-blue-700'
          : 'bg-gray-200 cursor-not-allowed'}
      `}
      onClick={() => {
        if (isStepValid()) {
          setSlideDirection('left');
          setCurrentStep(prev => prev + 1);
        }
      }}
    >
      <ArrowRight className={isStepValid() ? 'text-white' : 'text-gray-400'} />
    </button>
  </div>
  
)}
        {showScrollIndicator && (
          <button
            onClick={scrollToBottom}
            className="fixed left-1/2 -translate-x-1/2 z-50 bottom-6 opacity-70 w-10 h-10 bg-blue-600 rounded-full flex items-center justify-center shadow-lg hover:bg-blue-700 transition-all"          >
            <ArrowDown className="w-6 h-6 text-white" />
          </button>
        )}
      </div>
    </div>
  );
};

export default AssistedWorkout;