// src/components/Modal.jsx
import React, { useEffect, useState } from 'react';
import { X, AlertCircle, CheckCircle, Info, AlertTriangle } from 'lucide-react';

const Modal = ({ 
  isOpen, 
  onClose, 
  title, 
  message, 
  type = 'info',
  actions = null,
  autoClose = true,
  choices = null,
  onChoice = null
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let timeout;
    if (isOpen && autoClose && !choices) {  // Don't auto-close if choices are present
      setIsVisible(true);
      timeout = setTimeout(() => {
        handleClose();
      }, 3000);
    } else if (isOpen) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
    return () => clearTimeout(timeout);
  }, [isOpen, autoClose, choices]);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  const handleChoice = (choice) => {
    if (onChoice) {
      onChoice(choice);
    }
    handleClose();
  };

  const getIcon = () => {
    switch (type) {
      case 'error':
        return <AlertCircle className="text-red-500 mr-5" size={20} />;
      case 'success':
        return <CheckCircle className="text-green-500 mr-5" size={20} />;
      case 'warning':
        return <AlertTriangle className="text-yellow-500 mr-5" size={20} />;
      case 'confirm':
        return <AlertCircle className="text-blue-500 mr-5" size={20} />;
      default:
        return <Info className="text-blue-500 mr-2" size={20} />;
    }
  };

  const getChoiceButtonStyle = (choice) => {
    switch (choice) {
      case 'Overwrite':
        return 'bg-blue-500 hover:bg-blue-600 text-white';
      case 'New Version':
        return 'bg-green-500 hover:bg-green-600 text-white';
      case 'Cancel':
        return 'bg-gray-500 hover:bg-gray-600 text-white';
      default:
        return 'bg-blue-500 hover:bg-blue-600 text-white';
    }
  };

  if (!isOpen && !isVisible) return null;

  return (
    <div className={`fixed bottom-0 left-1/2 transform -translate-x-1/2 w-full max-w-[70%] bg-light shadow-md px-4 py-3 transition-all duration-300 ease-in-out rounded-lg mb-2 ${
      isVisible ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'
    }`}>
      <div className="flex flex-col">
        <div className="flex justify-between items-start">
          <div className="flex-grow text-center flex items-center justify-center">
            {getIcon()}
            <div>
              <h2 className="text-dark font-semibold" style={{ fontSize: 'var(--font-size-lg)' }}>{title}</h2>
              <p className="text-dark mt-1" style={{ fontSize: 'var(--font-size-base)' }}>{message}</p>
            </div>
          </div>
          <button
            onClick={handleClose}
            className="text-dark hover:text-dark-primary ml-4"
            aria-label="Close"
          >
            <X size={20} />
          </button>
        </div>

        {choices && (
          <div className="flex justify-center gap-4 mt-4">
            {choices.map((choice, index) => (
              <button
                key={index}
                onClick={() => handleChoice(choice)}
                className={`px-4 py-2 rounded-md ${getChoiceButtonStyle(choice)}`}
              >
                {choice}
              </button>
            ))}
          </div>
        )}

        {actions && !choices && (
          <div className="flex justify-center gap-4 mt-4">
            {actions.map((action, index) => (
              <button
                key={index}
                onClick={() => {
                  action.onClick();
                  if (action.closeOnClick) handleClose();
                }}
                className={`px-4 py-2 rounded-md ${action.className}`}
              >
                {action.label}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;