import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Target } from 'lucide-react';
import { ReactComponent as AbsIcon } from '../../../assets/icons/abs.svg';
import { ReactComponent as BackIcon } from '../../../assets/icons/back.svg';
import { ReactComponent as ChestIcon } from '../../../assets/icons/chest.svg';
import { ReactComponent as ArmIcon } from '../../../assets/icons/arm.svg';
import { ReactComponent as LegIcon } from '../../../assets/icons/leg.svg';
import { ReactComponent as ShoulderIcon } from '../../../assets/icons/shoulder.svg';
import { ReactComponent as GlutesIcon } from '../../../assets/icons/glutes.svg';
import { ReactComponent as ScaleIcon } from '../../../assets/icons/scale.svg';
import { ReactComponent as BodyWeight } from '../../../assets/icons/bodyweight.svg';
import { ReactComponent as WeightliftingIcon } from '../../../assets/icons/weightlifting.svg';

const StepTwo = ({ formData, updateFields }) => {
  const objectives = [
    { label: 'Muscle Gain', icon: <ArmIcon className="w-9 h-8 text-green-600" /> },
    { label: 'Weight Loss', icon: <ScaleIcon className="w-9 h-8 text-green-600" /> },
    { label: 'Strength/Endurance', icon: <WeightliftingIcon className="w-9 h-8 text-green-600" /> },
    { label: 'Overall Fitness', icon: <BodyWeight className="w-9 h-8 text-green-600" /> }
  ];

  // Set initial objective if not already set
  useEffect(() => {
    if (!formData.objective) {
      updateFields({ objective: objectives[0].label });
    }
  }, []);

  const [currentIndex, setCurrentIndex] = useState(
    formData.objective ? objectives.findIndex(obj => obj.label === formData.objective) : 0
  );
  const [isAnimating, setIsAnimating] = useState(false);

  const handleNext = () => {
    if (isAnimating || currentIndex === objectives.length - 1) return;
    setIsAnimating(true);
    setCurrentIndex(currentIndex + 1);
    updateFields({ objective: objectives[currentIndex + 1].label });
    setTimeout(() => setIsAnimating(false), 300);
  };

  const handlePrev = () => {
    if (isAnimating || currentIndex === 0) return;
    setIsAnimating(true);
    setCurrentIndex(currentIndex - 1);
    updateFields({ objective: objectives[currentIndex - 1].label });
    setTimeout(() => setIsAnimating(false), 300);
  };

  const handleCardClick = (index) => {
    if (isAnimating || index === currentIndex) return;
    setIsAnimating(true);
    setCurrentIndex(index);
    updateFields({ objective: objectives[index].label });
    setTimeout(() => setIsAnimating(false), 300);
  };

  const handleFocusAreaChange = (area) => {
    let newFocusAreas;
    if (area === 'No preference') {
      newFocusAreas = ['No preference'];
    } else {
      const currentAreas = formData.focusAreas || [];
      if (currentAreas.includes('No preference')) {
        newFocusAreas = [area];
      } else {
        newFocusAreas = currentAreas.includes(area)
          ? currentAreas.filter(a => a !== area)
          : [...currentAreas, area];
      }
    }
    updateFields({ focusAreas: newFocusAreas });
  };

  const focusAreas = [
    { label: 'No preference', icon: <Target size={24} className="text-gray-600" /> },
    { label: 'Arms', icon: <ArmIcon className="sm:w-11 sm:h-11 w-6 h-6" /> },
    { label: 'Chest', icon: <ChestIcon className="sm:w-11 sm:h-11 w-6 h-6" /> },
    { label: 'Back', icon: <BackIcon className="sm:w-11 sm:h-11 w-6 h-6" /> },
    { label: 'Shoulders', icon: <ShoulderIcon className="sm:w-11 sm:h-11 w-6 h-6" /> },
    { label: 'Legs', icon: <LegIcon className="sm:w-11 sm:h-11 w-6 h-6" /> },
    { label: 'Glutes', icon: <GlutesIcon className="sm:w-11 sm:h-11 w-6 h-6" /> },
    { label: 'Core/Abs', icon: <AbsIcon className="sm:w-11 sm:h-11 w-6 h-6" /> }
  ];

  return (
    <div className="space-y-2 w-full">
      {/* Objectives Section with Carousel */}
      <div className="space-y-2">
        <label className="block text-lg font-medium text-gray-700">Define your main objective</label>
        <div className="relative h-40">
          {/* Edge Gradients */}
          <div className="absolute left-0 top-0 h-full w-32 bg-gradient-to-r from-white via-white to-transparent z-10"></div>
          <div className="absolute right-0 top-0 h-full w-32 bg-gradient-to-l from-white via-white to-transparent z-10"></div>

          {/* Navigation Buttons */}
          <button 
            onClick={handlePrev}
            disabled={currentIndex === 0}
            className={`absolute left-4 top-1/2 -translate-y-1/2 z-20 p-2 rounded-full bg-white shadow-md transition-all
              ${currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50 cursor-pointer'}`}
          >
            <ChevronLeft className="w-6 h-6 text-gray-600" />
          </button>

          <button 
            onClick={handleNext}
            disabled={currentIndex === objectives.length - 1}
            className={`absolute right-4 top-1/2 -translate-y-1/2 z-20 p-2 rounded-full bg-white shadow-md transition-all
              ${currentIndex === objectives.length - 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50 cursor-pointer'}`}
          >
            <ChevronRight className="w-6 h-6 text-gray-600" />
          </button>

          {/* Carousel */}
          <div className="relative w-full h-full overflow-hidden px-16">
            <div className="w-full h-full flex items-center justify-center">
              {objectives.map((objective, index) => {
                const position = index - currentIndex;
                return (
                  <div
                    key={objective.label}
                    onClick={() => handleCardClick(index)}
                    className={`
                      absolute w-40 transition-all duration-300 ease-in-out
                      ${Math.abs(position) <= 1 ? 'cursor-pointer' : ''}
                    `}
                    style={{
                      transform: `translateX(${position * 90}%) scale(${
                        position === 0 ? 1 : 0.8
                      })`,
                      opacity: Math.abs(position) > 1 ? 0 : 1 - Math.abs(position) * 0.5,
                      zIndex: 10 - Math.abs(position),
                      pointerEvents: Math.abs(position) <= 1 ? 'auto' : 'none'
                    }}
                  >
                    <div
                      className={`
                        p-4 rounded-xl border-2 transition-all
                        ${position === 0
                          ? 'bg-blue-100 border-blue-500 shadow-lg'
                          : 'bg-gray-50 border-gray-200 hover:bg-gray-100'
                        }
                      `}
                    >
                      <div className="flex flex-col items-center">
                        {objective.icon}
                        <span className="text-center font-medium mt-2">{objective.label}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* Focus Areas Section */}
      <div className="space-y-4">
        <label className="block text-lg font-medium text-gray-700">Focus Area</label>
        <div className="grid sm:grid-cols-4 grid-cols-3 gap-3">
          {focusAreas.map(({ label, icon }) => (
            <div
              key={label}
              onClick={() => handleFocusAreaChange(label)}
              className={`
                cursor-pointer p-3 rounded-lg transition-all duration-300 flex flex-col items-center
                aspect-square justify-center
                ${formData.focusAreas?.includes(label)
                  ? 'bg-blue-100 border-2 border-blue-500 shadow-lg' 
                  : 'bg-gray-50 border-2 border-gray-200 hover:bg-gray-100'}
                ${label === 'No preference' && formData.focusAreas?.length > 0 && 
                  !formData.focusAreas?.includes('No preference') ? 'opacity-50' : ''}
              `}
            >
              <div className="mb-1">{icon}</div>
              <span className="text-center text-xs font-medium">{label}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StepTwo;