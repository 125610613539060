import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import userHandler from '../services/handlers/userHandler';

const UserProfileContext = createContext();

export const UserProfileProvider = ({ children }) => {
  const { email, user } = useAuth();
  const [userProfile, setUserProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const navigate = useNavigate();

  const initializeUserProfile = useCallback(async () => {
    if (email && !isInitialized) {
      setIsLoading(true);
      try {
        const userName = user?.username || user?.name || email.split('@')[0];
        const upsertResponse = await userHandler.upsertUser(email, userName);
        const fullUserData = await userHandler.getUser(email);
        const combinedUserData = { ...upsertResponse.user, ...fullUserData };
        setUserProfile(combinedUserData);
        setIsInitialized(true);
        
        if (combinedUserData.tier === 'unassigned' || combinedUserData.tier === 'undefined') {
          navigate('/select-tier');
        }
      } catch (error) {
        console.error('Error initializing user profile:', error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [email, user, isInitialized, navigate]);

  useEffect(() => {
    initializeUserProfile();
  }, [initializeUserProfile]);

  const updateUserProfile = async (updates) => {
    setIsLoading(true);
    try {
      const updatedUser = await userHandler.updateUser(email, updates);
      setUserProfile(prevProfile => ({ ...prevProfile, ...updatedUser }));
      return updatedUser;  
    } catch (error) {
      console.error('Error updating user profile:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const refreshUserProfile = useCallback(async () => {
    if (email) {
      setIsLoading(true);
      try {
        const fullUserData = await userHandler.getUser(email);
        setUserProfile(fullUserData);
      } catch (error) {
        console.error('Error refreshing user profile:', error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [email]);



  return (
    <UserProfileContext.Provider value={{ 
      userProfile,
      setUserProfile, 
      isLoading, 
      updateUserProfile,
      refreshUserProfile,
      isInitialized
    }}>
      {children}
    </UserProfileContext.Provider>
  );
};

export const useUserProfile = () => {
  const context = useContext(UserProfileContext);
  if (context === undefined) {
    throw new Error('useUserProfile must be used within a UserProfileProvider');
  }
  return context;
};

export default UserProfileContext;