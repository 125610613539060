import React, { useState, useEffect } from 'react';
import { Plus, Loader, ArrowLeft, ChevronDown, Target, AlertCircle } from 'lucide-react';

const BlankPlaceholder = () => (
  <div className="flex flex-col items-center justify-center border rounded-lg bg-white h-48 p-2">
    <div className="w-24 h-24 bg-gray-200 rounded-lg mb-2 animate-pulse"></div>
    <div className="h-4 bg-gray-200 rounded w-3/4 mb-2 animate-pulse"></div>
    <div className="h-3 bg-gray-200 rounded w-1/2 animate-pulse"></div>
  </div>
);

const EmptyState = ({ onBack }) => (
  <div className="flex flex-col items-center justify-center h-full text-center p-8">
    <div className="bg-gray-100 rounded-full p-4 mb-4">
      <AlertCircle size={48} className="text-gray-400" />
    </div>
    <h3 className="text-lg font-semibold text-gray-700 mb-2">
      No exercises available
    </h3>
    <p className="text-gray-500 mb-6 max-w-md">
      We couldn't find any exercises matching your selection. Please try a different combination of muscle group and equipment.
    </p>
    <button
      onClick={onBack}
      className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center gap-2"
    >
      <ArrowLeft size={20} />
      Go Back
    </button>
  </div>
);

const SelectionContent = ({ 
  title, 
  options, 
  onSelect, 
  onLoadMore, 
  hasMore, 
  isLoading, 
  isLoadingMore,
  onBack 
}) => {
  const [submuscleFilter, setSubmuscleFilter] = useState('All');
  const [submuscles, setSubmuscles] = useState(['All']);

  useEffect(() => {
    const uniqueSubmuscles = ['All', ...new Set(options.map(option => option.targetedMuscle).filter(Boolean))];
    setSubmuscles(uniqueSubmuscles);
  }, [options]);

  const filteredOptions = submuscleFilter === 'All' 
    ? options 
    : options.filter(option => option.targetedMuscle === submuscleFilter);

  const isEquipmentSelection = title.toLowerCase().includes('equipment');
  const showEmptyState = !isLoading && options.length === 0;

  const renderLoadingGrid = () => (
    <div className="grid grid-cols-3 gap-3 relative">
      {Array.from({ length: 9 }).map((_, index) => (
        <BlankPlaceholder key={index} />
      ))}
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="bg-white p-4 rounded-lg shadow-lg flex flex-col items-center">
          <Loader size={48} className="animate-spin mb-2" />
          <span className="text-sm font-medium">Loading exercises...</span>
        </div>
      </div>
    </div>
  );

  return (
    <div className="h-full flex flex-col">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          <button 
            onClick={onBack} 
            className="mr-4 p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
          >
            <ArrowLeft size={24} />
          </button>
          <h2 className="text-2xl font-bold">{title}</h2>
        </div>
        {submuscles.length > 1 && !isEquipmentSelection && !showEmptyState && (
          <div className="relative">
            <select
              value={submuscleFilter}
              onChange={(e) => setSubmuscleFilter(e.target.value)}
              className="appearance-none bg-white border border-gray-300 rounded-md py-2 pl-3 pr-10 text-sm leading-5 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            >
              {submuscles.map((submuscle) => (
                <option key={submuscle} value={submuscle}>{submuscle}</option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <ChevronDown size={16} />
            </div>
          </div>
        )}
      </div>
      
      <div className="flex-grow overflow-y-auto">
        {isLoading ? (
          renderLoadingGrid()
        ) : showEmptyState ? (
          <EmptyState onBack={onBack} />
        ) : (
          <div className="grid grid-cols-3 gap-3">
            {filteredOptions.map((option, index) => (
              <button
                key={index}
                onClick={() => onSelect(option)}
                className="flex flex-col items-center justify-center border rounded-lg bg-white h-40 p-2 hover:shadow-lg transition-shadow duration-200"
              >
                <div className="flex items-center justify-center h-20 w-full mb-2">
                  {option.icon && typeof option.icon === 'function' ? option.icon() : 
                   option.gifUrl ? <img src={option.gifUrl} alt={option.name} className="w-full h-full object-contain rounded-lg" /> :
                   <div className="w-full h-full bg-gray-200 flex items-center justify-center text-gray-500">No Image</div>}
                </div>
                <span className="text-sm text-center font-semibold line-clamp-2 mb-1">{option.name}</span>
                {option.targetedMuscle && !option.exerciseCount && !isEquipmentSelection && (
                  <span className="text-xs text-center text-gray-600">
                    {option.targetedMuscle}
                  </span>
                )}
                {option.exerciseCount !== undefined && (
                  <span className="text-xs text-center text-blue-600 mt-1">
                    {option.exerciseCount} exercises
                  </span>
                )}
              </button>
            ))}
            {hasMore && (
              <button
                onClick={onLoadMore}
                disabled={isLoadingMore}
                className="flex flex-col items-center justify-center p-2 border rounded-lg hover:bg-gray-100 h-48 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isLoadingMore ? (
                  <>
                    <Loader size={48} className="animate-spin mb-2" />
                    <span className="text-sm text-center">Loading more...</span>
                  </>
                ) : (
                  <>
                    <Plus size={48} className="mb-2" />
                    <span className="text-sm text-center">Load More</span>
                  </>
                )}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectionContent;