import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import userHandler from '../services/handlers/userHandler';

const styles = {
  container: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '40px 20px',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
    lineHeight: '1.6'
  },
  logo: {
    fontSize: '64px',
    marginBottom: '20px'
  },
  title: {
    color: '#333',
    marginBottom: '20px'
  },
  email: {
    fontSize: '18px',
    color: '#666',
    marginBottom: '30px'
  },
  preferences: {
    textAlign: 'center',
    marginBottom: '30px'
  },
  preferenceItem: {
    display: 'block',
    marginBottom: '15px',
    fontSize: '16px'
  },
  checkbox: {
    marginRight: '10px'
  },
  button: {
    backgroundColor: 'blue',
    border: 'none',
    color: 'white',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer'
  },
  message: {
    padding: '10px',
    marginBottom: '20px',
    borderRadius: '4px'
  },
  error: {
    backgroundColor: '#FFEBEE',
    color: '#D32F2F'
  },
  success: {
    backgroundColor: '#E8F5E9',
    color: '#388E3C'
  }
};

const Unsubscribe = () => {
  const [email, setEmail] = useState('');
  const [preferences, setPreferences] = useState({ promoEmail: true, accountUpdates: true });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    if (token) {
      verifyTokenAndFetchPreferences(token);
    } else {
      setError('Invalid unsubscribe link');
      setIsLoading(false);
    }
  }, [location]);

  const verifyTokenAndFetchPreferences = async (token) => {
    try {
      const response = await userHandler.verifyUnsubscribeToken(token);
      setEmail(response.email);
      setPreferences(response.emailPreferences);
      setIsLoading(false);
    } catch (error) {
      console.error('Token verification failed:', error);
      setError('Invalid or expired unsubscribe link');
      setIsLoading(false);
    }
  };

  const handlePreferenceChange = (preference) => {
    setPreferences(prev => ({ ...prev, [preference]: !prev[preference] }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await userHandler.updateUser(email, { emailPreferences: preferences });
      setSuccessMessage('Your email preferences have been updated successfully.');
    } catch (error) {
      setError('Failed to update email preferences');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <div style={styles.container}>Loading...</div>;
  if (error) return <div style={styles.container}><div style={{...styles.message, ...styles.error}}>{error}</div></div>;
  if (successMessage) return <div style={styles.container}><div style={{...styles.message, ...styles.success}}>{successMessage}</div></div>;

  return (
    <div style={styles.container}>
      <div style={styles.logo}>📧</div>
      <h1 style={styles.title}>Manage Email Preferences</h1>
      <p style={styles.email}>Email: {email}</p>
      <div style={styles.preferences}>
        <label style={styles.preferenceItem}>
          <input
            type="checkbox"
            style={styles.checkbox}
            checked={preferences.promoEmail}
            onChange={() => handlePreferenceChange('promoEmail')}
          />
          Receive promotional emails
        </label>
        <label style={styles.preferenceItem}>
          <input
            type="checkbox"
            style={styles.checkbox}
            checked={preferences.accountUpdates}
            onChange={() => handlePreferenceChange('accountUpdates')}
          />
          Receive account update emails
        </label>
      </div>
      <button style={styles.button} onClick={handleSubmit}>Update Preferences</button>
    </div>
  );
};

export default Unsubscribe;