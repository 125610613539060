import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchExercises, fetchExercisesByIds } from '../../services/handlers/serviceHandler';

const WorkoutContext = createContext();

export const useWorkout = () => useContext(WorkoutContext);

export const WorkoutProvider = ({ children }) => {
  const [workout, setWorkout] = useState([[]]);
  const [currentDay, setCurrentDay] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({
    bodyPart: null,
    equipment: null,
    exercise: null,
    parameters: null
  });
  const [availableExercises, setAvailableExercises] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [totalExercises, setTotalExercises] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationInfo, setPaginationInfo] = useState({});
  const [loadedWorkout, setLoadedWorkout] = useState(null);
  const [workoutStrategy, setWorkoutStrategy] = useState()
  const [workoutFinal, setWorkoutFinal] = useState(null)
  const [showFeedbackHints, setShowFeedbackHints] = useState(false);
  const [previousStrategy, setPreviousStrategy] = useState(null);
  const [hasSaved, setHasSaved] = useState(false);
  const [newExerciseId, setNewExerciseId] = useState(null);
  const [selectedExerciseId, setSelectedExerciseId] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [originalAdditionalNotes, setOriginalAdditionalNotes] = useState('')



  useEffect(() => {
    let isMounted = true;
  
    const handleSaveWorkout = async () => {
      if (workoutFinal && isMounted) {
        setHasSaved(false);
        setWorkout(workoutFinal.content || [[]]);
    
        setWorkoutStrategy(prev => ({
          ...prev,
          workoutPlan: {
            ...prev?.workoutPlan,
            name: workoutFinal.workoutPlan?.name || workoutFinal.name || "My Workout Plan",
            overallStrategy: workoutFinal.workoutPlan?.overallStrategy || workoutFinal.planStrategy || "",
            daysPerWeek: workoutFinal.content?.length || 0,
            timePerSession: workoutFinal.timePerSession || 60,
            programDuration: workoutFinal.workoutPlan?.programDuration || "4-6 weeks",
            days: workoutFinal.workoutPlan?.days || {},
          }
        }));
      }
    };
  
    handleSaveWorkout();
  
    return () => {
      isMounted = false;
    };
  }, [workoutFinal]);

  useEffect(() => {

  }, [workoutStrategy]);

  useEffect(() => {
    if (newExerciseId) {
      // Clear any existing selection first
      setSelectedExerciseId(null);
      
      // Then set the new selection after a brief delay
      const timer = setTimeout(() => {
        setSelectedExerciseId(newExerciseId);
        
        // Scroll to the new exercise
        const exerciseElement = document.querySelector(`[data-exercise-id="${newExerciseId}"]`);
        if (exerciseElement) {
          exerciseElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        
        // Clear the newExerciseId
        setNewExerciseId(null);
      }, 100);
  
      return () => clearTimeout(timer);
    }
  }, [newExerciseId]);

const loadWorkout = async (workoutData) => {
  
  setLoadedWorkout(workoutData);
  setHasUnsavedChanges(false);

  
  if (workoutData) {
    // Load exercises if content exists
    if (workoutData.content) {
      const exerciseIds = workoutData.content.flat().map(exercise => exercise.id);
      const exercisesData = await fetchExercisesByIds(exerciseIds);
      
      // Create workout array with day strategies
      const workoutWithStrategies = workoutData.content.map((day, index) => {
        // Get the day strategy from workoutData.workoutPlan.days
        const dayNumber = index + 1;
        const dayStrategy = workoutData.workoutPlan?.days?.[`day${dayNumber}`]?.strategy || '';
        
        // Map exercises and add strategy to the day array
        const exercises = day.map(exerciseInfo => {
          const exerciseData = exercisesData.find(ex => ex.id === exerciseInfo.id);
          return {
            ...exerciseData,
            parameters: exerciseInfo.parameters
          };
        });
        
        // Add strategy property to the day array
        exercises.strategy = dayStrategy;
        return exercises;
      });
      
      setWorkout(workoutWithStrategies);
    }

    setWorkoutStrategy({
      workoutPlan: {
        name: workoutData.name || "My Workout Plan",
        overallStrategy: workoutData.strategy || "A focused approach targeting strength and muscle growth",
        daysPerWeek: workoutData.content?.length || 0,
        timePerSession: workoutData.timePerSession || workoutData.workoutPlan?.workoutPlan?.timePerSession || 60,
        programDuration: "4-6 weeks",
        days: workoutData.workoutPlan?.days || {},
        food: workoutData.workoutPlan?.notes?.food || "",
        weights: workoutData.workoutPlan?.notes?.weights || "",
        repsAndSets: workoutData.workoutPlan?.notes?.repsAndSets || ""
      }
    });
  } else {
    setWorkout([[]]);
    setWorkoutStrategy(null);
  }
};

  const fetchAvailableExercises = async (bodyPart, equipment) => {
    if (!bodyPart || !equipment) {
      setAvailableExercises([]);
      setTotalExercises(0);
      setPaginationInfo({});
      return;
    }

    setIsLoading(true);
    setCurrentPage(1);

    try {
      const { exercises, totalExercises, paginationInfo } = await fetchExercises(
        bodyPart,
        equipment,
        1
      );
      
      setAvailableExercises(exercises || []);
      setTotalExercises(totalExercises || 0);
      setPaginationInfo(paginationInfo || {});
    } catch (error) {
      console.error('Error fetching exercises:', error);
      setAvailableExercises([]);
      setTotalExercises(0);
      setPaginationInfo({});
    } finally {
      setIsLoading(false);
    }
  };

  

  const loadMoreExercises = async () => {
    if (isLoadingMore || !selectedOptions.bodyPart || !selectedOptions.equipment) return;
    if (paginationInfo.page >= paginationInfo.totalPages) return;
    
    setIsLoadingMore(true);
    const nextPage = currentPage + 1;
    try {
      const { exercises, paginationInfo: newPaginationInfo } = await fetchExercises(
        selectedOptions.bodyPart.name,
        selectedOptions.equipment.name,
        nextPage
      );
      
      if (exercises && exercises.length > 0) {
        setAvailableExercises(prev => [...prev, ...exercises]);
        setPaginationInfo(newPaginationInfo);
        setCurrentPage(nextPage);
      }
    } catch (error) {
      console.error('Error loading more exercises:', error);
    } finally {
      setIsLoadingMore(false);
    }
  };


  const addExercise = (exercise, day = currentDay) => {
    const frontendId = Date.now();
    
    // First update the workout
    setWorkout(prev => {
      const newWorkout = [...prev];
      if (!newWorkout[day]) {
        newWorkout[day] = [];
      }
      newWorkout[day] = [...newWorkout[day], { 
        ...exercise,
        id: exercise.id,
        _frontendId: frontendId
      }];
      return newWorkout;
    });
  
    // Then set the new exercise ID with a slight delay
    setTimeout(() => {
      setNewExerciseId(frontendId);
    }, 50);
  };
  
  const resetExerciseSelection = () => {
    setSelectedOptions({
      bodyPart: null,
      equipment: null,
      exercise: null,
      parameters: null
    });
    setAvailableExercises([]);
    setCurrentPage(1);
    setTotalExercises(0);
    setPaginationInfo({});
  };

  const removeExercise = (frontendId, day = currentDay) => {
    setWorkout(prev => {
      const newWorkout = [...prev];
      newWorkout[day] = newWorkout[day].filter(ex => ex._frontendId !== frontendId);
      return newWorkout;
    });
  };

  const addDay = () => {
    setWorkout(prev => [...prev, []]);
    setCurrentDay(prev => prev + 1);
  };

  const removeDay = (dayIndex) => {
    setWorkout(prev => {
      const newWorkout = [...prev];
      newWorkout.splice(dayIndex, 1);
      return newWorkout;
    });
    setCurrentDay(prev => Math.min(prev, workout.length - 2));
  };



  const setExerciseParameters = (parameters) => {
    setSelectedOptions(prev => ({
      ...prev,
      parameters: parameters
    }));
  };

  const clearWorkout = () => {
    setWorkout([[]]);
    setCurrentDay(0);
  };

  const duplicateDay = (dayIndex) => {
    setWorkout(prev => {
      const newWorkout = [...prev];
      const dayToDuplicate = [...newWorkout[dayIndex]];
      newWorkout.splice(dayIndex + 1, 0, dayToDuplicate);
      return newWorkout;
    });
  };

  const moveExercise = (fromDay, toDay, exerciseIndex) => {
    setWorkout(prev => {
      const newWorkout = [...prev];
      const [movedExercise] = newWorkout[fromDay].splice(exerciseIndex, 1);
      newWorkout[toDay].push(movedExercise);
      return newWorkout;
    });
  };

  return (
    <WorkoutContext.Provider value={{
      setWorkout,
      workout,
      currentDay,
      setCurrentDay,
      selectedOptions,
      setSelectedOptions,
      availableExercises,
      setAvailableExercises,
      totalExercises,
      paginationInfo,
      addExercise,
      removeExercise,
      addDay,
      removeDay,
      isLoading,
      isLoadingMore,
      loadMoreExercises,
      resetExerciseSelection,
      setExerciseParameters,
      clearWorkout,
      duplicateDay,
      moveExercise,
      fetchAvailableExercises,
      loadedWorkout,
      loadWorkout,
      workoutStrategy,
      setWorkoutStrategy,
      workoutFinal,
      setWorkoutFinal,
      showFeedbackHints,
      setShowFeedbackHints,
      previousStrategy,
      setPreviousStrategy,
      hasSaved,
      setHasSaved,
      newExerciseId,
      setNewExerciseId,
      selectedExerciseId,
      setSelectedExerciseId,
      hasUnsavedChanges,
      setHasUnsavedChanges,
      originalAdditionalNotes, 
      setOriginalAdditionalNotes
    }}>
      {children}
    </WorkoutContext.Provider>
  );
};

export default WorkoutProvider;