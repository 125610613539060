import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-black text-white py-8 px-4 text-center">
      <div className="flex items-center justify-center gap-8 text-sm text-gray-400">
        <a 
          href="/terms-of-service.html"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-400"
        >
          Terms of Service
        </a>
        
        <p>© 2024 PDFTrainer. All rights reserved.</p>
        
        <a 
          href="/privacy-policy.html" 
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-400"
        >
          Privacy Policy
        </a>
      </div>
    </footer>
  );
};

export default Footer;