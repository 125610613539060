import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { Check } from 'lucide-react';
import { useInView } from 'react-intersection-observer';

const AboutSection = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 },
    },
  };

  return (
    <section id='about' className="relative py-8 md:py-10 px-4 md:px-6 flex justify-center overflow-hidden bg-cover bg-center bg-black">
      <div className="absolute inset-0 bg-black opacity-70"></div>
      <div className="relative z-10 max-w-6xl w-full">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={containerVariants}
          className="bg-white rounded-3xl overflow-hidden"
          style={{ boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}
        >
          <div className="flex flex-col md:flex-row items-center p-6 md:p-12">
            <div className="w-full md:w-1/2 pr-0 md:pr-8 mb-6 md:mb-0">
              <motion.h2 variants={itemVariants} className="text-3xl md:text-4xl font-bold mb-4 md:mb-6 font-inter text-gray-900 leading-tight text-center md:text-left">What is PDFTrainer?</motion.h2>
              <motion.div variants={itemVariants}>
                <p className="text-lg md:text-xl text-gray-800 mb-4 md:mb-6 font-fira leading-relaxed">
                  PDFTrainer offers two powerful core services:
                </p>
                <div className="space-y-4 mb-6">
                  <p className="text-base md:text-lg text-gray-800">
                    <span className="font-semibold text-black">1. AI-Powered Virtual Trainer:</span> Personalized, comprehensive workout plans tailored to your needs.
                  </p>
                  <p className="text-base md:text-lg text-gray-800">
                    <span className="font-semibold text-black">2. Intuitive Workout Builder:</span> You have fitness knowledge and want to build your own plan? Just use our workout plan builder with over illustrated 1000 exercises.
                  </p>
                </div>
              </motion.div>
              <ul className="space-y-3 md:space-y-4 text-gray-800 font-inter">
                {[
                  'Download your workouts and keep them forever.',
                  'Simple, easy-to-follow exercise plans with images and GIFs.',
                  'Our feedback system allows you to fine-tune current and future workouts.'
                ].map((item, index) => (
                  <motion.li key={index} variants={itemVariants} className="flex items-center">
                    <Check className="text-black mr-2 md:mr-3 h-5 w-5 md:h-6 md:w-6 flex-shrink-0" />
                    <span className="text-base md:text-lg">{item}</span>
                  </motion.li>
                ))}
              </ul>
            </div>
            <motion.div variants={itemVariants} className="w-full md:w-1/2">
              <div className="relative">
                <img
                  src="/images/phone-about2.png"
                  alt="PDF Trainer"
                  className="w-24 md:w-44 max-w-md mx-auto relative z-10"
                  style={{ filter: "drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.8))" }}
                />
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default AboutSection;