import axios from 'axios';
import { CognitoUserPool, CognitoRefreshToken } from 'amazon-cognito-identity-js';

const API_URL = 'https://d120ovw29hasnn.cloudfront.net/user' //'https://iucpms4k452nme2x37bbjqiury0dwuab.lambda-url.us-east-1.on.aws';

const poolData = {
  UserPoolId: 'us-east-1_bAQ2VWDUK',
  ClientId: '1ktl88fh2jcn0m8infb6eilmh'
};

const userPool = new CognitoUserPool(poolData);

// Token management functions
const getToken = (tokenType) => {
  const keys = Object.keys(localStorage);
  const tokenKey = keys.find(key => 
    key.startsWith('CognitoIdentityServiceProvider') && 
    key.endsWith(tokenType)
  );
  return tokenKey ? localStorage.getItem(tokenKey) : null;
};

const getJwtToken = () => getToken('accessToken');
const getRefreshToken = () => getToken('refreshToken');

const isTokenExpired = (token) => {
  if (!token) return true;
  const expiry = JSON.parse(atob(token.split('.')[1])).exp;
  return Math.floor(new Date().getTime() / 1000) >= expiry;
};

const refreshToken = async () => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          const refreshToken = new CognitoRefreshToken({RefreshToken: getRefreshToken()});
          cognitoUser.refreshSession(refreshToken, (err, session) => {
            if (err) {
              reject(err);
            } else {
              const newAccessToken = session.getAccessToken().getJwtToken();
              const accessTokenKey = Object.keys(localStorage).find(key => 
                key.startsWith('CognitoIdentityServiceProvider') && 
                key.endsWith('accessToken')
              );
              if (accessTokenKey) {
                localStorage.setItem(accessTokenKey, newAccessToken);
              }
              resolve(newAccessToken);
            }
          });
        }
      });
    } else {
      reject(new Error('No current user'));
    }
  });
};

// API request wrapper with token handling
const apiRequest = async (endpoint, method = 'GET', body = null) => {
  let token = getJwtToken();
  
  if (!token || isTokenExpired(token)) {
    try {
      token = await refreshToken();
    } catch (error) {
      console.error('Error refreshing token:', error);
      throw new Error('Session expired. Please log in again.');
    }
  }

  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json'
  };

  try {
    const response = await axios({
      method,
      url: `${API_URL}${endpoint}`,
      headers,
      data: body
    });
    return response.data;
  } catch (error) {
    console.error('API Error:', error.response?.data || error.message);
    throw error;
  }
};

const paymentHandler = {
  async createCheckoutSession(email, priceId, isSubscription = true) {
    try {
      const response = await apiRequest('/create-checkout-session', 'POST', { 
        email, 
        priceId,
        isSubscription
      });
      return response;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      throw error;
    }
  },

  async upgradeSubscription(email, newPriceId) {
    try {
      const response = await apiRequest('/upgrade-subscription', 'POST', { 
        email, 
        newPriceId 
      });
      return response;
    } catch (error) {
      console.error('Error upgrading subscription:', error);
      throw error;
    }
  },

  async downgradeSubscription(email, newPriceId) {
    try {
      const response = await apiRequest('/downgrade-subscription', 'POST', { 
        email, 
        newPriceId 
      });
      return response;
    } catch (error) {
      console.error('Error downgrading subscription:', error);
      throw error;
    }
  },

  async cancelDowngrade(email) {
    try {
      const response = await apiRequest('/cancel-downgrade', 'POST', { 
        email 
      });
      return response;
    } catch (error) {
      console.error('Error cancelling downgrade:', error);
      throw error;
    }
  }
};

export default paymentHandler;