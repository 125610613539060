import React, { useState, useEffect } from 'react';
import { useUserProfile } from '../../../contexts/UserProfileContext';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { ReactComponent as MaleIcon } from '../../../assets/icons/male.svg';
import { ReactComponent as FemaleIcon } from '../../../assets/icons/female.svg';

const StepOne = ({ formData, updateFields }) => {
  const { userProfile } = useUserProfile();
  const ageGroups = [
    { label: "15-18" },
    { label: "19-29" },
    { label: "30-39" },
    { label: "40-49" },
    { label: "50-59" },
    { label: "60+" }
  ];

  useEffect(() => {
    if (userProfile?.name && !formData.name) {
      updateFields({ name: userProfile.name });
    }
    if (!formData.ageGroup) {
      updateFields({ ageGroup: ageGroups[0].label });
    }
  }, [userProfile, formData.name, formData.ageGroup, updateFields]);

  const [currentIndex, setCurrentIndex] = useState(
    formData.ageGroup ? ageGroups.findIndex(group => group.label === formData.ageGroup) : 0
  );
  const [isAnimating, setIsAnimating] = useState(false);

  const handleNext = () => {
    if (isAnimating || currentIndex === ageGroups.length - 1) return;
    setIsAnimating(true);
    setCurrentIndex(currentIndex + 1);
    updateFields({ ageGroup: ageGroups[currentIndex + 1].label });
    setTimeout(() => setIsAnimating(false), 300);
  };

  const handlePrev = () => {
    if (isAnimating || currentIndex === 0) return;
    setIsAnimating(true);
    setCurrentIndex(currentIndex - 1);
    updateFields({ ageGroup: ageGroups[currentIndex - 1].label });
    setTimeout(() => setIsAnimating(false), 300);
  };

  const handleCardClick = (index) => {
    if (isAnimating || index === currentIndex) return;
    setIsAnimating(true);
    setCurrentIndex(index);
    updateFields({ ageGroup: ageGroups[index].label });
    setTimeout(() => setIsAnimating(false), 300);
  };

  return (
    <div className="space-y-4 w-full">
      <div className="space-y-2 over">
        <label className="block text-lg font-medium text-gray-700">Name</label>
        <input
          type="text"
          value={formData.name || ''}
          onChange={(e) => updateFields({ name: e.target.value })}
          className="w-full p-3 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Enter your name"
        />
      </div>

      <div className="space-y-4">
        <label className="block text-lg font-medium text-gray-700">Gender</label>
        <div className="flex gap-6 justify-center">
          {[
            { value: 'male', Icon: MaleIcon, label: 'Male', color: 'text-blue-600' },
            { value: 'female', Icon: FemaleIcon, label: 'Female', color: 'text-pink-600' }
          ].map(({ value, Icon, label, color }) => (
            <div
              key={value}
              onClick={() => updateFields({ gender: value })}
              className={`
                cursor-pointer p-5 rounded-xl transition-all duration-300 flex flex-col items-center h-28 w-28
                ${formData.gender === value 
                  ? 'bg-blue-100 border-2 border-blue-500 shadow-lg' 
                  : 'bg-gray-50 border-2 border-gray-200 hover:bg-gray-100'}
              `}
            >
              <Icon className={`w-10 h-10 ${color}`} />
              <span className="mt-2 font-medium">{label}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="space-y-1">
        <label className="block text-lg font-medium text-gray-700">Age Group</label>
        <div className="relative h-32">
          {/* Edge Gradients */}
          <div className="absolute left-0 top-0 h-full w-32 bg-gradient-to-r from-white via-white to-transparent z-10"></div>
          <div className="absolute right-0 top-0 h-full w-32 bg-gradient-to-l from-white via-white to-transparent z-10"></div>

          {/* Navigation Buttons */}
          <button 
            onClick={handlePrev}
            disabled={currentIndex === 0}
            className={`absolute left-4 top-1/2 -translate-y-1/2 z-20 p-2 rounded-full bg-white shadow-md transition-all
              ${currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50 cursor-pointer'}`}
          >
            <ChevronLeft className="w-6 h-6 text-gray-600" />
          </button>

          <button 
            onClick={handleNext}
            disabled={currentIndex === ageGroups.length - 1}
            className={`absolute right-4 top-1/2 -translate-y-1/2 z-20 p-2 rounded-full bg-white shadow-md transition-all
              ${currentIndex === ageGroups.length - 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50 cursor-pointer'}`}
          >
            <ChevronRight className="w-6 h-6 text-gray-600" />
          </button>

          {/* Carousel */}
          <div className="relative w-full h-full overflow-hidden px-16">
            <div className="w-full h-full flex items-center justify-center">
              {ageGroups.map((ageGroup, index) => {
                const position = index - currentIndex;
                return (
                  <div
                    key={ageGroup.label}
                    onClick={() => handleCardClick(index)}
                    className={`
                      absolute w-40 transition-all duration-300 ease-in-out
                      ${Math.abs(position) <= 1 ? 'cursor-pointer' : ''}
                    `}
                    style={{
                      transform: `translateX(${position * 90}%) scale(${
                        position === 0 ? 1 : 0.8
                      })`,
                      opacity: Math.abs(position) > 1 ? 0 : 1 - Math.abs(position) * 0.5,
                      zIndex: 10 - Math.abs(position),
                      pointerEvents: Math.abs(position) <= 1 ? 'auto' : 'none'
                    }}
                  >
                    <div
                      className={`
                        p-4 rounded-xl border-2 transition-all
                        ${position === 0
                          ? 'bg-blue-100 border-blue-500 shadow-lg'
                          : 'bg-gray-50 border-gray-200 hover:bg-gray-100'
                        }
                      `}
                    >
                      <div className="flex flex-col items-center">
                        <span className="text-center text-xl font-medium">{ageGroup.label}</span>
                        <span className="text-center text-sm text-gray-500 mt-2">years</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepOne;