// ConfirmSignup.js
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const ConfirmSignup = () => {
  const { confirmSignUp } = useAuth();
  const [code, setCode] = useState('');
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleConfirm = async () => {
    try {
      const result = await confirmSignUp(state.username, code);
      if (result.success) {
        navigate('/login'); // Redirect to login after successful confirmation
      } else {
        console.error('Confirmation failed:', result.message);
      }
    } catch (error) {
      console.error('Error confirming sign-up:', error);
    }
  };

  return (
    <div>
      <h2>Confirm Signup</h2>
      <input 
        type="text" 
        placeholder="Confirmation Code" 
        value={code} 
        onChange={(e) => setCode(e.target.value)} 
      />
      <button onClick={handleConfirm}>Confirm</button>
    </div>
  );
};

export default ConfirmSignup;
