import React, { createContext, useContext, useState, useEffect } from 'react';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

const AuthStateWrapper = ({ children }) => {
  const { user: amplifyUser, signOut: amplifySignOut } = useAuthenticator((context) => [context.user]);
  const [email, setEmail] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getSessionInfo = async () => {
      if (amplifyUser) {
        try {
          const session = await fetchAuthSession();
          const sessionEmail = session.tokens.idToken.payload.email;
          setEmail(sessionEmail);
          setUser(session.tokens.idToken.payload);
        } catch (error) {
          console.error('Error fetching auth session:', error);
          setEmail(null);
          setUser(null);
        }
      } else {
        setEmail(null);
        setUser(null);
      }
    };
    getSessionInfo();
  }, [amplifyUser]);

  const signOut = async () => {
    try {
      await amplifySignOut();
      setEmail(null);
      setUser(null);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ email, setEmail, user, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const AuthProvider = ({ children }) => {
  return (
    <Authenticator.Provider>
      <AuthStateWrapper>{children}</AuthStateWrapper>
    </Authenticator.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};