import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Menu, Instagram } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const NavLink = ({ href, children, onClick }) => (
  <a
  href={href}
  className="text-white mx-4 relative group font-inter"
  onClick={(e) => {
    e.preventDefault();
    const element = document.querySelector(href);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    if (onClick) onClick();
  }}
>
  <span className="relative z-10 transition-transform duration-300 group-hover:scale-110 inline-block">
    {children}
  </span>
</a>
);

const InstagramIcon = () => (
  <a
    href="https://www.instagram.com/pdftrainer"
    target="_blank"
    rel="noopener noreferrer"
    className="text-logoRed hover:text-white  mr-8 md:mr-0 md:ml-8 transition-colors duration-300"
  >
    <Instagram size={28} />
  </a>
);

const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const closeMenu = () => setIsOpen(false);

  return (
    <div className="md:hidden relative">
      <button onClick={() => setIsOpen(!isOpen)} className="text-white">
        <Menu size={24} />
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="absolute top-full right-0 bg-black bg-opacity-90 p-4 rounded-lg shadow-lg"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
          >
            <div className="flex flex-col items-start space-y-4 whitespace-nowrap">
              <NavLink href="#waitlist" onClick={closeMenu}><span className='text-logoRed font-semibold'>Join Waitlist!</span></NavLink>
              <NavLink href="#about" onClick={closeMenu}>About</NavLink>
              <NavLink href="#how-it-works" onClick={closeMenu}>How It Works</NavLink>
              <NavLink href="#faq" onClick={closeMenu}>FAQ</NavLink>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const SlidingNavbar = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.querySelector('.hero-section');
      if (heroSection) {
        const heroMid = heroSection.offsetTop + heroSection.offsetHeight / 2;
        setIsVisible(window.pageYOffset > heroMid);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.nav
          className="fixed top-0 left-0 right-0 bg-black bg-opacity-90 z-50"
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          exit={{ y: -100 }}
          transition={{ duration: 0.3 }}
        >
          <div className="max-w-6xl mx-auto px-4 py-4 flex items-center justify-between">
            <img src="/images/logo-text.png" alt="PDFTrainer" className="h-4 w-auto" />
            <div className="hidden md:flex justify-center flex-grow items-center">
              <NavLink href="#about">About</NavLink>
              <NavLink href="#how-it-works">How It Works</NavLink>
              <NavLink href="#waitlist">Waitlist</NavLink>
              <NavLink href="#faq">FAQ</NavLink>
              <InstagramIcon />
            </div>
            <div className="md:hidden flex items-center">
              <InstagramIcon />
              <BurgerMenu />
            </div>
          </div>
        </motion.nav>
      )}
    </AnimatePresence>
  );
};



const HeroSection = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleGetStarted = () => {
    if (user) {
      navigate('/workspace');
    } else {
      navigate('/login');
    }
  };
  return (
    <>
      <SlidingNavbar />
      <section className="hero-section md:min-h-screen min-h-vh100 flex flex-col bg-cover bg-center text-white relative font-inter" style={{backgroundImage: "url('/images/hero-bg.jpg')"}}>
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative z-10 flex flex-col h-full max-w-6xl mx-auto px-4">
          <div className="flex items-center justify-between py-3 relative">
            <img src="/images/logo-text.png" alt="PDFTrainer" className="h-4 w-auto" />
            <nav className="hidden md:flex flex-grow items-center justify-center">
              <div className="flex justify-center font-semibold font-inter">
                <NavLink href="#about">About</NavLink>
                <NavLink href="#how-it-works">How It Works</NavLink>
                <NavLink href="#waitlist">Waitlist</NavLink>
                <NavLink href="#faq">FAQ</NavLink>
              </div>
              <InstagramIcon />
            </nav>
            <div className="md:hidden flex items-center">
              <InstagramIcon />
              <BurgerMenu />
            </div>
          </div>
          <div className="flex-grow flex flex-col justify-center items-center md:pt-8 pt-2 md:pb-0 pb-2">
            <img src="/images/logoblack3.png" alt="PDFTrainer Logo" className="w-84 md:w-1/2 mb-4 md:mb-4" />
            <div className="max-w-5xl px-6 text-center">
              <motion.h1
                className="text-3xl md:text-5xl mb-6 md:mb-6 font-semibold"
                initial={{ opacity: 0, y: -100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
              >
              Your perfect workout plan ready in{' '}
                <span className="relative inline-block group">
                5 minutes
                  <span className="absolute bottom-0 left-0 w-full -z-10 h-2 bg-logoRed transform -skew-x-12 group-hover:skew-x-12 transition-transform duration-300"></span>
                </span>{' '}
              </motion.h1>
              <motion.p
                className="text-lg md:text-xl mb-8 md:mb-8 font-fira"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5, duration: 1 }}
              >
                Don't waste time and money. Start getting results today!
              </motion.p>
              <motion.div
                className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-4 md:mt-12"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 1 }}
              >
                <button 
                onClick={handleGetStarted}
                className="w-full md:w-auto px-8 py-3 text-lg bg-logoRed text-white rounded-full hover:bg-red-700 transition-colors duration-300 font-semibold">
                  Get Started
                </button>
                {/* <button className="w-full md:w-auto px-8 py-3 text-lg bg-transparent text-white border-2 border-white rounded-full hover:bg-white hover:text-black transition-colors duration-300 font-semibold">
                  Watch Demo
                </button> */}
              </motion.div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSection;