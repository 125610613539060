import React from 'react';
import { Pen } from 'lucide-react';
import { useWorkout } from './WorkoutContext';

const WorkoutSummary = ({ onTabChange }) => {
  const { 
    workout, 
    workoutStrategy,
    setSelectedExerciseId,
    setCurrentDay,
    setSelectedOptions,
    selectedExerciseId
  } = useWorkout();

  const handleExerciseClick = (exercise, dayIndex, exerciseIndex) => {
    // Use _frontendId instead of id
    const exerciseKey = `${exercise._frontendId}-${dayIndex}-${exerciseIndex}`;
    setCurrentDay(dayIndex);
    setSelectedExerciseId(exerciseKey);
    
    setSelectedOptions({
      bodyPart: { name: exercise.muscleGroup || exercise.bodyPart },
      equipment: { name: exercise.equipment },
      exercise: {
        id: exercise.id,
        uniqueId: exercise._frontendId,  // Add this
        name: exercise.name || exercise.exerciseName,
        targetedMuscle: exercise.targetedMuscle,
        gifUrl: exercise.gifUrl,
        frameUrls: exercise.frameUrls,
        position: { dayIndex, exerciseIndex }
      },
      parameters: exercise.parameters
    });
  
    // Update the querySelector to use the same format as WorkoutPlan
    setTimeout(() => {
      const element = document.querySelector(`[data-exercise-id="${exercise._frontendId}-${dayIndex}-${exerciseIndex}"]`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 100);
  };

  const handleEditClick = (e, exercise, dayIndex, exerciseIndex) => {
    e.stopPropagation();
    handleExerciseClick(exercise, dayIndex, exerciseIndex);
    onTabChange('builder');
  };

  const getTotalSets = (day) => (
    day.reduce((total, exercise) => total + (parseInt(exercise.parameters?.sets) || 0), 0)
  );

  const ExerciseCard = ({ exercise, dayIndex, exerciseIndex }) => {
    const isSelected = selectedExerciseId === `${exercise.id}-${dayIndex}-${exerciseIndex}`;

    return (
      <div 
        className={`flex items-center gap-2 mb-3 p-2 rounded cursor-pointer group relative
          hover:bg-gray-100 transition-all duration-200
          ${isSelected ? 'ring-2 ring-blue-500 bg-gray-50' : ''}
        `}
        onClick={() => handleExerciseClick(exercise, dayIndex, exerciseIndex)}
      >
        <img 
          src={exercise.gifUrl} 
          alt={exercise.name} 
          className="w-12 h-12 rounded object-cover"
        />
        <div className="min-w-0 flex-grow">
          <p className="font-medium truncate">{exercise.name}</p>
          <p className="text-sm text-gray-600">
            {exercise.parameters?.sets || 0} x {exercise.parameters?.reps || 0} | {exercise.parameters?.rest || 0}s
          </p>
        </div>
        <button 
          onClick={(e) => handleEditClick(e, exercise, dayIndex, exerciseIndex)}
          className="md:opacity-0 md:group-hover:opacity-100 transition-opacity p-2 hover:bg-gray-200 rounded-full"
          aria-label="Edit exercise"
        >
          <Pen size={16} className="text-gray-600" />
        </button>
      </div>
    );
  };

  return (
    <div className="w-4/5 sm:w-full h-full overflow-y-auto p-4">
      <div className="text-center mb-6">
        <h2 className="text-2xl font-bold mb-2">
          {workoutStrategy?.workoutPlan?.name || "My Workout"}
        </h2>
        <p className="text-lg">{workout.length} days</p>
      </div>
      
      {workoutStrategy?.workoutPlan?.overallStrategy && (
        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-2 text-center">Plan Strategy</h3>
          <p className="text-gray-700 text-center px-4">
            {workoutStrategy.workoutPlan.overallStrategy}
          </p>
        </div>
      )}

      {workout.map((day, dayIndex) => (
        <div key={dayIndex} className="mb-6">
          <div className="text-center mb-4">
            <h3 className="text-xl font-bold mb-1">Day {dayIndex + 1}</h3>
            <p className="text-gray-600">
              {getTotalSets(day)} sets - {workoutStrategy?.workoutPlan?.days?.[`day${dayIndex + 1}`]?.strategy || 'Workout'}
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {day.map((exercise, exerciseIndex) => (
              <ExerciseCard
                key={exercise.id}
                exercise={exercise}
                dayIndex={dayIndex}
                exerciseIndex={exerciseIndex}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default WorkoutSummary;