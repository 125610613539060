import React, { useState, useEffect, useRef } from 'react';
import { Activity, Plus, X } from 'lucide-react';
import { useWorkout } from './WorkoutContext';
import SelectionContent from './SelectionContent';
import ParameterSelectionContent from './ParameterSelectionContent';
import SearchExercise from './SearchExercise';



// Import SVG icons
import { ReactComponent as AbsIcon } from '../../assets/icons/abs.svg';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import { ReactComponent as ChestIcon } from '../../assets/icons/chest.svg';
import { ReactComponent as ArmIcon } from '../../assets/icons/arm.svg';
import { ReactComponent as LegIcon } from '../../assets/icons/leg.svg';
import { ReactComponent as NeckIcon } from '../../assets/icons/neck.svg';
import { ReactComponent as ShoulderIcon } from '../../assets/icons/shoulder.svg';
import { ReactComponent as CardioIcon } from '../../assets/icons/cardio.svg';
import { ReactComponent as BodyweightIcon } from '../../assets/icons/bodyweight.svg';
import { ReactComponent as DumbbellIcon } from '../../assets/icons/dumbbell.svg';
import { ReactComponent as BarbellIcon } from '../../assets/icons/barbell.svg';
import { ReactComponent as MachineIcon } from '../../assets/icons/machine.svg';
import { ReactComponent as CableIcon } from '../../assets/icons/cable.svg';
import { ReactComponent as KettlebellIcon } from '../../assets/icons/kettlebell.svg';
import { ReactComponent as BandIcon } from '../../assets/icons/bands.svg';
import { ReactComponent as GlutesIcon } from '../../assets/icons/glutes.svg'

const BuilderMenu = () => {
  const { 
    selectedOptions, 
    setSelectedOptions, 
    availableExercises,
    setAvailableExercises, 
    isLoading, 
    isLoadingMore,
    totalExercises, 
    addExercise, 
    loadMoreExercises,
    currentDay,
    fetchAvailableExercises,
    updateExercise,
    startEditingExercise,
    cancelEditingExercise,
    workout,                   
    selectedExerciseId,       
    updateExerciseInWorkout,
    setWorkout   
  } = useWorkout();
  
  const triedCombinationsRef = useRef(new Set());
  const [lockedOptions ] = useState({
    bodyPart: false,
    equipment: false,
    exercise: false,
    parameters: false
  });
  const [currentStep, setCurrentStep] = useState('main');

  const bodyParts = [
    { name: "Back", icon: () => <BackIcon width={72} height={72} />, exerciseCount: 152 },
    { name: "Cardio", icon: () => <CardioIcon width={72} height={72} />, exerciseCount: 31 },
    { name: "Chest", icon: () => <ChestIcon width={72} height={72} />, exerciseCount: 119 },
    { name: "Upper Arms", icon: () => <ArmIcon width={72} height={72} />, exerciseCount: 154 },
    { name: "Forearms", icon: () => <ArmIcon width={72} height={72} />, exerciseCount: 40 },
    { name: "Upper Leg", icon: () => <LegIcon width={72} height={72} />, exerciseCount: 102 },
    { name: "Calves", icon: () => <LegIcon width={72} height={72} />, exerciseCount: 40 },
    { name: "Shoulders", icon: () => <ShoulderIcon width={72} height={72} />, exerciseCount: 77 },
    { name: "Abs", icon: () => <AbsIcon width={72} height={72} />, exerciseCount: 150 },
    { name: "Glutes", icon: () => <GlutesIcon width={72} height={72} />, exerciseCount: 81 },
    { name: "Neck", icon: () => <NeckIcon width={72} height={72} />, exerciseCount: 10 }
  ];

  const equipmentList = [
    { name: "Body weight", icon: () => <BodyweightIcon width={72} height={72} /> },
    { name: "Dumbbell", icon: () => <DumbbellIcon width={72} height={72} /> },
    { name: "Barbell", icon: () => <BarbellIcon width={72} height={72} /> },
    { name: "Machine", icon: () => <MachineIcon width={72} height={72} /> },
    { name: "Cable", icon: () => <CableIcon width={72} height={72} /> },
    { name: "Kettlebell", icon: () => <KettlebellIcon width={72} height={72} /> },
    { name: "Band", icon: () => <BandIcon width={72} height={72} /> },
  ];

  const steps = [
    { 
      title: 'Select body part', 
      key: 'bodyPart', 
      icon: () => {
        const BodyPartIcon = bodyParts.find(
          part => part.name === selectedOptions.bodyPart?.name
        )?.icon || (() => <Activity size={72} />);
        return <BodyPartIcon />;
      }
    },
    { 
      title: 'Select equipment', 
      key: 'equipment', 
      icon: () => <DumbbellIcon width={72} height={72} /> 
    },
    { 
      title: 'Select exercise', 
      key: 'exercise', 
      icon: () => <Activity size={72} /> 
    }
  ];


  useEffect(() => {
    const shouldFetchExercises = 
      currentStep === 'exercise' && 
      selectedOptions.bodyPart && 
      selectedOptions.equipment && 
      !isLoading;
  
    if (shouldFetchExercises) {
      const exerciseKey = `${selectedOptions.bodyPart.name}-${selectedOptions.equipment.name}`;
      
      // Only fetch if we haven't tried this combination before
      if (!triedCombinationsRef.current.has(exerciseKey)) {
        triedCombinationsRef.current.add(exerciseKey);
        fetchAvailableExercises(selectedOptions.bodyPart.name, selectedOptions.equipment.name);
      }
    }
  }, [currentStep, selectedOptions.bodyPart, selectedOptions.equipment, isLoading, fetchAvailableExercises]);

  const isStepDisabled = (stepKey) => {
    switch (stepKey) {
      case 'bodyPart':
        return false;
      case 'equipment':
        return !selectedOptions.bodyPart;
      case 'exercise':
        return !selectedOptions.bodyPart || !selectedOptions.equipment || isLoading;
      case 'parameters':
        return !selectedOptions.exercise;
      default:
        return false;
    }
  };

  const handleStepClick = (step) => {
    if (lockedOptions[step.key] || isStepDisabled(step.key)) return;
    setCurrentStep(step.key);
  };


  const handleOptionSelect = (option) => {
    setSelectedOptions(prev => ({
      ...prev,
      [currentStep]: option
    }));
    
    if (currentStep === 'bodyPart') {
      setCurrentStep('equipment');
      setAvailableExercises([]);
      triedCombinationsRef.current.clear();
    } else if (currentStep === 'equipment') {
      setCurrentStep('exercise');
    } else {
      setCurrentStep('main');
    }
  };

  const handleParameterSubmit = (params) => {
    setSelectedOptions(prev => ({
      ...prev,
      parameters: params
    }));
    setCurrentStep('main');
  };

  const handleAddOrUpdateExercise = (params = selectedOptions.parameters) => {
    const exerciseData = {
      id: selectedOptions.exercise.id,
      exerciseName: selectedOptions.exercise.name,
      name: selectedOptions.exercise.name,
      muscleGroup: selectedOptions.bodyPart.name,
      equipment: selectedOptions.equipment.name,
      targetedMuscle: selectedOptions.exercise.targetedMuscle,
      gifUrl: selectedOptions.exercise.gifUrl,
      frameUrls: selectedOptions.exercise.frameUrls,
      parameters: params
    };
  
    // Clear selections before adding/updating
    const tempExerciseData = { ...exerciseData };
    handleClearAllSelections();
    
    // Use setTimeout to ensure state updates have completed
    setTimeout(() => {
      // Find the selected exercise's position in the workout
      const selectedExercisePosition = workout.reduce((position, day, dayIndex) => {
        const exerciseIndex = day.findIndex(ex => ex._frontendId === selectedExerciseId);
        if (exerciseIndex !== -1) {
          return { dayIndex, exerciseIndex };
        }
        return position;
      }, null);
  
      if (selectedExercisePosition) {
        // Update existing exercise
        updateExerciseInWorkout(tempExerciseData, selectedExercisePosition.dayIndex, selectedExercisePosition.exerciseIndex);
      } else {
        // Add as new exercise
        addExercise(tempExerciseData, currentDay);
      }
      
      setCurrentStep('main');
    }, 50);
  };

  const handleClearAllSelections = () => {
    setSelectedOptions({
      bodyPart: null,
      equipment: null,
      exercise: null,
      parameters: null
    });
    setAvailableExercises([]);
    triedCombinationsRef.current.clear();
    if (startEditingExercise) {
      cancelEditingExercise();
    }
  };
  
  const renderSelectionContent = () => {
    switch (currentStep) {
      case 'bodyPart':
        return (
          <SelectionContent
            title="Select Body Part"
            options={bodyParts}
            onSelect={handleOptionSelect}
            onBack={() => setCurrentStep('main')}
          />
        );
      case 'equipment':
        return (
          <SelectionContent
            title="Select Equipment"
            options={equipmentList}
            onSelect={handleOptionSelect}
            onBack={() => setCurrentStep('main')}
          />
        );
      case 'exercise':
        return (
          <SelectionContent
            title="Select Exercise"
            options={availableExercises}
            onSelect={handleOptionSelect}
            onLoadMore={loadMoreExercises}
            hasMore={availableExercises.length < totalExercises}
            isLoading={isLoading}
            isLoadingMore={isLoadingMore}
            onBack={() => setCurrentStep('main')}
          />
        );
      case 'parameters':
        return (
          <ParameterSelectionContent
            onSelect={handleParameterSubmit}
            onBack={() => setCurrentStep('main')}
            onAddExercise={handleAddOrUpdateExercise}
            currentDay={currentDay}
            initialParams={selectedOptions.parameters}
            isEditMode={!!startEditingExercise}
          />
        );
      default:
        return null;
    }
  };

  const renderMainMenu = () => (
    <div className="flex flex-col w-full mx-auto p-2 lg:p-4">
      {/* Header */}
      <h2 className="text-lg lg:text-2xl font-bold text-center mb-2">
        {startEditingExercise ? 'Edit Exercise' : 'Build Your Workout'}
      </h2>
  
      {/* Search */}
      <div className="w-full sm:w-2/3 mb-4 item flex mx-auto">
        <SearchExercise 
          onSearch={(selectedExercise) => {
            setSelectedOptions(prev => ({
              ...prev,
              ...selectedExercise
            }));
          }}
        />
      </div>
  
      {/* Content Area - Reordered for mobile */}
      <div className="flex flex-col justify-center items-center gap-2 lg:gap-4">
        {/* Preview section */}
        <div className="w-full sm:w-[305px] flex items-start relative">
          {selectedOptions.bodyPart && selectedOptions.equipment && selectedOptions.exercise?.gifUrl ? (
            <div className="w-full flex flex-col items-center">
              <div className="relative w-full">
                <img 
                  src={selectedOptions.exercise.gifUrl} 
                  alt={selectedOptions.exercise.name}
                  className="flex mx-auto w-4/5 sm:w-full rounded-lg mb-2"
                />
                <button
                  onClick={handleClearAllSelections}
                  className="absolute top-2 right-2 p-1 rounded-full bg-red-500 hover:bg-red-600 text-white"
                >
                  <X size={16} />
                </button>
              </div>
              <div className="text-center">
                <h3 className="text-base lg:text-lg font-bold text-black mb-1">
                  {selectedOptions.exercise.name}
                </h3>
              </div>
            </div>
          ) : (
            <button 
              onClick={() => handleStepClick(steps[0])} 
              className="w-full h-[280px] rounded-lg border-2 border-dashed border-gray-300 flex flex-col items-center justify-center transition-colors hover:bg-gray-50"
            >
              <Plus size={40} className="text-gray-400 mb-2" />
              <span className="text-sm text-gray-500">
                Select New Exercise
              </span>
            </button>
          )}
        </div>
  
        {/* Steps buttons - Mobile optimized */}
        <div className="flex flex-row gap-2 lg:gap-4 w-full justify-center">
          {steps.map((step, index) => (
            <div key={index} className="flex flex-col items-center gap-1 lg:gap-2">
              <button
                onClick={() => handleStepClick(step)}
                disabled={isStepDisabled(step.key)}
                className={`
                  w-16 h-16 lg:w-24 lg:h-24
                  rounded-lg 
                  flex items-center justify-center
                  transition-colors 
                  duration-200
                  ${
                    selectedOptions[step.key] 
                      ? 'bg-blue-500 text-white' 
                      : isStepDisabled(step.key)
                        ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                        : 'bg-gray-200 text-gray-600 hover:bg-gray-300'
                  }
                `}
              >
                {step.key === 'exercise' && selectedOptions.exercise?.gifUrl ? (
                  <img 
                    src={selectedOptions.exercise.gifUrl}
                    alt={selectedOptions.exercise.name}
                    className="w-full h-full object-cover rounded-lg"
                  />
                ) : (
                  <div className="scale-50 lg:scale-75 transition-transform">
                    {selectedOptions[step.key] ? 
                      (selectedOptions[step.key].icon ? selectedOptions[step.key].icon() : step.icon())
                      : step.icon()
                    }
                  </div>
                )}
              </button>
              <span className="text-xs lg:text-sm text-gray-600 text-center w-16 lg:w-24 break-words">
                {selectedOptions[step.key]?.name || step.title.replace('Select ', '')}
              </span>
            </div>
          ))}
        </div>
      </div>

  
      <div className="mt-4 flex justify-center">
  {selectedOptions.exercise ? (
    (() => {
      if (!selectedExerciseId) {
        // If no exercise selected in workout plan, show Add button
        return (
          <button
  onClick={() => {
    const exerciseData = {
      id: selectedOptions.exercise.id,
      _frontendId: Date.now(),
      exerciseName: selectedOptions.exercise.name,
      name: selectedOptions.exercise.name,
      muscleGroup: selectedOptions.bodyPart.name,
      equipment: selectedOptions.equipment.name,
      targetedMuscle: selectedOptions.exercise.targetedMuscle,
      gifUrl: selectedOptions.exercise.gifUrl,
      frameUrls: selectedOptions.exercise.frameUrls,
      parameters: { sets: '3', reps: '10', rest: '60' }
    };
    
    // Clear selections first
    const tempExerciseData = { ...exerciseData };
    handleClearAllSelections();
    
    // Add exercise after a brief delay
    setTimeout(() => {
      addExercise(tempExerciseData, currentDay);
    }, 50);
  }}
  className="w-full p-3 bg-green-500 text-white rounded-lg hover:bg-green-600 font-semibold text-sm sm:text-base"
>
  Add to Day {currentDay + 1}
</button>
        );
      }


      // Split the ID safely
      const [exerciseId, dayIndex, exerciseIndex] = selectedExerciseId.split('-');
      
      // Validate the indexes exist
      if (!workout[dayIndex] || !workout[dayIndex][exerciseIndex]) {
        console.error('Invalid workout indices:', { dayIndex, exerciseIndex });
        return null;
      }

      const selectedWorkoutExercise = workout[dayIndex][exerciseIndex];
      const isSameExercise = selectedWorkoutExercise?.name === selectedOptions.exercise.name;

      return !isSameExercise ? (
        <div className="flex flex-col sm:flex-row w-full gap-2">
          <button
            onClick={() => {
              setWorkout(prev => {
                const newWorkout = [...prev];
                const existingParams = newWorkout[dayIndex][exerciseIndex]?.parameters;
                newWorkout[dayIndex][exerciseIndex] = {
                  id: selectedOptions.exercise.id,
                  _frontendId: Date.now(),
                  exerciseName: selectedOptions.exercise.name,
                  name: selectedOptions.exercise.name,
                  muscleGroup: selectedOptions.bodyPart.name,
                  equipment: selectedOptions.equipment.name,
                  targetedMuscle: selectedOptions.exercise.targetedMuscle,
                  gifUrl: selectedOptions.exercise.gifUrl,
                  frameUrls: selectedOptions.exercise.frameUrls,
                  parameters: existingParams
                };
                return newWorkout;
              });
              handleClearAllSelections();
            }}
            className="flex-1 p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 font-semibold text-sm sm:text-base"
          >
            Replace Exercise
          </button>
          <button
            onClick={() => {
              const exerciseData = {
                id: Date.now(),
                exerciseName: selectedOptions.exercise.name,
                name: selectedOptions.exercise.name,
                muscleGroup: selectedOptions.bodyPart.name,
                equipment: selectedOptions.equipment.name,
                targetedMuscle: selectedOptions.exercise.targetedMuscle,
                gifUrl: selectedOptions.exercise.gifUrl,
                frameUrls: selectedOptions.exercise.frameUrls,
                parameters: { sets: '3', reps: '10', rest: '60' }
              };
              addExercise(exerciseData, currentDay);
              handleClearAllSelections();
            }}
            className="flex-1 p-3 bg-green-500 text-white rounded-lg hover:bg-green-600 font-semibold text-sm sm:text-base"
          >
            Add to Day {currentDay + 1}
          </button>
        </div>
      ) : null;
    })()
  ) : null}
</div>
   </div>
 );
 return (
   <div className="w-4/5 sm:w-full h-full p-4 pt-8 lg:p-8">
     {currentStep === 'main' ? renderMainMenu() : renderSelectionContent()}
   </div>
 );
};

export default BuilderMenu;