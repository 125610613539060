import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useTheme } from '../contexts/ThemeContext';
import { useUserProfile } from '../contexts/UserProfileContext';
import {  UserCircle, Palette, Star, Loader, Coins } from 'lucide-react';

const Navbar = () => {
  const { user, signOut, email } = useAuth();
  const { currentTheme, changeTheme, themes } = useTheme();
  const { userProfile, updateUserProfile, isLoading } = useUserProfile();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isThemeDropdownOpen, setIsThemeDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const themeDropdownRef = useRef(null);
  const themeUpdateTimeoutRef = useRef(null);
  const lastSelectedThemeRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
      if (themeDropdownRef.current && !themeDropdownRef.current.contains(event.target)) {
        setIsThemeDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (user && userProfile && !lastSelectedThemeRef.current) {
      if (userProfile.theme && themes.some(theme => theme.name === userProfile.theme)) {
        changeTheme(userProfile.theme);
        lastSelectedThemeRef.current = userProfile.theme;
      }
    }
  }, [user, userProfile, changeTheme, themes]);

  const debouncedUpdateTheme = useCallback((themeName) => {
    if (themeUpdateTimeoutRef.current) {
      clearTimeout(themeUpdateTimeoutRef.current);
    }

    themeUpdateTimeoutRef.current = setTimeout(async () => {
      try {
        await updateUserProfile({ theme: themeName });
      } catch (error) {
        console.error('Error updating theme in user profile:', error);
      }
    }, 5 * 60 * 1000); // 5 minutes
  }, [updateUserProfile]);

  useEffect(() => {
    return () => {
      if (themeUpdateTimeoutRef.current) {
        clearTimeout(themeUpdateTimeoutRef.current);
      }
    };
  }, []);

  const handleThemeChange = (themeName) => {
    changeTheme(themeName);
    lastSelectedThemeRef.current = themeName;
    setIsThemeDropdownOpen(false);
    debouncedUpdateTheme(themeName);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleThemeDropdown = () => {
    setIsThemeDropdownOpen(!isThemeDropdownOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut();
      setIsDropdownOpen(false);
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const renderCreditsAndTierBadge = () => {
    const credits = userProfile?.credits !== undefined ? userProfile.credits : 0;
    const tier = userProfile?.tier || 'free';
  
    const creditDisplay = (
      <div className="flex items-center mr-2">
        <span className="text-sm font-medium text-yellow-400 mr-1">{credits}</span>
      </div>
    );
  
    switch (tier) {
      case 'pro':
        return (
          <>
            {creditDisplay}
            <Star size={24} fill="#FBBF24" stroke="#FBBF24" className="mr-4" />
          </>
        );
      case 'enterprise':
        return (
          <>
            {creditDisplay}
            <img src="/images/diamond.svg" alt="Enterprise" className="w-6 h-6 mr-4" />
          </>
        );
      default: // free tier
        return (
          <>
            {creditDisplay}
            <Coins size={24} className="text-yellow-400 mr-4" />
          </>
        );
    }
  };

  const handleLinkClick = () => {
    setIsDropdownOpen(false);
  };

  return (
    <nav className="bg-black text-white shadow-lg sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
          <Link to="/" className="flex-shrink-0 flex flex-row">
  <img src="/images/logo-text.png" alt="Logo" className="sm:h-8 h-6" />
</Link>
            <div className="ml-10 flex items-center space-x-4">
            {user && (
  <Link 
    to="/workspace" 
    className={`hidden md:block btn-special bg-logoRed px-4 py-2 text-sm font-medium ${
      isLoading ? 'opacity-50 cursor-not-allowed pointer-events-none' : ''
    }`}
  >
    {isLoading ? (
      <div className="flex items-center">
        <Loader className="animate-spin mr-2" size={16} />
        Loading...
      </div>
    ) : (
      'Get Started'
    )}
  </Link>
)}
            </div>
          </div>
          <div className="flex items-center">
            <div className="relative" ref={dropdownRef}>
              {user ? (
                <>
                  <div className="flex items-center">
                    {renderCreditsAndTierBadge()}
                    <button onClick={toggleDropdown} className="flex items-center focus:outline-none" aria-label="User menu">
                      <UserCircle size={24} />
                    </button>
                  </div>
                  {isDropdownOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                      <div className="px-4 py-2 text-xs font-bold text-gray-600 border-b border-gray-200 truncate">
                        {email}
                      </div>
                      {isLoading ? (
                        <div className="px-4 py-2 text-sm text-gray-700 flex items-center">
                          <Loader className="animate-spin mr-2" size={16} />
                          Loading...
                        </div>
                      ) : (
                        <>
<Link onClick={handleLinkClick} 
  to="/workspace" 
  className="block mx-4 my-2 btn-special bg-logoRed px-4 py-2 text-sm font-medium text-white text-center rounded hover:opacity-90"
>
  Get Started
</Link>
                          <Link onClick={handleLinkClick} to="/profile" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-black">Profile</Link>
                          <Link onClick={handleLinkClick} to="/account" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-black">Account</Link>
                          <Link onClick={handleLinkClick} to="/dashboard" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-black">My Workouts</Link>
                          <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100 hover:text-black">Logout</button>
                        </>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <Link to="/login" className="btn-primary px-3 py-2 rounded-md text-sm font-medium">Login</Link>
              )}
            </div>
            <div className="relative ml-4" ref={themeDropdownRef}>
              <button
                onClick={toggleThemeDropdown}
                className="p-2 rounded-full hover:bg-gray-700 focus:outline-none"
                aria-label="Change theme"
              >
                <Palette size={24} />
              </button>
              {isThemeDropdownOpen && (
                <div className="absolute right-0 mt-2 bg-transparent rounded-md py-1 z-10" style={{ marginRight: '-0.5rem' }}>
                  <div className="flex flex-col gap-2 p-2">
                    {themes.map((theme) => (
                      <button
                        key={theme.name}
                        onClick={() => handleThemeChange(theme.name)}
                        className={`w-10 h-10 rounded-full flex items-center justify-center ${
                          currentTheme === theme.name ? `bg-white` : 'bg-white opacity-50 '
                        } hover:bg-gray-100 hover:opacity-100 opacity-100 focus:outline-none  focus: opacity-100 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 `}
                        title={theme.name}
                      >
                        <span className="text-2xl">{theme.emoji}</span>
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;