// userHandler.js
import axios from 'axios';
import { CognitoUserPool, CognitoRefreshToken } from 'amazon-cognito-identity-js';

const API_BASE_URL =  'https://d120ovw29hasnn.cloudfront.net/user'//'https://iucpms4k452nme2x37bbjqiury0dwuab.lambda-url.us-east-1.on.aws';

const poolData = {
  UserPoolId: 'us-east-1_bAQ2VWDUK',
  ClientId: '1ktl88fh2jcn0m8infb6eilmh'
};

const userPool = new CognitoUserPool(poolData);

// Token management functions
const getToken = (tokenType) => {
  const keys = Object.keys(localStorage);
  const tokenKey = keys.find(key => 
    key.startsWith('CognitoIdentityServiceProvider') && 
    key.endsWith(tokenType)
  );
  return tokenKey ? localStorage.getItem(tokenKey) : null;
};

const getJwtToken = () => getToken('accessToken');
const getRefreshToken = () => getToken('refreshToken');

const isTokenExpired = (token) => {
  if (!token) return true;
  const expiry = JSON.parse(atob(token.split('.')[1])).exp;
  return Math.floor(new Date().getTime() / 1000) >= expiry;
};

const refreshToken = async () => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          const refreshToken = new CognitoRefreshToken({RefreshToken: getRefreshToken()});
          cognitoUser.refreshSession(refreshToken, (err, session) => {
            if (err) {
              reject(err);
            } else {
              const newAccessToken = session.getAccessToken().getJwtToken();
              const accessTokenKey = Object.keys(localStorage).find(key => 
                key.startsWith('CognitoIdentityServiceProvider') && 
                key.endsWith('accessToken')
              );
              if (accessTokenKey) {
                localStorage.setItem(accessTokenKey, newAccessToken);
              }
              resolve(newAccessToken);
            }
          });
        }
      });
    } else {
      reject(new Error('No current user'));
    }
  });
};

// API request wrapper with token handling
const apiRequest = async (endpoint, method = 'GET', body = null) => {
  let token = getJwtToken();
  
  if (!token || isTokenExpired(token)) {
    try {
      token = await refreshToken();
    } catch (error) {
      console.error('Error refreshing token:', error);
      throw new Error('Session expired. Please log in again.');
    }
  }

  const config = {
    method,
    url: `${API_BASE_URL}${endpoint}`,
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    withCredentials: false,  // Don't include credentials
    ...(body && { data: body })
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    if (error.response?.status === 404) {
      return { exists: false };
    }
    throw error;
  }
};

// User handler methods
const userHandler = {
  async upsertUser(email, userName) {
    try {
      return await apiRequest('/users/upsert', 'POST', { email, userName });
    } catch (error) {
      console.error('Error upserting user:', error);
      throw error;
    }
  },

  async getUser(email) {
    try {
      return await apiRequest(`/users/${encodeURIComponent(email)}`);
    } catch (error) {
      if (error.response?.status === 404) {
        return { exists: false };
      }
      throw error;
    }
  },

  async updateUser(email, updateData, suppressNotification = false) {
    try {
      const response = await apiRequest(
        `/users/${encodeURIComponent(email)}`, 
        'PUT', 
        updateData
      );
      
      if (response.success) {
        const updatedAttributes = Object.keys(updateData).join(', ');
        return {
          success: true,
          user: response.user,
          message: suppressNotification ? null : `${updatedAttributes} has been updated`,
          type: suppressNotification ? null : 'success'
        };
      }
      return {
        success: false,
        error: response.error || 'Failed to update user',
        type: suppressNotification ? null : 'error'
      };
    } catch (error) {
      console.error('Error updating user:', error);
      return {
        success: false,
        error: error.response?.data?.error || 'An error occurred while updating the user.',
        type: suppressNotification ? null : 'error'
      };
    }
  },

  async getTiers() {
    try {
      const tiers = await apiRequest('/tiers');
  
      return tiers;
    } catch (error) {
      console.error('Error fetching tiers:', error);
      throw error;
    }
  },

  async createCheckoutSession(email, priceId) {
    try {
      return await apiRequest('/create-checkout-session', 'POST', { email, priceId });
    } catch (error) {
      console.error('Error creating checkout session:', error);
      throw error;
    }
  },

  async verifyUnsubscribeToken(token) {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/verify-unsubscribe-token`, 
        { token },
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization, x-cf-signature, x-cf-timestamp, x-cf-distribution-id'
          },
          withCredentials: true
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error verifying unsubscribe token:', error);
      throw error;
    }
},

  async getChatMessages(userEmail) {
    try {
      return await apiRequest(`/chat/messages?userEmail=${encodeURIComponent(userEmail)}`);
    } catch (error) {
      console.error('Error fetching chat messages:', error);
      throw error;
    }
  },

  async sendChatMessage(userEmail, message) {
    try {
      return await apiRequest('/chat/send', 'POST', {
        userEmail,
        message
      });
    } catch (error) {
      console.error('Error sending chat message:', error);
      throw error;
    }
  }



  
};

export default userHandler;