import { CognitoUserPool, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { prepareWorkoutForSave } from '../../pages/workspace/WorkoutUtils';
import axios from 'axios';




const API_BASE_URL = 'https://icjqq4a6bk4djuiisn3zfokqly0gfgco.lambda-url.us-east-1.on.aws';
const API_USER_URL = 'https://d120ovw29hasnn.cloudfront.net/user'
const API_WORKOUTGENERATOR_URL = 'https://hbj5ga5fkxjg25h2mo2f43kypm0cjbok.lambda-url.us-east-1.on.aws'

const poolData = {
  UserPoolId: 'us-east-1_bAQ2VWDUK',
  ClientId: '1ktl88fh2jcn0m8infb6eilmh'
};

const userPool = new CognitoUserPool(poolData);

const getToken = (tokenType) => {
  const keys = Object.keys(localStorage);
  const tokenKey = keys.find(key => 
    key.startsWith('CognitoIdentityServiceProvider') && 
    key.endsWith(tokenType)
  );
  return tokenKey ? localStorage.getItem(tokenKey) : null;
};

const getJwtToken = () => getToken('accessToken');
const getRefreshToken = () => getToken('refreshToken');

const isTokenExpired = (token) => {
  if (!token) return true;
  const expiry = JSON.parse(atob(token.split('.')[1])).exp;
  return Math.floor(new Date().getTime() / 1000) >= expiry;
};

const refreshToken = async () => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          const refreshToken = new CognitoRefreshToken({RefreshToken: getRefreshToken()});
          cognitoUser.refreshSession(refreshToken, (err, session) => {
            if (err) {
              reject(err);
            } else {
              const newAccessToken = session.getAccessToken().getJwtToken();
              // Update the access token in localStorage
              const accessTokenKey = Object.keys(localStorage).find(key => 
                key.startsWith('CognitoIdentityServiceProvider') && 
                key.endsWith('accessToken')
              );
              if (accessTokenKey) {
                localStorage.setItem(accessTokenKey, newAccessToken);
              }
              resolve(newAccessToken);
            }
          });
        }
      });
    } else {
      reject(new Error('No current user'));
    }
  });
};

const apiRequest = async (endpoint, method = 'GET', body = null, isBlob = false, baseUrl = API_BASE_URL) => {
  let token = getJwtToken();
  
  if (!token || isTokenExpired(token)) {
    try {
      token = await refreshToken();
    } catch (error) {
      console.error('Error refreshing token:', error);
      throw new Error('Session expired. Please log in again.');
    }
  }

  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json'
  };

  const config = {
    method,
    headers,
    body: body ? JSON.stringify(body) : null
  };
  
  const response = await fetch(`${baseUrl}${endpoint}`, config);
  
  if (!response.ok) {
    const errorText = await response.text();
    console.error('API Error:', errorText);
    throw new Error(`API request failed: ${errorText}`);
  }

  return isBlob ? response.blob() : response.json();
};

export const searchExercises = async (searchTerm) => {
  if (!searchTerm) return { exercises: [] };

  try {
    const data = await apiRequest(`/search-exercises?term=${encodeURIComponent(searchTerm)}`);
    const mappedExercises = data.exercises.map(exercise => ({
      id: exercise.id,
      name: exercise.exerciseName,
      exerciseName: exercise.exerciseName,
      bodyPart: exercise.muscleGroup,
      equipment: exercise.equipment,
      targetedMuscle: exercise.targetedMuscle,
      gifUrl: exercise.gifUrl,
      frameUrls: exercise.frameUrls
    }));


    return {
      exercises: mappedExercises,
      totalResults: data.totalResults
    };
  } catch (error) {
    console.error('Error searching exercises:', error);
    throw error;
  }
};

export const fetchExercises = async (muscleGroup, equipment, page = 1, pageSize = 20) => {
  const queryParams = new URLSearchParams({
    muscleGroup: typeof muscleGroup === 'string' ? muscleGroup : muscleGroup.name,
    ...(equipment && { equipment: typeof equipment === 'string' ? equipment : equipment.name }),
    page: page.toString(),
    pageSize: pageSize.toString()
  });


  try {
    const data = await apiRequest(`/get-exercises?${queryParams}`);
    
    // Check if there are no exercises in the first page
    if (page === 1 && (!data.exercises || data.exercises.length === 0)) {
      throw new Error('NO_EXERCISES_AVAILABLE');
    }
    
    const mappedExercises = data.exercises.map(exercise => ({
      id: exercise.id,
      name: exercise.exerciseName,
      exerciseName: exercise.exerciseName,
      bodyPart: exercise.muscleGroup,
      equipment: exercise.equipment,
      targetedMuscle: exercise.targetedMuscle,
      gifUrl: exercise.gifUrl,
      frameUrls: exercise.frameUrls
    }));

    
    
    
    return {
      exercises: mappedExercises,
      totalExercises: data.totalExercises,
      paginationInfo: data.paginationInfo
    };
  } catch (error) {
    if (error.message === 'NO_EXERCISES_AVAILABLE') {
      return {
        exercises: [],
        totalExercises: 0,
        paginationInfo: { hasMore: false },
        message: 'No exercises available, please review your selection.'
      };
    }
    console.error('Error fetching exercises:', error);
    throw error;
  }
};

export const saveWorkout = async (email, workoutData, workoutStrategy = null, options = {}) => {
  try {
    const preparedData = prepareWorkoutForSave(email, workoutData, workoutStrategy, options);
    const response = await apiRequest('/save-workout', 'POST', preparedData);
    return response;
  } catch (error) {
    console.error('Error saving workout:', error);
    throw error;
  }
};

export const getWorkouts = async (email) => {
  try {
    const lowercaseEmail = email.toLowerCase();
    const response = await apiRequest(`/get-workouts?email=${encodeURIComponent(lowercaseEmail)}`);
    return response;
  } catch (error) {
    console.error('Error fetching workouts:', error);
    throw error;
  }
};

export const deleteWorkout = async (email, workoutId) => {
  try {
    if (!email || !workoutId) {
      throw new Error('email and workoutId are required');
    }
    
    // Send as query parameters since the Lambda is expecting them
    const queryParams = new URLSearchParams({ 
      userId: email.toLowerCase(),  // Changed from email to userId to match Lambda
      workoutId: workoutId 
    });
    
    const response = await apiRequest(`/delete-workout?${queryParams}`, 'DELETE');
    return response;
  } catch (error) {
    console.error('Error deleting workout:', error);
    throw error;
  }
};

export const updateWorkout = async (email, workoutId, updatedWorkout) => {
  try {
    // Keep a minimal update structure - only what we want to update
    const requestBody = {
      userId: email,
      workoutId: workoutId,
      fieldToUpdate: 'name',  // Changed from 'field' to be more descriptive
      newValue: updatedWorkout.name  // Changed from 'value' to be more descriptive
    };

    const response = await apiRequest('/update-workout', 'POST', requestBody);
    return response;
  } catch (error) {
    console.error('Error updating workout:', error);
    throw error;
  }
};

export const getWorkoutHistory = async (email) => {
  try {
    const response = await apiRequest(`/workout-history?email=${encodeURIComponent(email)}`);
    return response;
  } catch (error) {
    console.error('Error fetching workout history:', error);
    throw error;
  }
};



export const getProxiedImage = async (key) => {
  const queryParams = new URLSearchParams({
    key: key
  });


  try {
    const blob = await apiRequest(`/get-image?${queryParams}`, 'GET', null, true);
    
    if (!(blob instanceof Blob)) {
      throw new Error('Response is not a Blob');
    }

    return URL.createObjectURL(blob);
  } catch (error) {
    console.error('Error fetching proxied image:', error);
    throw error;
  }
};


export const getCloudFrontSignedUrl = async (key) => {
  const queryParams = new URLSearchParams({
    key: key
  });


  try {
    const data = await apiRequest(`/get-cloudfront-url?${queryParams}`);

    if (!data.signedUrl) {
      throw new Error('No signedUrl in response');
    }

    return data.signedUrl;
  } catch (error) {
    console.error('Error getting CloudFront signed URL:', error);
    throw error;
  }
};

export const fetchExercisesByIds = async (exerciseIds) => {
  if (!exerciseIds || exerciseIds.length === 0) {
    console.error('No exercise IDs provided');
    return [];
  }


  try {
    const data = await apiRequest('/fetch-exercises-by-ids', 'POST', { exerciseIds });
    
    if (!data || !data.exercises) {
      console.error('Invalid response from API:', data);
      return [];
    }
    
    return data.exercises.map(exercise => ({
      id: exercise.id,
      name: exercise.exerciseName,
      exerciseName: exercise.exerciseName,
      bodyPart: exercise.muscleGroup,
      equipment: exercise.equipment,
      targetedMuscle: exercise.targetedMuscle,
      gifUrl: exercise.gifUrl,
      frameUrls: exercise.frameUrls,
      exerciseId: exercise.id
    }));
  } catch (error) {
    console.error('Error fetching exercises by IDs:', error);
    return [];
  }
};

export const generateWorkoutPlan = async (formData) => {
  console.log('form data:', formData)
  try {
    const response = await apiRequest(
      '/generate-workout', 
      'POST', 
      {
        userProfile: {
          name: formData.name,
          gender: formData.gender,
          ageGroup: formData.ageGroup,
          experienceLevel: formData.experienceLevel,
          limitations: formData.limitations || null,
          email: formData.email
        },
        workoutPreferences: {
          objective: formData.objective,
          focusAreas: formData.focusAreas,
          daysPerWeek: formData.daysPerWeek,
          timePerSession: formData.timePerSession,
          equipment: formData.equipment,
          additionalNotes: formData.additionalNotes,
          originalAdditionalNotes: formData.originalAdditionalNotes,
          previousWorkoutStrategy: formData.previousWorkoutStrategy,
          split: formData.split
        }
      },
      false, // isBlob
      API_WORKOUTGENERATOR_URL // using the workout generator URL
    );
    
    return response;
  } catch (error) {
    console.error('Error generating workout plan:', error);
    throw error;
  }
};  


export const generateFinalWorkout = async (workoutStrategy, formData) => {
  try {
    // Create a cleaned version of workoutStrategy without context
    const cleanedWorkoutStrategy = {
      ...workoutStrategy,
      context: undefined, // Remove context explicitly
      workoutPlan: {
        ...workoutStrategy.workoutPlan,
        context: undefined, // Remove context from workoutPlan if it exists
        notes: workoutStrategy.notes || {
          food: workoutStrategy.food || '',
          weights: workoutStrategy.weights || '',
          repsAndSets: workoutStrategy.repsAndSets || ''
        }
      },
      notes: workoutStrategy.notes || {
        food: workoutStrategy.food || '',
        weights: workoutStrategy.weights || '',
        repsAndSets: workoutStrategy.repsAndSets || ''
      }
    };

    const requestBody = {
      workoutStrategy: cleanedWorkoutStrategy,
      userProfile: {
        name: formData.name,
        gender: formData.gender,
        ageGroup: formData.ageGroup,
        experienceLevel: formData.experienceLevel,
        limitations: formData.limitations || null,
        equipment: formData.equipment,
        timePerSession: formData.timePerSession,
        additionalNotes: formData.additionalNotes,
        email: formData.email
      },
    };

    
    // Additional check for circular references
    try {
      JSON.stringify(requestBody);
    } catch (e) {
      console.error('Circular reference detected in requestBody:', e);
    }

    const response = await apiRequest(
      '/generate-final-workout',
      'POST', 
      requestBody,
      false,
      API_WORKOUTGENERATOR_URL
    );
    

    const finalResponse = {
      ...response,
      notes: {
        food: workoutStrategy.notes?.food || '',
        weights: workoutStrategy.notes?.weights || ''
      },
      workoutPlan: {
        ...response.workoutPlan,
        name: workoutStrategy.workoutPlan.name,
        overallStrategy: workoutStrategy.workoutPlan.overallStrategy,
        notes: {
          food: workoutStrategy.notes?.food || '',
          weights: workoutStrategy.notes?.weights || ''
        }
      }
    };

    
    return finalResponse;
  } catch (error) {
    console.error('Error in generateFinalWorkout:', error);
    console.error('Error stack:', error.stack);
    throw error;
  }
};

export const emailWorkout = async (email, pdfBlob, workoutName) => {
  try {
    let token = getJwtToken();
    
    if (!token || isTokenExpired(token)) {
      try {
        token = await refreshToken();
      } catch (error) {
        console.error('Error refreshing token:', error);
        throw new Error('Session expired. Please log in again.');
      }
    }

    const formData = new FormData();
    formData.append('workoutName', workoutName);
    formData.append('pdf', pdfBlob, 'workout.pdf');

    const config = {
      method: 'POST',
      url: `${API_USER_URL}/email-workout?email=${encodeURIComponent(email)}`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      },
      data: formData,
      ...(window.location.hostname !== 'localhost' && { withCredentials: true })
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error('Error emailing workout:', error);
    throw error;
  }
};